import React from 'react';
import { Link, withRouter } from 'react-router-dom';

const RecipeTags = ({ tags, type }) => {
	const prefix = type === 'recipe' ? 'recipes' : 'hints-and-tips';
	if (tags) {
		return (
			<section className="recipe-tags -remove-padding">
				<div className="center-container grid-parent">
					<div className="grid-child-8">
						<h4 className="recipe-tags-heading">Tags</h4>
						<div className="recipe-tags-container">
							{tags.map((item, i) => {
								if (item.tag) {
									return (
										<Link
											key={i}
											to={`/${prefix}?tag=${item.tag}`}
											className="single-recipe-tag"
										>
											{item.tag}
										</Link>
									);
								}
							})}
						</div>
					</div>
				</div>
			</section>
		);
	}
};

export default RecipeTags;
