import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import queryString from 'query-string';
import gql from 'graphql-tag';
import { isEmpty } from 'lodash';
import CardPaginationFilterContainer from '../container/partials/CardPaginationFilterContainer';
import CategoryHero from '../container/partials/CategoryHero';
import SeoComponent from '../component/SeoComponent';
import RelatedArticles from '../container/partials/RelatedArticles';
import Loader from '../component/Loader';

const HintsIndex = ({location}) => {
	const { data, loading, error, fetchMore } = useQuery(HINTS_QUERY,{
		fetchPolicy: 'no-cache'
	});
	if (loading) return <Loader />;
	if (error) return <p>error</p>;
	const { hintsAndTips, hintsAndTipsPage, tags } = data;
	const filteredTags = tags.filter((tag) => {
		return !isEmpty(tag.hints_tips);
	});
	const params = queryString.parse(location.search);

	return (
		<div className="hints-index display-page">
			{hintsAndTipsPage.social_sharing && <SeoComponent social={hintsAndTipsPage.social_sharing} />}
			<CategoryHero content={hintsAndTipsPage} />
			<CardPaginationFilterContainer
				data={hintsAndTips || []}
				tags={filteredTags || []}
				defaultTag={params.tag}
			/>
			{hintsAndTipsPage.related_content && (
				<RelatedArticles
					relatedContent={hintsAndTipsPage.related_content || []}
					relatedHeading={hintsAndTipsPage.related_content_heading}
				/>
			)}
		</div>
	);
};

export default HintsIndex;

const HINTS_QUERY = gql`
	query HintsQuery {
		hintsAndTips(sort: "updated_at:desc", where: { status: "published"})  {
			id
			title
			tagline
			slug
			status
			created_at
			updated_at
			tags (sort: "tag:asc") {
				id
				tag
			}
			card_image {
				id
				name
				url
				alternativeText
				caption
				width
				height
				formats
				previewUrl
			}
			
		}
		tags (sort: "tag:asc") {
			id
			tag
			recipes {
				id
			}
			hints_tips {
				id
			}
		}
		hintsAndTipsPage {
			id
			title
			social_sharing {
				id
				title
				description
				image {
					id
					url
					alternativeText
				}
			}
			hero_with_foreground {
				id
				foreground {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
				background {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
			}
			related_content_heading
			related_content {
				... on ComponentRepeatedRelatedRecipe {
					id
					recipe {
						id
						title
						slug
						status
						tagline
						card_image {
							id
							url
						}
					}
				}
			}
		}
	}
`;
