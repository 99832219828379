const hexCode = (colour) => {
	const style = {};
	if(!colour) return style;
	let primary, secondary;
	if (colour.hex_primary) {
		if (colour.hex_primary.indexOf('#') < 0) {
			primary = `#${colour.hex_primary}`;
		} else {
			primary = colour.hex_primary;
		}
		style.backgroundColor = primary;
	}

	if (colour.hex_optional) {
		if (colour.hex_optional.indexOf('#') < 0) {
			secondary = `#${colour.hex_optional}`;
		} else {
			secondary = colour.hex_optional;
		}
		style.backgroundImage = `linear-gradient(90deg, ${primary} 51%, ${secondary} 49%)`;
	}

	return style;
};

export default hexCode;
