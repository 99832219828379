import React from 'react';
import { isEmpty } from 'lodash';
import hexCode from '../../utils/hexCode';
import PropTypes from 'prop-types';
import Config from '../../common/config';
const isAU = Config.theme === 'AU';

const ProductRetailers = ({
	product,
	colour,
	colourchange,
	toggleRetailers,
}) => {
	const retailers = colour && (isAU ? colour.au_retailers : colour.nz_retailers);

	return (

		<div className="product-retailer">
			<div className="center-container">
				<div className="product-retailer-outer">
					<div className="product-retailer-product-info">
						<img
							src={product.card_image.url}
							className="product-retailer-image"
							alt={product.title}
						/>
						<div className="product-retailer-id">
							{product.collection && (
								<h3>
									{product.collection.title} |{' '}
									{product.primary_product_code}
								</h3>
							)}
							{!product.collection && (
								<h3>{product.primary_product_code}</h3>
							)}
							<h4>{product.title}</h4>
						</div>
					</div>
					<a
						href="#"
						onClick={(e) => toggleRetailers(e)}
						className="exit-product-retailer back-button"
					>
						Go back
					</a>
				</div>
				<div className="product-retailer-inner">
					<div className="product-retailer-ui">
						<h4 className="product-retailer-heading">Where to buy</h4>
						<p className="product-retailer-copy">
							We suggest you contact the retail store to ensure the
							product you are interested in buying is available.
						</p>
						<div className="product-retailer-colour-container">
							<h4 className="product-colours-heading">
								Select your colour
							</h4>
							{product.product_colour && (
								<div className="product-colours-container">
									{product.product_colour.map(
										(productColour, i) => {
											let activeColour = false;
											if (
												productColour.colour.param ==
												colour.colour.param
											) {
												activeColour = true;
											}
											return (
												<div
													className="single-colour-container"
													key={i}
												>
													<a
														key={i}
														onClick={(e) =>
															colourchange(
																e,
																productColour
															)
														}
														href="#"
														className={
															activeColour
																? 'single-product-colour -active'
																: 'single-product-colour'
														}
													>
														<div
															className="colour-inside"
															style={hexCode(
																productColour.colour
															)}
														></div>
													</a>
													<p className="colour-title">
														{productColour.colour.title}
													</p>
												</div>
											);
										}
									)}
								</div>
							)}
						</div>
					</div>
					<div className="product-retailers-list">
						{isEmpty(retailers) && (
							<h3 className="no-retailers large-subheading">
								Unfortunately there are no available retailers
								for this product
							</h3>
						)}
						{!isEmpty(retailers) && retailers.map((item, j) => {
							return (
								<a
									key={j}
									href={item.url}
									target="_blank"
									rel="noopener noreferrer"
									className="single-product-retailer"
								>
									<img
										className="product-retailer-logo"
										src={item.retailer.logo.url}
										alt={item.retailer.title}
									/>
								</a>
							);
						})}
					</div>
					<a
						href="#"
						onClick={(e) => toggleRetailers(e)}
						className="exit-product-retailer back-button -mobile"
					>
						Go back
					</a>
				</div>
			</div>
		</div>
	);
};

ProductRetailers.propTypes = {
	product: PropTypes.object,
	colour: PropTypes.object,
	colourchange: PropTypes.func,
	toggleRetailers: PropTypes.func
};

export default ProductRetailers;
