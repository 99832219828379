import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Images from '../image/Images';
import {isEmpty} from 'lodash';

const SeoComponent = ({social}) => {
	if(isEmpty(social) || isEmpty(social.title) || isEmpty(social.description)) return null;
	return (
		<Helmet
			title={social.title}
			meta={[
				{property: 'og:title', content: social.title},
				{name: 'description', content: social.description},
				{property:'og:description', content: social.description},
				{property: 'og:image',content: social.image && social.image.url || Images.favicon}
			]}
		/>
	);
};

export default SeoComponent;