import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import CategoryHero from '../container/partials/CategoryHero';
import DynamicContentContainer from '../container/DynamicContentContainer';
import Loader from '../component/Loader';

import gql from 'graphql-tag';

const About = () => {
	const { data, loading, error } = useQuery(ABOUTPAGE);
	// console.log(data);
	if (loading) return <Loader />;
	if (error) return null;
	const { aboutUs } = data;
	const { dynamic_content } = aboutUs;
	return (
		<div className="about-page">
			<CategoryHero content={aboutUs} />
			{dynamic_content && <DynamicContentContainer dynamicContent={dynamic_content} />}
		</div>
	);
};

export default About;

const ABOUTPAGE = gql`
	query AboutPageQuery {
		aboutUs {
			id
			title
			social_sharing {
				id
				title
				description
				image {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					formats
				}
			}
			hero_with_foreground {
				foreground {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
				background {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
			}
			dynamic_content {
				... on ComponentTemplatesContentBlock {
					id
					content
				}
				... on ComponentTemplatesImageSlider {
					id
					single_image {
						id
						desktop {
							url
							alternativeText
							formats
						}
					}
				}
				... on ComponentTemplatesLargeHeading {
					heading
				}
				... on ComponentTemplatesImageCopyBlock {
					reverse
					copy
					image_media {
						alternativeText
						url
						formats
					}
				}
				... on ComponentRepeatedIntro {
					video_poster {
						alternativeText
						url
					}
					video_embed_url
					heading
					content
					button_url
					button_text
					button_new_window
				}
				... on ComponentRepeatedVideoCarousel {
					video {
						embed_link
						poster {
							alternativeText
							url
						}
						title
					}
				}
				... on ComponentRepeatedSingleImage {
					id
					desktop {
						url
						alternativeText
						formats
					}
					mobile {
						url
						alternativeText
						formats
					}
				}
				... on ComponentRepeatedAccordion {
					show_images
					accordion_items {
						visible_text
						image {
							alternativeText
							url
						}
						dropdown_text
					}
				}
			}
		}
	}
`;
