import React, { useState } from 'react';
import Images from '../../image/Images';
import Swiper from 'react-id-swiper';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

const swiperParams = {
	slidesPerView: 1.5,
	freeMode: true,
	breakpoints: {
		600: {
			slidesPerView: 2.5,
		},
		767: {
			slidesPerView: 3,
		},
	},
};

const RelatedArticles = ({ relatedContent, relatedHeading }) => {
	if (isEmpty(relatedContent)) return null;

	const [swiper, setSwiper] = useState(null);

	const goNext = (e) => {
		e.preventDefault();
		if (swiper !== null) {
			swiper.slideNext();
		}
	};

	const goPrev = (e) => {
		e.preventDefault();
		if (swiper !== null) {
			swiper.slidePrev();
		}
	};

	let productBlocks = [];

	relatedContent.forEach((item, i) => {
		// console.log(item);
		let type, data, link;
		if (item.recipe) {
			data = item.recipe;
			type = 'Recipe';
			link = '/recipes/';
		} else {
			data = item.hints_tip;
			type = 'Hints & tip';
			link = '/hints-and-tips/';
		}
		if (data && data.status == 'published') {
			const element = (
				<Link to={link + data.slug} key={i} className="related-article">
					<h3 className="related-article-type">{type}S</h3>
					<h4 className="related-article-title">{data.title}</h4>
					<p className="related-article-intro">{data.tagline}</p>
					<div className="orange-arrow-link">
						<img
							className=""
							src={Images.arrowRight}
							alt="More info"
						/>
					</div>
					<img
						className="related-article-image"
						src={data.card_image && data.card_image.url || Images.fallbackCardImage}
						alt={data.card_image && data.card_image.alternativeText || 'Sunbeam'}
					/>
				</Link>
			);
			productBlocks.push(element);
		}
	});

	// console.log(productBlocks);
	if(isEmpty(productBlocks)) {
		return null;
	}
	return (
		<section className="related-articles-module">
			<div className="center-container -break-to-full">
				<div className="related-articles-wrapper">
					<div className="related-articles-headings-container">
						<div className="related-articles-headings-top">
							<img
								className="info-icon"
								src={Images.infoIcon}
								alt="Info"
							/>
							<h2 className="related-articles-heading">
								{ relatedHeading || 'Want to know more about your product?'}
							</h2>
							{/* <div className="related-articles-nav">
								<a
									onClick={(e) => goPrev(e)}
									className="related-articles-previous"
								>
									<img src={Images.lineArrowLeft} alt="Previous" />
								</a>
								<a
									onClick={(e) => goNext(e)}
									className="related-articles-next"
								>
									<img src={Images.lineArrowRight} alt="Next" />
								</a>
							</div> */}
						</div>
						<div className="related-articles-headings-bottom -desktop">
							<a
								className="related-articles-link"
								href="/recipes"
							>
								View all recipes
							</a>
							<a
								className="related-articles-link"
								href="/hints-and-tips"
							>
								View all hints & tips
							</a>
						</div>
					</div>
					<div className="related-articles-container">
						<Swiper getSwiper={setSwiper} {...swiperParams}>
							{productBlocks}
						</Swiper>
					</div>
					<div className="related-articles-headings-bottom -mobile">
						<a className="related-articles-link" href="/recipes">
							View all recipes
						</a>
						<a
							className="related-articles-link"
							href="/hints-and-tips"
						>
							View all hints & tips
						</a>
					</div>
				</div>
			</div>
		</section>
	);
};

RelatedArticles.propTypes = {
	relatedContent: PropTypes.array,
};

export default RelatedArticles;
