import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {isEmpty} from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import SearchBar from './SearchBar';
import MobileHeaderComponent from './MobileHeaderComponent';
import CategoryDropdownItem from './CategoryDropdownItem';
import Images from '../image/Images';
import Config from '../common/config';
import {Helmet} from "react-helmet";

const isAU = Config.theme === 'AU';

const Header = ({location, history}) => {
	const [categoryMenu, setCategoryMenu] = useState(false);
	const [categorySubmenu, setCategorySubmenu] = useState(0);
	const [mobileMenu, setMobileMenu] = useState(false);

	const handleMobileCss = () => {
		const html = document.getElementById('html');
		if(mobileMenu) {
			html.classList.add('overflow-hidden'); 
		} else {
			html.classList.remove('overflow-hidden');
		}
	};
	useEffect(() => handleMobileCss(), [mobileMenu]);

	const { data, loading, error } = useQuery(HEADER_QUERY);
	const [searchResult, setSearchResult] = useState(false);
	if(loading) return null;
	if(error) return null;
	const {categories} = data;
	// console.log(categories);
	const dropDownMenu = categories.map((category, i) => {
		return (
			<CategoryDropdownItem 
				category = {category}
				key={i}
				number={i}
				active = {i === categorySubmenu}
				setCategorySubmenu={setCategorySubmenu}
				setCategoryMenu={setCategoryMenu}
			/>
		);
	});

	const onClick = async (link) => {
		await setMobileMenu(false);
		history.push({
			pathname: link,
		});
	};
	//onMouseLeave={()=>setFirstmenu(false)} onMouseLeave={toggleHoverState} onMouseEnter={()=>setFirstmenu(true)}

	const headerStyle = categoryMenu || mobileMenu || searchResult ? 'header-wrapper -active' : 'header-wrapper';
	const osanoCode = isAU ? '16CVrKSNQJqIQ3AvP/0c88e065-b46d-4aae-9d4f-e77adc0cabb5' : '16CVrKSNQJqIQ3AvP/2d266bf5-8dcc-4f5b-b7d7-4def9d12b6be';

	return (
		<>
			<Helmet>
				<script src={'https://cmp.osano.com/' + osanoCode + '/osano.js'} />
			</Helmet>
			<header className="header">
				<div className={headerStyle}>
					<Link className="header-logo" to={'/'}>
						<img src={Images.blogo} className="logo" />
					</Link>
					<div className="header-links-desktop">
						<Link to={'/'}
							className={`header-link ${
								location.pathname === '/' || location.pathname.includes('/kitchen-and-home')
									? '-active'
									: ''
							}`}
							onMouseEnter={() => setCategoryMenu(true)}
							onClick={() => setCategoryMenu(false)}
							>
							Kitchen & Home
						</Link>
						<Link to={'/collections'}
							className={`header-link ${
								location.pathname.includes('/collection')
									? '-active'
									: ''
							}`}
						 onMouseEnter={() => setCategoryMenu(false)} >
							Collections
						</Link>
						<Link to={'/recipes'}
							className={`header-link ${
								location.pathname.includes('/recipe')
									? '-active'
									: ''
							}`}
						 onMouseEnter={() => setCategoryMenu(false)} >
							Recipes
						</Link>
						<Link to={'/hints-and-tips'}
							className={`header-link ${
								location.pathname.includes('/hint')
									? '-active'
									: ''
							}`}
						 onMouseEnter={() => setCategoryMenu(false)} >
							Hints & Tips
						</Link>
						<Link to={'/support'}
							className={`header-link ${
								location.pathname.includes('/support')
									? '-active'
									: ''
							}`}
						 onMouseEnter={() => setCategoryMenu(false)} >
							Support
						</Link>
						<Link to={'/about'}
							className={`header-link ${
								location.pathname.includes('/about')
									? '-active'
									: ''
							}`}
						 onMouseEnter={() => setCategoryMenu(false)} >
							About Us
						</Link>
					</div>
					<div className="search-bar-container">
						<SearchBar setSearchResult={setSearchResult} />
					</div>
					{/* ON CLICK OF HAMBURGER TOGGLE -active CLASS */}
					<a onClick={() => setMobileMenu(!mobileMenu)} className={mobileMenu ? 'hamburger hamburger--squeeze header-mobile -active' : 'hamburger hamburger--squeeze header-mobile'} type="button" aria-label="Hamburger Menu" title="Hamburger Menu">
						<span className="hamburger-box">
							<span className="hamburger-inner"></span>
						</span>
					</a>
					{
						categoryMenu &&
							<div className='sub-menu' onMouseLeave={() => setCategoryMenu(false)}>
								<div className='sub-menu-container'>
									{dropDownMenu}
								</div>
							</div>
					}
				</div>
				{mobileMenu && <div className="mobile-navigation -active">
					<div className="search-bar-container">
						<SearchBar closeMenu={() => setMobileMenu(false)} setSearchResult={setSearchResult} />
					</div>

					<div className="header-links-mobile">
						{/* ON .header-link CLICK, TOGGLE -active CLASS */}
						<MobileHeaderComponent categories={categories} closeMenu={() => setMobileMenu(false)}/>
						<a onClick={() => onClick('/collections')} className="header-link">
							<p>Collections</p>
						</a>
						<a onClick={() => onClick('/recipes')} className="header-link">
							<p>Recipes</p>
						</a>
						<a onClick={() => onClick('/hints-and-tips')} className="header-link">
							<p>Hints & Tips</p>
						</a>
						<a onClick={() => onClick('/support')} className="header-link">
							<p>Support</p>
						</a>
						<a onClick={() => onClick('/about')} className="header-link">
							<p>About Us</p>
						</a>
					</div>
				</div>}
			</header>
		</>
	);
};

export default withRouter(Header);

const HEADER_QUERY = gql`
	query Header {
		categories {
			id
			slug
			title
			subcategories {
				id
				title
				slug
				card_image {
					url
				}
			}
		}
	}
`;

// products (where: { status: "published"}) {
// 					id
// 				}
