import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Playbutton from '../../image/icons/video-play.svg';
import WatchVideo from '../../image/icons/watch-video.svg';
import Images from '../../image/Images';
import ReactMarkdown from 'react-markdown';
import Breaks from 'remark-breaks';
import {isEmpty} from 'lodash';

class ProductIntroModule extends Component {
	constructor(props) {
		super(props);
		// console.log(props);
		this.toggleDescription = this.toggleDescription.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.updateVideo = this.updateVideo.bind(this);
		let currentVideo = false;
		if (props.productIntro && props.productIntro.video_embed) {
			currentVideo = props.productIntro.video_embed[0];
		}

		this.state = {
			showDescription: false,
			videoModal: false,
			currentVideo: currentVideo,
		};
	}

	toggleModal(e) {
		e.preventDefault();
		this.setState((prev) => ({
			videoModal: !prev.videoModal,
		}));
	}

	toggleDescription(e) {
		e.preventDefault();
		this.setState((prev) => ({
			showDescription: !prev.showDescription,
		}));
	}

	updateVideo(e, video) {
		e.preventDefault();
		this.setState({ currentVideo: video, videoModal: false });
	}

	render() {
		const { productIntro, anchor } = this.props;

		return (
			<div className="product-intro-content-container right-medium">
				{this.state.currentVideo && (
					<div className="product-intro-video-gallery">
						<div className="single-product-intro-video">
							{this.state.videoModal && (
								<div className="intro-video-wrapper">
									<iframe
										className="intro-video-element"
										src={
											this.state.currentVideo.embed_link +
											'?autoplay=1'
										}
										frameBorder="0"
										allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
										allowFullScreen
									></iframe>
								</div>
							)}
							{!this.state.videoModal && (
								<div className="product-intro-video-poster-container">
									<img
										className="product-intro-video-poster"
										src={
											this.state.currentVideo.poster && this.state.currentVideo.poster.url || Images.fallbackPoster
										}
										alt={this.state.currentVideo.title}
									/>
									<div className="video-poster-button-wrapper">
										<a
											href="#"
											onClick={(e) => this.toggleModal(e)}
											className="video-poster-button"
										>
											<img
												className="video-poster-button-icon"
												src={Playbutton}
												alt="Play video"
											/>
										</a>
									</div>
								</div>
							)}
							<div className="product-intro-video-details">
								{this.state.currentVideo.title && (
									<h4 className="product-intro-video-title">
										{this.state.currentVideo.title}
									</h4>
								)}
								<a
									className="play-video-button"
									href="#"
									onClick={(e) => this.toggleModal(e)}
								>
									<img src={WatchVideo} alt="Play Video" />
								</a>
							</div>
						</div>
					</div>
				)}

				{this.state.currentVideo && productIntro.video_embed && productIntro.video_embed.length > 1 && (
					<div className="product-intro-selector-container swiper-pagination-bullets">
						{productIntro.video_embed.map((video, i) => {
							let active = false;
							if (this.state.currentVideo == video) {
								active = true;
							}
							return (
								<a
									key={i}
									className={
										active
											? 'product-intro-selector swiper-pagination-bullet -active'
											: 'product-intro-selector swiper-pagination-bullet'
									}
									href="#"
									onClick={(e) => this.updateVideo(e, video)}
								></a>
							);
						})}
					</div>
				)}

				<div className="product-intro-text">
					{
						!isEmpty(productIntro.tagline_extended) ?
							<div className="product-intro-copy">
								<ReactMarkdown children={productIntro.tagline_extended} plugins={[Breaks]} />
							</div>
							:
							<p className="product-intro-copy">
								{productIntro.tagline}
							</p>
					}
					{this.state.showDescription && (
						<div className="product-intro-description markdown-block">
							<ReactMarkdown children={productIntro.description} plugins={[Breaks]} />
						</div>
					)}
					{productIntro.description && (
						<a
							href="#"
							onClick={(e) => this.toggleDescription(e)}
							className="product-intro-readmore"
						>
							{this.state.showDescription ? 'Read less' : 'Read more'}
						</a>
					)}
				</div>
				{anchor && anchor.anchor_to_info && (
					<div className="product-intro-additional-wrapper" id="icon-anchor">
						<div className="product-intro-additional">
							<img
								id="featured-icon"
								className="product-intro-featured-icon"
								src={anchor.icon.image.url}
								alt={anchor.icon.title}
							/>
							<div className="product-intro-additional-copy">
								<h3 className="product-intro-additional-heading">
									{anchor.title}
								</h3>
								{anchor.tagline && <p>{anchor.tagline}</p>}
								{anchor.link && (
									<a
										className="product-intro-readmore"
										href={anchor.link}
										target="_blank"
										rel="noopener noreferrer"
									>
										Learn more
									</a>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

const ProductIntro = ({ anchor, productIntro }) => {


	return (
		<section className="product-intro">
			<div className="center-container">
				<div className="product-intro-inner grid-parent">
					<div className="product-intro-header-container left-small">
						<h1 className="product-intro-header large-subheading">
							{productIntro && productIntro.heading}
						</h1>
					</div>
					<ProductIntroModule
						productIntro={productIntro}
						anchor={anchor}
					/>
				</div>
			</div>
		</section>
	);
};

ProductIntroModule.propTypes = {
	productIntro: PropTypes.object,
	anchor: PropTypes.object,
};

ProductIntro.propTypes = {
	productIntro: PropTypes.object,
	anchor: PropTypes.object,
};

export default ProductIntro;
