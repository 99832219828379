import React, { useState, useEffect } from 'react';
import Images from '../../image/Images';
import Swiper from 'react-id-swiper';
import { imageHelper } from '../../utils/helper';
import { Link } from 'react-router-dom';

const swiperParams = {
	slidesPerView: 1,
	loop: true,
	// autoplay: {
	// 	delay: 10000,
	// 	disableOnInteraction: false,
	// },
};

const HomeHero = ({ carousel }) => {
	const [swiper, setSwiper] = useState(null);
	const [currentIndex, updateCurrentIndex] = useState(0);

	useEffect(() => {
		if (swiper !== null) {
			swiper.on('slideChange', () =>
				updateCurrentIndex(swiper.realIndex)
			);
		}

		return () => {
			if (swiper !== null) {
				swiper.off('slideChange', () =>
					updateCurrentIndex(swiper.realIndex)
				);
			}
		};
	}, [swiper]);

	const clickTo = (index) => {
		if (swiper !== null) {
			swiper.slideTo(index + 1);
		}
	};

	const buttons = carousel.map((ca, i) => {
		let style = ['slider-option'];
		if (currentIndex == i) {
			style.push('-active');
		}
		return (
			<div
				className={style.join(' ')}
				key={i}
				onClick={() => clickTo(i)}
			></div>
		);
	});

	const content = carousel.map((item, i) => {
		const bg = imageHelper(item.background_image, 'large');
		const fg = imageHelper(item.foreground_image, 'large');

		return (
			<div className="home-hero-container" key={i}>
				<div className="left">
					<div className="content-block">
						<h2 className="header-title large-subheading">{item.title}</h2>
						<p className="d1">{item.subtitle}</p>

						<p className="d2">{item.tagline}</p>
						{item.button_link && (
							<Link
								to={item.button_link}
								className="hero-button button -orange"
							>
								{item.button_text ? item.button_text : 'Learn more'}
							</Link>
						)}

						<div className="button-container">{buttons}</div>
					</div>
				</div>

				<div className="right" style={bg ? { backgroundImage: 'url(' + bg + ')' } : { backgroundImage: 'url(' + Images.fallbackHomeHeroBg + ')' }}>
					{fg && (
						<img
							key={i}
							className="img"
							src={fg}
						/>
					)}
				</div>
			</div>
		);
	});

	return (
		<section className="home-hero">
			<div className="center-container">
				<div className="lifestyle-slider-container">
					<Swiper getSwiper={setSwiper} {...swiperParams}>
						{content}
					</Swiper>
				</div>
			</div>
		</section>
	);
};

export default HomeHero;
