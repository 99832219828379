import React from 'react';
import PropTypes from 'prop-types';
import Images from '../../image/Images';
import { imageHelper } from '../../utils/helper';

//Add boolean to CategoryHero param for seperating publish date and promotion expiration date
const CollectionFullHero = ({ data, type }) => {
	if (!data) return null;

	if (data.full_bleed_hero) {
		return (
			<section className="article-hero collection-full-hero -full-width">
				<div className="article-hero-desktop" style={{
					backgroundImage:
						'url(' + data.full_bleed_hero.desktop_image.url + ')',
				}}>
					<div className="center-container grid-parent">
						<div className="article-hero-inner grid-child-12">
							<h2 className="article-hero-title small-heading">{type}</h2>
							<h3 className="article-hero-subtitle large-subheading">
								{data.title}
							</h3>
						</div>
					</div>
				</div>

				<div className="article-hero-mobile" style={{
					backgroundImage:
						'url(' + imageHelper(data.full_bleed_hero.mobile_image, 'large') + ')',
				}}>
					<div className="center-container grid-parent">
						<div className="article-hero-inner grid-child-12">
							<h2 className="article-hero-title small-heading">{type}</h2>
							<h3 className="article-hero-subtitle large-subheading">
								{data.title}
							</h3>
						</div>
					</div>
				</div>
			</section>
		);
	}
};

CollectionFullHero.propTypes = {
	data: PropTypes.object,
	type: PropTypes.string
};

export default CollectionFullHero;