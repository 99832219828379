//config.js
const THEME = process.env.REACT_APP_THEME;
const REACT_APP_SALESFORCE_APIKEY=process.env.REACT_APP_SALESFORCE_APIKEY;
const REACT_APP_REGISTER_URL = process.env.REACT_APP_REGISTER_URL;
const REACT_APP_PROMOFORM_URL = process.env.REACT_APP_PROMOFORM_URL;
const REACT_APP_PROMOFORM_APIKEY = process.env.REACT_APP_PROMOFORM_APIKEY;
// const SALESFORCE_CONTACTUS_URL = process.env.REACT_APP_SALESFORCE_CONTACTUS_URL;

const REACT_APP_CONTACTUS_APIKEY = process.env.REACT_APP_CONTACTUS_APIKEY;
const REACT_APP_CONTACTUS_URL = process.env.REACT_APP_CONTACTUS_URL;

export default {
	theme: THEME,
	gtmId: process.env.REACT_APP_GTMID, 
	salesforce: {
		contactApi: {
			api: REACT_APP_CONTACTUS_URL,
			api_key: REACT_APP_CONTACTUS_APIKEY
		},
		registerApi: {
			api: REACT_APP_REGISTER_URL,
			api_key: REACT_APP_SALESFORCE_APIKEY
		},
		promoFormApi: {
			api: REACT_APP_PROMOFORM_URL,
			api_key: REACT_APP_PROMOFORM_APIKEY
		}
	}
};