import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router';
import {isEmpty} from 'lodash';
import CATEGORY_QUERY from '../queries/category/category';
import CollectionRange from '../container/partials/CollectionRange';
import RelatedArticles from '../container/partials/RelatedArticles';
import FeaturedProducts from '../container/partials/FeaturedProducts';
import CategoryHero from '../container/partials/CategoryHero';
import SeoComponent from '../component/SeoComponent';
import Loader from '../component/Loader';

const Category = () => {
	let { categoryslug } = useParams();
	const { loading, error, data } = useQuery(CATEGORY_QUERY, {
		variables: { category: categoryslug },
		fetchPolicy: 'no-cache'
	});
	if (loading) return <Loader />;
	if (error) return <p>error</p>;
	const { categories } = data;
	const categoryContent = categories && categories[0];
	if (!categoryContent) {
		return null;
	}
	const { collections_range, related_content, subcategories, slug, related_content_heading } = categoryContent;
	return (
		<div className="category-page">
			{categoryContent.social_sharing && <SeoComponent social={categoryContent.social_sharing} />}
			<CategoryHero content={categoryContent} />
			{subcategories &&
				subcategories.map((subcategory, i) => {
					if(isEmpty(subcategory.products)) return null;
					return <FeaturedProducts key={i} content={subcategory} type='subcategory' categorySlug={slug} />;
				})}
			{collections_range && (
				<CollectionRange CollectionRange={collections_range} />
			)}
			{related_content && (
				<RelatedArticles
					relatedContent={related_content || []}
					relatecHeading={related_content_heading}
				/>
			)}
		</div>
	);
};

export default Category;
