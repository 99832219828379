import React, { useState, Fragment } from 'react';
import { useQuery } from '@apollo/react-hooks';
// import { useParams } from 'react-router';
import gql from 'graphql-tag';
import {isEmpty} from 'lodash';
import { Link } from 'react-router-dom';
import MobileHeaderDropdownItem from './MobileHeaderDropdownItem';
import Images from '../image/Images';

const MobileHeaderComponent = ({categories, closeMenu}) => {
	const [dropdown, setDropdown] = useState(false);
	let top_style = ['header-link product-link -with-dropdown'];
	if(dropdown) {
		top_style.push('-active');
	}

	return (
		<Fragment>
			<div className={top_style.join(' ')} 
				onClick={(e) => {
					setDropdown(!dropdown);
				}}
			>
				<p>Kitchen & Home</p>		
			</div>
			{dropdown && 
				<div className="category-wrapper">
					{
						categories && categories.map((category, i) => {
							return (
								<MobileHeaderDropdownItem 
								 key={i}
								 category = {category}
								 closeMenu={closeMenu}
								/>
							);
						})
					}
				</div>
			}
		</Fragment>
	);
};

export default MobileHeaderComponent;