import React from 'react';
import gql from 'graphql-tag';
import ReactMarkdown from 'react-markdown';
import Facebook from '../image/icons/facebook-red.svg';
import Instagram from '../image/icons/instagram-red.svg';
import { useQuery } from '@apollo/react-hooks';
import HelpModule from '../container/partials/HelpModule';
import SeoComponent from '../component/SeoComponent';
// import ServiceAgent from '../container/partials/ServiceAgent';
import CategoryHero from '../container/partials/CategoryHero';
import ContactForm from '../component/ContactForm';
import Breaks from 'remark-breaks';
import Loader from '../component/Loader';


const ContactPage = () => {
	const { data, loading, error } = useQuery(CONTACTPAGE, {
		fetchPolicy: 'no-cache'
	});
	if (loading) return <Loader />;
	if (error) return <p>error</p>;
	const { supportLandingPage, contactPage, contactDetail } = data;
	const { warranty, services, product_support, general_support } = supportLandingPage;
	
	return (
		<div className="contact-page">
			{contactPage.social_sharing && <SeoComponent social={contactPage.social_sharing} />}
			<CategoryHero content={contactPage} />
			<section className="text-block -remove-padding-top">
				<div className="center-container grid-parent">
					<div className="text-wrapper grid-child-6-3 markdown-block">
						<ReactMarkdown children={contactPage.copy} plugins={[Breaks]} />
					</div>
				</div>
			</section>
			<section className="contact-block -remove-padding-top">
				<div className="center-container grid-parent">
					{contactDetail && (
						<div className="contact-wrapper contact grid-child-6-3">
							<h3 className="contact contact-heading">
								Customer contact centre
							</h3>
							{contactDetail.phone && (
								<a
									className="contact-number"
									href="tel:1300881861"
								>
									{contactDetail.phone}
								</a>
							)}
							{contactDetail.hours_of_operation &&
								contactDetail.hours_of_operation.map(
									(item, i) => {
										return (
											<p
												key={i}
												className="contact-hours"
											>
												{item.hours}
											</p>
										);
									}
								)}
						</div>
					)}
				</div>
			</section>

			<ContactForm />
			<HelpModule content={warranty} color="white" />
			<HelpModule content={services} color="grey" />
			<HelpModule content={product_support} color="white" />
			<HelpModule content={general_support} color="grey" />
		</div>
	);
};

export default ContactPage;

const CONTACTPAGE = gql`
	query ContactPageQuery {
		supportLandingPage {
			id
			title
			copy

			warranty {
				id
				heading
				links {
					id
					link_text
					link_slug
				}
			}

			services {
				id
				heading
				links {
					id
					link_text
					link_slug
				}
			}

			contact {
				id
				heading
				links {
					id
					link_text
					link_slug
				}
			}

			general_support {
				id
				heading
				links {
					id
					link_text
					link_slug
				}
			}

			product_support {
				id
				heading
				links {
					id
					link_text
					link_slug
				}
			}
		}
		contactPage {
			id
			title
			copy
			social_sharing {
				id
				title
				description
				image {
					id
					url
					alternativeText
				}
			}
			hero_with_foreground {
				id
				foreground {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
				background {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
			}
		}

		contactDetail {
			id
			facebook_url
			hours_of_operation {
			  hours
			  id
			}
			instagram_url
			phone
			twitter_url
		}
	}
`;
