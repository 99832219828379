import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Breaks from 'remark-breaks';
import ReactMarkdown from 'react-markdown';
import {isEmpty} from 'lodash';

const Warranty = ({warranty, model}) => {
	return (
		<div className="product-warranty-inner">
			<div className="product-warranty-intro">
				<p className="product-warranty-intro-text">
					Recently bought a product from Sunbeam? Register your
					product now to ensure it’s covered by a replacement or
					repair guarantee.
				</p>
				<Link className="button -register -orange" to={'/support/product-registration'}>
					Register your Product
				</Link>
			</div>
			<div className="product-warranty-container">
				<h3 className="product-warranty-heading">Warranty</h3>
				<div className="single-product-warranty">
					<div className="warranty-line -top">
						<h4 className="single-product-warranty-model">
							{model}
						</h4>
					</div>
					{warranty.type && (
						<div className="warranty-line">
							<p className="single-product-warranty-heading">
								Warranty Type
							</p>
							<p className="single-product-warranty-details">
								{warranty.type}
							</p>
						</div>
					)}
					{warranty.duration_months && (
						<div className="warranty-line">
							<p className="single-product-warranty-heading">
								Duration
							</p>
							<p className="single-product-warranty-details">
								{warranty.duration_months} Months
							</p>
						</div>
					)}
					{warranty.note && (
						<div className="warranty-line">
							<p className="single-product-warranty-heading">
								Note
							</p>
							<p className="single-product-warranty-details">
								{warranty.note}
							</p>
						</div>
					)}
				</div>
			</div>
			{
				!isEmpty(warranty.copy) ?
					<div className="product-warranty-guarantee">
						<ReactMarkdown children={warranty.copy} plugins={[Breaks]} />
						<a className="warranty-guarantee-link" href="/support/warranty/">
							Read our replacement & repair guarantee here
						</a>
					</div>
					: 			
					<div className="product-warranty-guarantee">
						<p className="warranty-guarantee-text">
							Most of Sunbeam appliances are covered by a replacement or
							repair guarantee, which is in addition to your rights under
							the Australian Consumer Law.
						</p>
						<p className="warranty-guarantee-text">
							Many Sunbeam appliances have guarantee periods exceeding 12
							months, highlighting Sunbeam&lsquo;s commitment to the
							highest standards of quality.
						</p>
						<a className="warranty-guarantee-link" href="/support/warranty/">
							Read our replacement & repair guarantee here
						</a>
					</div>
			}
		</div>
	);
};

Warranty.propTypes = {
	warranty: PropTypes.object,
};

export default Warranty;
