import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import CategoryHero from '../container/partials/CategoryHero';
import TextBlock from '../component/TextBlock';
import SeoComponent from '../component/SeoComponent';
// import ReactMarkdown from 'react-markdown';
import Loader from '../component/Loader';

const Terms = () => {
	const { data, loading, error } = useQuery(TERMPAGE);
	if (loading) return <Loader />;
	if (error) return null;
	const { termsOfUse } = data;
	const { content, social_sharing } = termsOfUse;
	return (
		<div className="terms-page">
			{social_sharing && <SeoComponent social={social_sharing} />}
			<CategoryHero content={termsOfUse} />
			{content && (
				<section className="html-template center-container grid-parent">
					<TextBlock content={termsOfUse} col={8} />
				</section>
			)}
		</div>
	);
};
//<div dangerouslySetInnerHTML={createMarkup(content)} />
export default Terms;

const TERMPAGE = gql`
	query TermPageQuery {
		termsOfUse {
			id
			title
			content
			social_sharing {
				id
				title
				description
				image {
					id
					url
					alternativeText
				}
			}
			hero_with_foreground {
				foreground {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
				background {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
			}
		}
	}
`;
