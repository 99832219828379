import React, { Component } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

class AccordionToggle extends Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.state = {
			toggleClass: false,
		};
	}

	handleClick(e) {
		e.preventDefault();
		this.setState((prev) => ({
			toggleClass: !prev.toggleClass,
		}));
	}

	render() {
		const category = this.props.category;
		const categoryContent = category.products.map((product, i) => {
			if (product.warranty) {
				return (
					<div key={i} className="single-product-warranty">
						<div className="warranty-line -top">
							<h4 className="single-product-warranty-model">
								{product.primary_product_code} / {product.title}
							</h4>
						</div>
						{product.warranty.type && (
							<div className="warranty-line">
								<p className="single-product-warranty-heading">
									Warranty Type
								</p>
								<p className="single-product-warranty-details">
									{product.warranty.type}
								</p>
							</div>
						)}
						{product.warranty.duration_months && (
							<div className="warranty-line">
								<p className="single-product-warranty-heading">
									Duration
								</p>
								<p className="single-product-warranty-details">
									{product.warranty.duration_months} Months
								</p>
							</div>
						)}
						{product.warranty.note && (
							<div className="warranty-line">
								<p className="single-product-warranty-heading">
									Note
								</p>
								<p className="single-product-warranty-details">
									{product.warranty.note}
								</p>
							</div>
						)}
					</div>
				);
			}
		});
		if (categoryContent[0]) {
			return (
				<div className="single-accordion">
					<a
						className={
							this.state.toggleClass
								? 'accordion-title -active'
								: 'accordion-title'
						}
						onClick={(e) => this.handleClick(e)}
						href="#"
					>
						{category.title}
					</a>
					{this.state.toggleClass && categoryContent}
				</div>
			);
		} else {
			return false;
		}
	}
}

AccordionToggle.propTypes = {
	category: PropTypes.object,
};

const WarrantyTable = () => {
	const { loading, error, data } = useQuery(WARRANTY_QUERY);
	if (loading) return null;
	if (error) return <p>error</p>;
	const warrantyCategories = data.subcategories;
	return (
		<div className="warranty-table" id="table">
			{warrantyCategories.map((category, i) => {
				return <AccordionToggle category={category} key={i} />;
			})}
		</div>
	);
};

const WARRANTY_QUERY = gql`
	query WARRANTIES {
		subcategories {
			title
			products (where: { status: "published"}) {
				warranty {
					duration_months
					note
					type
				}
				title
				primary_product_code
			}
		}
	}
`;

export default WarrantyTable;
