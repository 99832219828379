import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import client from './utils/apolloClient';
import AppLayoutRoute from './page/AppLayoutRoute'; // Default layout with Header and Footer
import Home from './page/Home';
import Category from './page/Category';
import Subcategory from './page/Subcategory';
import Product from './page/Product';
import CollectionsIndex from './page/CollectionsIndex';
import Collection from './page/Collection';
import RecipesIndex from './page/RecipesIndex';
import Recipe from './page/Recipe';
import HintsIndex from './page/HintsIndex';
import Hint from './page/Hint';
import PromotionIndex from './page/PromotionIndex';
import Promotion from './page/Promotion';
import Custom from './page/Custom';

import NotFound from './page/404';

//static pages
import Faq from './page/Faq';
import ProductManualsPage from './page/ProductManualsPage';
import Warranty from './page/Warranty';
import Service from './page/Service';
import ProductRegister from './page/ProductRegister';
import WhereToBuy from './page/WhereToBuy';
import ContactPage from './page/ContactPage';
import SearchResultPage from './page/SearchResultPage';

import Support from './page/Support';
import About from './page/About';
import Terms from './page/Terms';
import Privacy from './page/Privacy';

import TrackerWrapper from './component/TrackerWrapper';

const Routes = () => (
	<Router>
		
		<ApolloProvider client={client}>
			<TrackerWrapper>
				<Switch>
					<AppLayoutRoute component={Home} exact path="/" />

					<AppLayoutRoute
						component={Collection}
						exact
						path="/collections/:collectionslug"
					/>
					<AppLayoutRoute
						component={CollectionsIndex}
						exact
						path="/collections"
					/>

					{/* Category */}
					<AppLayoutRoute
						component={Category}
						exact
						path="/kitchen-and-home/:categoryslug"
					/>
					{/* Subcategory */}
					<AppLayoutRoute
						component={Subcategory}
						exact
						path="/kitchen-and-home/:categoryslug/:subcategoryslug"
					/>

					{/* Product */}
					<AppLayoutRoute
						component={Product}
						exact
						path="/kitchen-and-home/:categoryslug/:subcategoryslug/:productslug"
					/>

					{/* Routes With Dynamic Content */}
					<AppLayoutRoute 
						exact 
						component={RecipesIndex} 
						path="/recipes" 
					/>
					<AppLayoutRoute
						component={Recipe}
						exact
						path="/recipes/:recipeslug"
					/>
					<AppLayoutRoute
						component={HintsIndex}
						path="/hints-and-tips"
						exact
					/>
					<AppLayoutRoute 
						component={Hint} 
						exact
						path="/hints-and-tips/:hintslug"
					/>
					<AppLayoutRoute
						component={PromotionIndex}
						path="/promotions"
						exact
					/>
					<AppLayoutRoute
						component={Promotion}
						path="/promotions/:promotionslug"
						exact
					/>
					<AppLayoutRoute
						component={Custom}
						path="/home-appliances/:customslug"
						exact
					/>
					<AppLayoutRoute
						component={SearchResultPage}
						path="/search"
					/>
					{/* Static pages */}
					<Route
						path="/support"
						render={({ match: { url } }) => (
							<>
								<AppLayoutRoute
									path={`${url}/`}
									component={Support}
									exact
								/>
								<AppLayoutRoute
									path={`${url}/warranty`}
									component={Warranty}
								/>
								<AppLayoutRoute
									path={`${url}/services`}
									component={Service}
								/>
								<AppLayoutRoute
									path={`${url}/product-registration`}
									component={ProductRegister}
								/>
								<AppLayoutRoute
									path={`${url}/contact`}
									component={ContactPage}
								/>
								<AppLayoutRoute
									component={Faq}
									path={`${url}/faq`}
								/>
								<AppLayoutRoute
									component={WhereToBuy}
									path={`${url}/where-to-buy`}
								/>
								<AppLayoutRoute
									component={ProductManualsPage}
									path={`${url}/product-manuals`}
								/>
							</>
						)}
					/>

					<AppLayoutRoute component={About} path="/about" />
					<AppLayoutRoute component={Terms} path="/terms" />
					<AppLayoutRoute component={Privacy} path="/privacy" />
					<AppLayoutRoute component={NotFound} />
				</Switch>
			</TrackerWrapper>
		</ApolloProvider>
	</Router>
);

export default Routes; 

