import gql from 'graphql-tag';
// , $limit: Int = 12 limit: $limit, start: $start,
const RECIPE_QUERY = gql`
	query RecipeQuery {
		tags(sort: "tag:asc", where: { recipes_ne: null }) {
			id
			tag
			recipes {
				id
			}
		}
		meals(sort: "id", where: { recipes_ne: null }) {
			id
			meal_title
		}
		recipes(
			limit: 250
			sort: "updated_at:desc"
			where: { status: "published" }
		) {
			id
			title
			tagline
			slug
			status
			created_at
			updated_at
			preparation_time
			tags(sort: "tag:asc") {
				id
				tag
			}
			meal {
				id
				meal_title
			}
			card_image {
				id
				name
				url
				alternativeText
				caption
				width
				height
				formats
				previewUrl
			}
			dropdown_tags {
				id
				Tag
			}
		}
		recipesPage {
			id
			title
			social_sharing {
				id
				title
				description
				image {
					id
					url
					alternativeText
				}
			}
			hero_with_foreground {
				id
				foreground {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
				background {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
			}
			related_content_heading
			related_content {
				... on ComponentRepeatedRelatedHintsTip {
					id
					hints_tip {
						id
						title
						status
						slug
						tagline
						card_image {
							id
							url
						}
					}
				}
			}
		}
	}
`;

export default RECIPE_QUERY;
