import queryString from 'query-string';

let currentColour;
const colourParam = (data) => {
	if (!data) return null;
	const colours = data.product_colour || data.colours;
	if (!colours) return null;

	const currentQuery = queryString.parse(window.location.search).colour;
	if (colours.length < 2) {
		return colours[0];
	}
	else {
		if (!currentQuery) {
			if (history.pushState) {
				const newurl =
					window.location.protocol +
					'//' +
					window.location.host +
					window.location.pathname +
					`?colour=${colours[0].colour.param}`;
				window.history.pushState({ path: newurl }, '', newurl);
			}
			currentColour = colours[0];
		} else {
			colours.map((item, i) => {
				if (item.colour.param == currentQuery) {
					currentColour = colours[i];
				}
			});
			if (!currentColour && history.pushState) {
				const newurl =
					window.location.protocol +
					'//' +
					window.location.host +
					window.location.pathname +
					`?colour=${colours[0].colour.param}`;
				window.history.pushState({ path: newurl }, '', newurl);
				currentColour = colours[0];
			}
		}
	}
	return currentColour;
};

export default colourParam;
