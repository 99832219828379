import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router';
import { isEmpty } from 'lodash';
import gql from 'graphql-tag';
import CardFilterContainer from '../container/partials/CardFilterContainer';
import CategoryHero from '../container/partials/CategoryHero';
import SeoComponent from '../component/SeoComponent';
import Config from '../common/config';
const isAU = Config.theme === 'AU';
import Loader from '../component/Loader';

const retrieveFeaturesOptions = (productlist) => {
	let featurelist = [];
	productlist.forEach((p) => {
		p.features && p.features.accordion_items && p.features.accordion_items.map((i) => {
			const visible_text = i.visible_text;
			if(!featurelist.includes(visible_text)) {
				featurelist.push(visible_text);
			}
		});
	});
	return featurelist;
};

const retrieveFinishOptions = (productlist) => {
	let finishlist = [];
	productlist.forEach((p) => {
		const finish = p.specifications && p.specifications.construction_finish;
		if(finish && !finishlist.includes(finish)) {
			finishlist.push(finish);
		}
	});
	return finishlist;
};

const retrieveColourOptions = (productlist) => {
	let colourlist = [];
	productlist.forEach((p) => {
		p.product_colour && p.product_colour.map((c) => {
			const colour = c.colour;
			if(colour && !colourlist.includes(colour)) {
				colourlist.push(colour);
			}
		});
	});
	return colourlist;
};

const getMaxPrice = (productlist) => {
	let max = 0;
	productlist.forEach((p) => {
		console.log();
		if(p.price && p.price.price_au > max) {
			max = p.price.price_au;
		}
	});
	return max;
};

const Subcategory = () => {
	let { subcategoryslug, categoryslug } = useParams();
	const { loading, error, data, fetchMore } = useQuery(SUBCATEGORY_QUERY, {
		variables: { subcategoryslug, categoryslug },
	});

	if (loading) return <Loader />;
	if (error) return <p>error</p>;
	const { subcategories } = data;
	const subcategory = subcategories[0];

	if (isEmpty(subcategory)) {
		return <p>nothing is here</p>;
	}
	const {products} = subcategory;
	const maxPrice = isAU && getMaxPrice(products);
	const finishes = retrieveFinishOptions(products);

	const onLoadMore = () => {
		fetchMore({
			variables: {
				start: products.length,
			},
			updateQuery: (prev, { fetchMoreResult }) => {
				if (!fetchMoreResult) return prev;
				const newData = Object.assign({}, prev.subcategories[0], {
					products: [
						...prev.subcategories[0].products,
						...fetchMoreResult.subcategories[0].products,
					],
				});

				return {
					subcategories: [newData]
				};
			},
		});
	};

	return (
		<div className="product-index display-page">
			{subcategory.social_sharing && <SeoComponent social={subcategory.social_sharing} />}
			<CategoryHero content={subcategory} categoryslug={categoryslug} subcategoryslug={subcategoryslug} />
			<CardFilterContainer 
				finishes={finishes}
				data={subcategory.products || []}  
				maxPrice={maxPrice}
				type="product-index"
				subcategorySlug={subcategory.slug}
				sort
			/>
		</div>
	);
};

// limit={9} onLoadMore={onLoadMore} $start: Int = 0, $limit: Int = 9,
// 

export default Subcategory;

const SUBCATEGORY_QUERY = gql`
	query SubCategories( $subcategoryslug: String, $categoryslug: String) {
		subcategories(where: { slug: $subcategoryslug }) {
			id
			slug
			title
			category {
				id
				title
			}
			social_sharing {
				id
				title
				description
				image {
					id
					url
					alternativeText
				}
			}

			hero_with_foreground {
				foreground {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
				background {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
			}
			products (where: { status: "published"}, sort: "created_at:desc") {
				id
				title
				slug
				status
				created_at
				primary_product_code
				subcategories {
					id
					slug
					title
					category {
						id
						slug
						title
					}
				}
				categories (where: { slug: $categoryslug }) {
					id
					slug
				}
				specifications {
					id
					construction_finish
				}
				features {
					id
					show_images
					accordion_items {
						id
						visible_text
						dropdown_text
					}
				}
				product_colour {
					id
					colour {
						id
						title
						param
						hex_primary
						hex_optional
					}
					key_image {
						id
						title
						file {
							id
							url
							formats
							alternativeText
						}
					}
					product_code
				}
				awards_and_icons {
					id
					featured_icon {
						id
						icon {
							id
							image {
								id
								url
								alternativeText
							}
						}
					}
				}
				card_image {
					id
					name
					url
					alternativeText
					caption
					width
					height
					formats
					previewUrl
				}
				primary_ean
				price {
					id
					discontinued_au
					price_au
				}
			}
		}
	}
`;
