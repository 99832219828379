import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import CardContainer from '../container/partials/CardContainer';
import ProductRange from '../container/partials/ProductRange';
import CategoryHero from '../container/partials/CategoryHero';
import SeoComponent from '../component/SeoComponent';
import Loader from '../component/Loader';

const CollectionsIndex = () => {
	const { data, loading, error, fetchMore } = useQuery(COLLECTIONS_QUERY);
	// console.log(data);
	if (loading) return <Loader />;
	if (error) return <p>error</p>;
	const { collections, collectionsPage } = data;

	return (
		<div className="collections-index display-page">
			{collectionsPage.social_sharing && <SeoComponent social={collectionsPage.social_sharing} />}
			<CategoryHero content={collectionsPage} />
			<section className="card-module -remove-padding-top">
				<div className="center-container grid-parent">
					<div className="grid-child-10">
						<CardContainer 
							data={collections || []}
						/>
					</div>
				</div>
			</section>
			{collectionsPage.product_range && (
				<ProductRange productRange={collectionsPage.product_range} />
			)}
		</div>
	);
};

export default CollectionsIndex;

const COLLECTIONS_QUERY = gql`
	query CollectionsQuery {
		collections (sort: "created_at:desc") {
			id
			title
			tagline
			slug
			awards_icon {
				id
				title
				image {
					id
					url
					formats
				}
			}
			card_image {
				id
				name
				url
				alternativeText
				caption
				width
				height
				formats
				previewUrl
			}
		}

		collectionsPage {
			id
			title
			product_range {
				id
				heading
				categories {
					id
					title
					slug
					created_at
					updated_at
					card_image {
						id
						name
						url
						alternativeText
						caption
						width
						height
						formats
						previewUrl
					}
				}
			}
			hero_with_foreground {
				foreground {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
				background {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
			}
			social_sharing {
				id
				title
				description
				image {
					id
					url
					alternativeText
				}
			}
		}
	}
`;
