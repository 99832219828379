import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router';
import gql from 'graphql-tag';
import {isEmpty} from 'lodash';
import Images from '../image/Images';
import { Link, withRouter } from 'react-router-dom';
import Config from '../common/config';
const isAU = Config.theme === 'AU';

const twURL = 'https://twitter.com/intent/tweet?via=the_aemc&text=';

const linkedinURL = 'https://www.linkedin.com/shareArticle/?mini=true&url=';

const fbURL = 'https://www.facebook.com/sharer/sharer.php?u=';

const url = isAU ? 'https://www.sunbeam.com.au' : 'https://www.sunbeam.co.nz';



const SocialSharingContainer = ({location, type}) => {	
	const { pathname } = location;

	const shareUrl = `${url}${pathname}`;
	// console.log(dynamicContent);
	return (
		<section className="social-share-block -remove-padding">
			<div className="center-container grid-parent">
				<div className="grid-child-8 social-share-wrapper">

					<img src={Images.share} className="share-icon" />
					<a
						className="social-link"
						href={`${fbURL}${shareUrl}`}
						target='_blank'
					>
						<img src={Images.facebookgrey} />
					</a>

					<a className="social-link" href={`mailto:?subject=You've%20been%20sent%20a%20Sunbeam%20${type}&body=Hey,%20check%20this%20amazing%20${type}%20out!%20${{shareUrl}}`}><img src={Images.emailgrey} /></a>

				</div>
			</div>
		</section>
		
	);
};

export default withRouter(SocialSharingContainer);