import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import HelpModule from '../container/partials/HelpModule';
import CategoryHero from '../container/partials/CategoryHero';
import ReactMarkdown from 'react-markdown';
import SeoComponent from '../component/SeoComponent';
import Breaks from 'remark-breaks';
import Loader from '../component/Loader';

const Support = () => {
	const { data, loading, error } = useQuery(SUPPORTLANDING);
	// console.log(data);
	if (loading) return <Loader />;
	if (error) return <p>error</p>;
	const { supportLandingPage } = data;
	// console.log(supportLandingPage);
	const {
		warranty,
		services,
		general_support,
		contact,
		product_support,
		social_sharing
	} = supportLandingPage;
	return (
		<div className="support-page">
			{social_sharing && <SeoComponent social={social_sharing} />}
			<CategoryHero content={supportLandingPage} />
			<section className="text-block -remove-padding-top">
				<div className="center-container grid-parent">
					<div className="text-wrapper grid-child-6-3 markdown-block">
						<ReactMarkdown children={supportLandingPage.copy} plugins={[Breaks]} />
					</div>
				</div>
			</section>
			<HelpModule content={warranty} color="grey" />
			<HelpModule content={services} color="white" />
			<HelpModule content={product_support} color="grey" />
			<HelpModule content={general_support} color="white" />
			<HelpModule content={contact} color="grey" />
		</div>
	);
};

export default Support;

const SUPPORTLANDING = gql`
	query SupportLandingPageQuery {
		supportLandingPage {
			id
			title
			copy
			social_sharing {
				id
				title
				description
				image {
					id
					url
					alternativeText
				}
			}
			hero_with_foreground {
				foreground {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
				background {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
			}

			warranty {
				id
				heading
				links {
					id
					link_text
					link_slug
				}
			}

			services {
				id
				heading
				links {
					id
					link_text
					link_slug
				}
			}

			contact {
				id
				heading
				links {
					id
					link_text
					link_slug
				}
			}

			general_support {
				id
				heading
				links {
					id
					link_text
					link_slug
				}
			}

			product_support {
				id
				heading
				links {
					id
					link_text
					link_slug
				}
			}
		}
	}
`;
