import React from 'react';
import DownloadManual from '../image/icons/download-manual.svg';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Config from '../common/config';
const isAU = Config.theme === 'AU';

const ManualBlock = ({ manual }) => {
	return (
		<div className="single-manual-download">
			<a className="manual-download-button" href={manual.file.url} target="_blank" rel="noopener noreferrer">
				<img
					className="manual-download-icon"
					alt={
						manual.type == 'manual'
							? 'Download User Guide'
							: 'Download Quick Guide'
					}
					src={DownloadManual}
				/>
				{manual.type == 'manual'
					? 'Download User Guide'
					: 'Download Quick Guide'}
			</a>
		</div>
	);
};

const ProductBlock = ({ product }) => {
	// console.log(product);
	if (!product) return null;
	const { manuals } = product;
	const link = `/kitchen-and-home/${product.subcategories[0].category.slug}/${product.subcategories[0].slug}/${product.slug}`;
	return (
		<>
			<div className="manual-download">
				<div className="manual-download-container">
					<div className="manual-download-inner">
						{product.card_image.url && (
							<img
								className="manual-download-image"
								src={product.card_image.url}
								alt={product.title}
							/>
						)}
						<div className="manual-download-content">
							<Link
								to={link}
								className="product-block"
							>
								<div className="manual-download-top">
									{product.primary_product_code && (
										<p className="manual-download-product-code">
											{product.primary_product_code}
										</p>
									)}
									{product.title && (
										<p className="manual-download-product-title">
											{product.title}
										</p>
									)}
									{isAU && product.price && (
										<p className="manual-download-product-title">
											{product.price.discontinued_au ? 'Discontinued' : `$${product.price.price_au}`}
										</p>
									)}
								</div>
							</Link>
							<div className="manual-download-bottom">
								{!isEmpty(manuals) &&
									manuals.map((manual, i) => {
										if (manual.file) {
											return (
												<ManualBlock
													manual={manual}
													key={i}
												/>
											);
										}
									})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

ProductBlock.propTypes = {
	product: PropTypes.object,
};

ManualBlock.propTypes = {
	manual: PropTypes.object,
};

export default ProductBlock;
