import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import Breaks from 'remark-breaks';

class AccordionToggle extends Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.state = {
			toggleClass: false,
		};
	}

	handleClick(e) {
		e.preventDefault();
		this.setState((prev) => ({
			toggleClass: !prev.toggleClass,
		}));
	}

	render() {
		const item = this.props.item;
		return (
			<div className="single-accordion">
				{item.image && (
					<div className="single-accordion-image">
						<img src={item.image.url} alt="" />
					</div>
				)}
				<div className="accordion-text">
					<a
						className={
							this.state.toggleClass
								? 'accordion-title -active'
								: 'accordion-title'
						}
						onClick={(e) => this.handleClick(e)}
						href="#"
					>
						<span>
							{item.visible_text}
						</span>
					</a>
					{this.state.toggleClass && (
						<div className="accordion-dropdown-text">
							<ReactMarkdown children={item.dropdown_text} plugins={[Breaks]} />
						</div>
					)}
				</div>
			</div>
		);
	}
}

AccordionToggle.propTypes = {
	item: PropTypes.object,
};

const Accordion = ({ accordion }) => {
	// console.log(accordion);
	if (!accordion) return null;
	const items = accordion.accordion_items || accordion;
	// const accordion = data.accordion;
	return (
		<div className="accordion-container">
			{items.map((item, i) => {
				return <AccordionToggle item={item} key={i} />;
			})}
		</div>
	);
};

// Accordion.propTypes = {
// 	accordion: PropTypes.object,
// };

export default Accordion;
