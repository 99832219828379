import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router';
import gql from 'graphql-tag';
import ProductList from './ProductList';
import Images from '../../image/Images';
import CardContainer from './CardContainer';
import { findIndex, isEmpty } from 'lodash';
import Checkbox from '../../component/Checkbox';
import DropDownMenu from '../../component/DropDownMenu';
const defaultLimit = 12;
const defaultMeal = 'All Meals';
const defaultApplication = 'All Appliances';

class CardPaginationFilterContainer extends Component {
	constructor(props) {
		super(props);
		this.state = this.getDefaultState();
	}

	checkDefaultTag = (defaultTag, tags) => {
		if (!defaultTag || isEmpty(tags)) return [];
		const found = tags.find((tag) => tag.tag === defaultTag);
		if (found) {
			return [defaultTag];
		}
		return [];
	};

	getDefaultState = () => {
		const { defaultTag, tags } = this.props;
		return {
			checkedItems: this.checkDefaultTag(defaultTag, tags),
			meal: defaultMeal,
			showFilter: false,
			limit: defaultLimit,
			application: defaultApplication,
		};
	};

	handleCheckbox = (e, tag) => {
		const item = e.target.name;
		const isChecked = e.target.checked;
		const { checkedItems } = this.state;
		const index = checkedItems.findIndex((element) => element === item);

		let newArr;
		if (isChecked) {
			if (index === -1) {
				newArr = [...checkedItems, item];
			}
		} else {
			newArr = checkedItems.filter((it) => {
				return it !== item;
			});
		}

		this.setState((prevState) => ({ checkedItems: newArr }));
	};

	handleDropdown = (e) => {
		const item = e.target.name;
		const value = e.target.value;
		this.setState({
			[item]: value,
		});
	};

	toggleFilter = () => {
		const { showFilter } = this.state;
		this.setState({
			showFilter: !showFilter,
		});
	};

	filterTags = (data, tags) => {
		if (isEmpty(tags)) {
			return data;
		} else {
			return data.filter((item, i) => {
				if (item.tags) {
					let hasTags = false;
					item.tags.forEach((tag) => {
						if (tags.includes(tag.tag)) {
							hasTags = true;
						}
					});
					return hasTags;
				}
			});
		}
	};

	filterMeal = (data, meal) => {
		if (isEmpty(meal) || meal === 'All Meals') {
			return data;
		} else {
			return data.filter((item) => {
				return item.meal && item.meal.meal_title === meal;
			});
		}
	};

	filterApplication = (data, application) => {
		if (isEmpty(application) || application === defaultApplication) {
			return data;
		} else {
			// console.log('data', data);
			return data.filter((item) => {
				return item.dropdown_tags.find((pro) => {
					return pro.Tag === application;
				});
			});
		}
	};

	resetFilter = () => {
		const defaultState = this.getDefaultState();
		this.setState(defaultState);
	};

	renderMealsFilter = (meals) => {
		const { meal } = this.state;
		return (
			<div className="filter-dropdown filter-wrapper-inner">
				<div className="filter-tag-header">
					<img className="" src={Images.filterMealtype} alt={''} />
					<h3>Meal Type</h3>
				</div>

				<DropDownMenu
					value={meal}
					name="meal"
					onChange={(e) => this.handleDropdown(e)}
				>
					{meals.map((m, i) => {
						return (
							<option value={m.meal_title} key={i}>
								{m.meal_title}
							</option>
						);
					})}
				</DropDownMenu>
			</div>
		);
	};

	renderTagsFilter = (tags) => {
		return (
			<div className="filter-wrapper-inner">
				<div className="filter-tag-header">
					<img className="" src={Images.flag} alt={''} />
					<h3>TAGS</h3>
				</div>
				<div className="tag-container">
					{tags.map((tag, i) => {
						return (
							<label key={i} className="container">
								{tag.tag}
								<Checkbox
									name={tag.tag}
									checked={this.state.checkedItems.includes(
										tag.tag
									)}
									onChange={(e) => this.handleCheckbox(e)}
								/>
								<span className="checkmark"></span>
							</label>
						);
					})}
				</div>
			</div>
		);
	};

	renderAppliancesFilter = (appliances) => {
		const { application } = this.state;
		return (
			<div className="filter-dropdown filter-wrapper-inner">
				<div className="filter-tag-header">
					<img className="" src={Images.filterAppliance} alt={''} />
					<h3>Appliances</h3>
				</div>

				<DropDownMenu
					value={application}
					name="application"
					onChange={(e) => this.handleDropdown(e)}
				>
					<option value={defaultApplication}>
						{defaultApplication}
					</option>
					{appliances.map((a, i) => {
						return (
							<option value={a.Tag} key={i}>
								{a.Tag}
							</option>
						);
					})}
				</DropDownMenu>
			</div>
		);
	};

	limitShowData = (data, limit) => {
		return data.slice(0, limit);
	};

	loadMore = () => {
		const { limit } = this.state;
		this.setState({ limit: limit + defaultLimit });
	};

	render() {
		const { tags, meals, data, appliances } = this.props;
		const {
			checkedItems,
			meal,
			showFilter,
			limit,
			application,
		} = this.state;
		let filteredData = [];
		filteredData = this.filterTags(data, checkedItems);
		filteredData = this.filterMeal(filteredData, meal);
		filteredData = this.filterApplication(filteredData, application);
		const limitedData = this.limitShowData(filteredData, limit);
		// console.log('application', application);
		return (
			<section className="card-container">
				<div className="center-container">
					<div className="inner-content -info">
						<div className="filter-wrapper result">
							<h3 className="filter-title">Filter</h3>
							<img
								className="reset-icon"
								src={Images.reset}
								alt={''}
								onClick={this.resetFilter}
							/>
						</div>

						<div className="filter-mobile">
							<button
								className="button -black -dark-grey-bg -single"
								onClick={this.toggleFilter}
							>
								{' '}
								Filter
							</button>
							<div className="">
								<p>{limitedData.length} results</p>
							</div>
						</div>

						<div className="card-wrapper -desktop">
							<p>{limitedData.length} results</p>
						</div>
					</div>
					<div className="inner-content">
						<div className="filter-wrapper">
							{meals && this.renderMealsFilter(meals)}
							{appliances &&
								this.renderAppliancesFilter(appliances)}
							{tags && this.renderTagsFilter(tags)}
						</div>

						{showFilter && (
							<div className="filter-wrapper-mobile">
								<div className="flex-row-between filter-wrapper-mobile-header">
									<h3>Filter</h3>
									<div>
										<img
											className="reset-icon"
											src={Images.resetWhite}
											alt={''}
											onClick={this.resetFilter}
										/>
										<img
											className="reset-icon"
											src={Images.closeWhite}
											alt={''}
											onClick={this.toggleFilter}
										/>
									</div>
								</div>
								{meals && this.renderMealsFilter(meals)}
								{appliances &&
									this.renderAppliancesFilter(appliances)}
								{tags && this.renderTagsFilter(tags)}

								<div className="apply-button-container">
									<button
										className="button -orange -grey-background"
										onClick={this.toggleFilter}
									>
										Apply
									</button>
								</div>
							</div>
						)}
						<CardContainer data={limitedData} />
					</div>
					{limitedData.length < filteredData.length && (
						<div className="load-more-container flex-row-center">
							<button
								className="button -orange"
								onClick={this.loadMore}
							>
								Load more
							</button>
						</div>
					)}
				</div>
			</section>
		);
	}
}

export default CardPaginationFilterContainer;
