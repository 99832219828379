import React from 'react';
import PropTypes from 'prop-types';
import Config from '../../common/config';
const isAU = Config.theme === 'AU';

const ProductNav = ({ toggleRetailers, showInTheBox, showFeatures, showFaqs }) => {

	return (
		<nav
			aria-label="Product Navigation"
			className="product-navigation sticky-nav"
		>
			<div className="center-container">
				<div className="product-navigation-wrapper">
					<ul className="product-navigation-link-container sticky-nav-inner">
						{showFeatures && (
							<li className="product-navigation-link sticky-nav-link">
								<a href="#features">Features</a>
							</li>
						)}
						<li className="product-navigation-link sticky-nav-link">
							<a href="#specifications">Specifications</a>
						</li>
						{showInTheBox && (
							<li className="product-navigation-link sticky-nav-link">
								<a href="#in-the-box">In the box</a>
							</li>
						)}
						{showFaqs && (
							<li className="product-navigation-link sticky-nav-link">
								<a href="#faq">FAQs</a>
							</li>
						)}
						{isAU && (
							<li className="product-navigation-link sticky-nav-link">
								<a href="#reviews">Reviews</a>
							</li>
						)}
						<li className="product-navigation-link sticky-nav-link">
							<a href="#warranty">Warranty</a>
						</li>
						<li className="product-navigation-link sticky-nav-link">
							<a href="#service">Service & Support</a>
						</li>
					</ul>
					<div className="product-nav-button-container">
						<a
							className="product-navigation-button button -orange"
							href="#"
							onClick={(e) => toggleRetailers(e)}
						>
							Where to buy
						</a>
					</div>
				</div>
			</div>
		</nav>
	);
};

ProductNav.propTypes = {
	toggleRetailers: PropTypes.func,
};

export default ProductNav;
