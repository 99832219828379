import React, { useState, Fragment } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {isEmpty} from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import Images from '../image/Images';

const MobileHeaderDropdownItem = ({category, history, closeMenu}) => {
	const [subdropdown, setSubdropdown] = useState(false);
	let top_style = ['category-link -with-dropdown'];
	if(subdropdown) {
		top_style.push('-active');
	}

	const filterOptions = category.subcategories.filter((sub) => {
		return true;
		// return !isEmpty(sub.products);
	});

	const onClick = (link) => {
		closeMenu(false);
		history.push({
			pathname: link,
		});
	};

	return (
		<Fragment>
			<div className={top_style.join(' ')}
				onClick={(e) => {
					setSubdropdown(!subdropdown);
				}}
		 >
				<p>{category.title}</p>		
			</div>
			{subdropdown && <div className="subcategory-wrapper">
				{
					filterOptions.map((subcategory, index) => {
						const cardImage = subcategory.card_image && subcategory.card_image.url || Images.fallbackCardImage;
						console.log(cardImage);
						return (
							<a onClick={ () => onClick(`/kitchen-and-home/${category.slug}/${subcategory.slug}`)} className="subcategory-link" key={index}>
								<img src={cardImage} className="subcategory-image" />
								<p>{subcategory.title}</p>
							</a>
						);
					})
				}
			</div>
			}
		</Fragment>
	);
};

export default withRouter(MobileHeaderDropdownItem);
