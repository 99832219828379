import React from 'react';

// const specifications = {
// 	construction_finish: 'Silver',
// 	dimensions: {
// 		Height_mm: 12,
// 		Width_mm: 5,
// 		Depth_mm: 5,
// 	},
// 	weight: {
// 		Net_weight_kg: 50,
// 		Gross_weight_kg: 12,
// 	},
// 	custom_specifications: [
// 		{
// 			label: 'Label',
// 			value: 'Value',
// 		},
// 		{
// 			label: 'Label',
// 			value:
// 				'58mm Group Handle, 1 cup and 2 cup Single Wall Filter Baskets, Tamper, 460ml stainless steel Milk Jug, Cleaning Pin, Cleaning Brush and Cleaning Disc',
// 		},
// 		{
// 			label: 'Label',
// 			value: 'Value',
// 		},
// 	],
// };

const Specifications = (data) => {
	const specifications = data.specifications;
	const colour = data.colour;
	// console.log(specifications);
	if (!specifications) return null;
	const customSpecifications = specifications.custom_specifications.map(
		(item, i) => {
			if (item.label && item.value) {
				return (
					<div key={i} className="single-specification">
						<h4 className="specification-type">{item.label}</h4>
						<p className="specification-details">{item.value}</p>
					</div>
				);
			}
		}
	);

	return (
		<div className="specifications-inner">
			{colour && (
				<div className="single-specification">
					<h4 className="specification-type">Colour</h4>
					<p className="specification-details">{colour}</p>
				</div>
			)}
			{specifications.dimensions &&
				specifications.dimensions.height_mm &&
				specifications.dimensions.width_mm &&
				specifications.dimensions.depth_mm && (
				<div className="single-specification">
					<h4 className="specification-type">
							Dimension H x W x D (mm)
					</h4>
					<p className="specification-details">
						{specifications.dimensions.height_mm} x{' '}
						{specifications.dimensions.width_mm} x{' '}
						{specifications.dimensions.depth_mm}
					</p>
				</div>
			)}
			{specifications.weight && specifications.weight.net_weight_kg &&
				specifications.weight.gross_weight_kg && (
				<div className="single-specification">
					<h4 className="specification-type">
							Net Weight / Gross Weight (kg)
					</h4>
					<p className="specification-details">
						{specifications.weight.net_weight_kg}kg /{' '}
						{specifications.weight.gross_weight_kg}kg
					</p>
				</div>
			)}
			{specifications.custom_specifications[0].label &&
				customSpecifications}
		</div>
	);
};

export default Specifications;
