import React from 'react';
import CardComponent from '../../component/CardComponent';
import Masonry from 'react-masonry-css';

const CardContainer = ({ data, selectedColour }) => {

	const breakpointColumnsObj = {
		default: 3,
		1100: 2,
		600: 1
	};


	return (
		<div className="card-wrapper">
			<Masonry
				breakpointCols={breakpointColumnsObj}
				className="masonry-grid"
				columnClassName="masonry-grid-column">
				{data.map((item, i) => {
					return <CardComponent data={item} key={i} selectedColour={selectedColour} />;
				})}
			</Masonry>
		</div>
	);
};

export default CardContainer;
