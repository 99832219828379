import gql from 'graphql-tag';

const CATEGORY_QUERY = gql`
	query Categories($category: String) {
		categories(where: { slug: $category }, limit: 1) {
			id
			slug
			title
			related_content_heading
			hero_with_foreground {
				id
				foreground {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
				background {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
			}
			collections_range {
				id
				collection {
					id
					title
					tagline
					slug
					card_image {
						id
						name
						url
						alternativeText
						caption
						width
						height
						formats
						previewUrl
					}
					card_image_large {
						id
						name
						hash
						url
						alternativeText
						width
						height
						formats
						previewUrl
					}
				}
			}
			related_content {
				... on ComponentRepeatedRelatedRecipe {
					id
					recipe {
						id
						title
						slug
						status
						tagline
						card_image {
							id
							url
						}
					}
				}
				... on ComponentRepeatedRelatedHintsTip {
					id
					hints_tip {
						id
						title
						status
						slug
						tagline
						card_image {
							id
							url
						}
					}
				}
			}
			social_sharing {
				id
				title
				description
				image {
					id
					url
					alternativeText
				}
			}
			subcategories {
				id
				title
				slug
				products (limit: 4, where: { status: "published"}) {
					id
					title
					slug
					status
					subcategories {
						id
						slug
						title
						category {
							id
							slug
							title
						}
					}
					card_image {
						id
						alternativeText
						url
					}
					primary_ean
					price {
						id
						discontinued_au
						price_au
					}
					awards_and_icons {
						id
						featured_icon {
							id
							icon {
								id
								image {
									id
									url
									alternativeText
								}
							}
						}
					}
					primary_product_code
				}
				prioritise_product {
					id
					products (limit: 4, where: { status: "published"}) {
						id
						title
						slug
						status
						card_image {
							id
							alternativeText
							url
						}
						primary_ean
						primary_product_code
						price {
							id
							discontinued_au
							price_au
						}
						subcategories {
							id
							slug
							title
							category {
								id
								slug
								title
							}
						}
					}
				}
			}
		}
	}
`;

// need fragment for related content fields

export default CATEGORY_QUERY;
