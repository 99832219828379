import React, {Fragment, Component} from 'react';
import  { Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router';
import {isEmpty} from 'lodash';
import PRODUCT_QUERY from '../queries/product/product';
import ProductContainer from '../container/ProductContainer';
import SeoComponent from '../component/SeoComponent';
import Loader from '../component/Loader';

const Product = () => {
	// let { category } = useParams();
	let { productslug, categoryslug, subcategoryslug } = useParams();
	const { loading, error, data } = useQuery(PRODUCT_QUERY, {
		variables: { productslug, categoryslug, subcategoryslug },
		fetchPolicy: 'no-cache'
	});
	if (loading) return <Loader />;
	if (error)  return  null;

	const {products} = data;
	let product;
	if(!isEmpty(products)) {
		product = products[0];
	}

	return (
		<Fragment>
			{product.social_sharing && <SeoComponent social={product.social_sharing} />}
			{product && <ProductContainer product={product} categoryslug={categoryslug} subcategoryslug={subcategoryslug} />}
			{!product && <Redirect to='/'  />}
		</Fragment>
	);
};

export default Product;


