import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './styles/main.scss'; // Entry point of scss
import Routes from './routes';
import config from './common/config';

// console.log(config.gtmId);

const tagManagerArgs = {
	gtmId: config.gtmId,
	// dataLayer: {
	//     userId: '001',
	//     userProject: 'project'
	// }
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(<Routes />, document.getElementById('root'));