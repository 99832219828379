import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import HelpModule from '../container/partials/HelpModule';
import SeoComponent from '../component/SeoComponent';
import CategoryHero from '../container/partials/CategoryHero';
import Accordion from '../component/Accordion';
import ReactMarkdown from 'react-markdown';
import Breaks from 'remark-breaks';
import Loader from '../component/Loader';

const FAQ = () => {
	const [search, setSearch] = useState('');
	const { data, loading, error } = useQuery(FAQPAGE);
	console.log(data);
	if (loading) return <Loader />;
	if (error) return <p>error</p>;
	const { supportLandingPage, faqPage } = data;
	console.log(supportLandingPage);
	const { warranty, services, product_support, contact } = supportLandingPage;

	const faqBlock = faqPage.faqs_with_title.map((p, i) => {
		if (!p.faqs) return null;
		return (
			<div key={i} className="faqs-section">
				<h2 className="alt-section-heading">
					{p.title}
				</h2>
				<Accordion accordion={p.faqs} />
			</div>
		);
	});

	// fix search faq later
	return (
		<div className="faq-page">
			{faqPage.social_sharing && <SeoComponent social={faqPage.social_sharing} />}
			<CategoryHero content={faqPage} />
			<section className="text-block -remove-padding-top">
				<div className="center-container grid-parent">
					<div className="text-wrapper grid-child-6-3 markdown-block">
						<ReactMarkdown children={faqPage.copy} plugins={[Breaks]} />
					</div>
				</div>
			</section>
			<section className="faq-container -remove-padding-top">
				<div className="center-container grid-parent">
					<div className="wrapper grid-child-8">
						{/* <div className="text-block wrapper grid-child-6">
							<div className="search-bar">
								<form onSubmit={() => {}}>
									<div className="input-field">
										<input
											type="search"
											value={search}
											onChange={(e) => setSearch(e.target.value)}
											id="search"
											// eslint-disable-next-line react/jsx-no-duplicate-props
											type="search"
											name="search"
											className={'search-field'}
											placeholder="Search"
										/>
										<div className="search-icon"></div>
									</div>
								</form>
							</div>

						</div> */}
						{faqBlock}
					</div>
				</div>
			</section>
			<HelpModule content={warranty} color="white" />
			<HelpModule content={services} color="grey" />
			<HelpModule content={product_support} color="white" />
			<HelpModule content={contact} color="grey" />
		</div>
	);
};

export default FAQ;

const FAQPAGE = gql`
	query FaqPageQuery {
		supportLandingPage {
			id
			title
			copy
			warranty {
				id
				heading
				links {
					id
					link_text
					link_slug
				}
			}
			services {
				id
				heading
				links {
					id
					link_text
					link_slug
				}
			}

			contact {
				id
				heading
				links {
					id
					link_text
					link_slug
				}
			}

			general_support {
				id
				heading
				links {
					id
					link_text
					link_slug
				}
			}

			product_support {
				id
				heading
				links {
					id
					link_text
					link_slug
				}
			}
		}

		faqPage {
			id
			title
			copy
			faqs_with_title {
				id
				title
				faqs {
					id
					visible_text
					dropdown_text
				}
			}

			social_sharing {
				id
				title
				description
				image {
					id
					url
					alternativeText
				}
			}
			hero_with_foreground {
				id
				foreground {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
				background {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
			}
		}
	}
`;
