import React, { Component } from 'react';
import ProductList from './ProductList';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router';
import gql from 'graphql-tag';
import Images from '../../image/Images';
import { Link } from 'react-router-dom';
import CardContainer from './CardContainer';
import { findIndex, isEmpty } from 'lodash';
import Checkbox from '../../component/Checkbox';
import DropDownMenu from '../../component/DropDownMenu';
import hexCode from '../../utils/hexCode';
import Slider from 'rc-slider';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const sortOptions = {
	'Sort by most recent': 0,
	'Price: Low to high': 1,
	'Price: High to low': 2
};

const marks = {
	0: <strong>0</strong>,
	50: '$50',
	100: {
		style: {
			color: '#E0232F',
		},
		label: <strong>$150</strong>,
	},
};

class RangeComponent extends Component {
	constructor(props) {
		super(props);
	}

	renderMark = (value) => {
		return {
			style: {
				color: '#E0232F',
			},
			label: <strong>${value}</strong>,
		};
	}
 
	render() {
		const {onSliderChange, value, maxPrice} = this.props;  	
		const marks = {
			0: this.renderMark(0),
		};
		marks[maxPrice] = this.renderMark(maxPrice);
		return (
			<div className="rc-slider-wrapper">
				<Range value={value} min={0} max={maxPrice}
					onChange={onSliderChange}
					marks={marks}
					tipFormatter={value => `$${value}`}
				/>
			</div>
		);
	}
}

const ColourComponent = ({colour, onClick, isActive}) => {
	return (
		<label
			className="flex-center color-block"
		>
			<a
				onClick={onClick}
				className={
					isActive
						? 'single-product-colour -active'
						: 'single-product-colour'
				}
			>
				<div
					className="colour-inside"
					style={hexCode(
						colour
					)}
				></div>
			</a>
			{colour.title}
		</label>
	);
};

class CardFilterContainer extends Component {
	constructor(props) {
		super(props);
		const maxPrice = props.maxPrice;
		
		this.state = {
			checkedItems: [],
			meal: '',
			alliance: '',
			showFilter: false,
			featureItems: [],
			finishItems: [],
			selectedColour: null,
			priceRange: maxPrice ? [0, Math.ceil(maxPrice)] : [],
			hasMore: props.data.length >= props.limit,
			sortby: 2,
			showSort: false
		};
	}

	componentDidUpdate(prevProps) {
		const {data, limit, onLoadMore } = this.props;
		if (onLoadMore && data !== prevProps.data) {
			if((data.length - prevProps.data.length) < (limit || 12 )) {
				this.setState({hasMore: false});
			}
		}

		if (this.props.maxPrice !== prevProps.maxPrice) { 
			this.setState({priceRange: [0, Math.ceil(this.props.maxPrice)]});
		}
	}

	handleCheckbox = (e, tag) => {
		const item = e.target.name;
		const isChecked = e.target.checked;
		const { checkedItems } = this.state;
		const index = checkedItems.findIndex((element) => element === item);

		let newArr;
		if (isChecked) {
			if (index === -1) {
				newArr = [...checkedItems, item];
			}
		} else {
			newArr = checkedItems.filter((it) => {
				return it !== item;
			});
		}
		// console.log(newArr);
		this.setState((prevState) => ({ checkedItems: newArr }));
	};

	handleColour = (colour) => {
		this.setState({
			selectedColour : colour
		});
	};

	handleFeatureCheckbox = (e, feature) => {
		const item = e.target.name;
		const isChecked = e.target.checked;
		const { featureItems } = this.state;
		const index = featureItems.findIndex((element) => element === item);

		let newArr;
		if (isChecked) {
			if (index === -1) {
				newArr = [...featureItems, item];
			}
		} else {
			newArr = featureItems.filter((it) => {
				return it !== item;
			});
		}
		// console.log(newArr);
		this.setState((prevState) => ({ featureItems: newArr }));
	};

	handleFinishesCheckbox = (e, feature) => {
		const item = e.target.name;
		const isChecked = e.target.checked;
		const { finishItems } = this.state;
		const index = finishItems.findIndex((element) => element === item);

		let newArr;
		if (isChecked) {
			if (index === -1) {
				newArr = [...finishItems, item];
			}
		} else {
			newArr = finishItems.filter((it) => {
				return it !== item;
			});
		}
		// console.log(newArr);
		this.setState((prevState) => ({ finishItems: newArr }));
	};


	handleDropdown = (e) => {
		const item = e.target.name;
		const value = e.target.value;
		// console.log(item);
		// console.log(value);
		this.setState({
			[item]: value,
		});
	};

	toggleFilter = () => {
		let html = document.getElementById('html');
		html.classList.toggle('overflow-hidden');

		const { showFilter } = this.state;
		this.setState({
			showFilter: !showFilter,
		});
	};

	toggleSort = () => {
		let html = document.getElementById('html');
		html.classList.toggle('overflow-hidden');

		const { showSort } = this.state;
		this.setState({
			showSort: !showSort,
		});
	};

	filterTags = (data, tags) => {
		if (isEmpty(tags)) {
			return data;
		} else {
			return data.filter((item, i) => {
				if (item.tags) {
					let hasTags = false;
					item.tags.forEach((tag) => {
						if (tags.includes(tag.tag)) {
							hasTags = true;
						}
					});
					return hasTags;
				}
			});
		}
	};

	filterFeatures = (data, features) => {
		if (isEmpty(features)) {
			return data;
		} else {
			return data.filter((item, i) => {
				if (item.features && item.features.accordion_items) {
					let hasFeature = false;
					item.features.accordion_items.forEach((feature) => {
						if (features.includes(feature.visible_text)) {
							hasFeature = true;
						}
					});
					return hasFeature;
				}
			});
		}
	};

	

	filterColours = (data, selectedColour) => {
		if (isEmpty(selectedColour)) {
			return data;
		} else {
			return data.filter((item, i) => {
				let hasFeature = false;
				if (item.product_colour && item.product_colour) {				
					item.product_colour.forEach((colour) => {
						if (colour.colour === selectedColour ) {
							hasFeature = true;
						}
					});
					
				}
				return hasFeature;
			});
		}
	};

	filterMeal = (data, meal) => {
		if (isEmpty(meal) || meal === 'All Meals') {
			return data;
		} else {
			return data.filter((item, i) => {
				return item.meal && item.meal.meal_title === meal;
			});
		}
	};

	resetFilter = () => {
		const maxPrice = this.props.maxPrice;
		this.setState({
			checkedItems: [],
			meal: '',
			alliance: '',
			featureItems: [],
			selectedColour: null,
			priceRange: maxPrice ? [0, Math.ceil(maxPrice)] : [],
			finishItems: [],
		});
	};

	renderFeatureFilter = (features) => {
		return (
			<div className="filter-wrapper-inner">
				<div className="filter-tag-header">
					<img
						className=""
						src={Images.featurIcon}
						alt={''}
					/>
					<h3>FEATURES</h3>
				</div>
				<div className="tag-container">
					{features.map((feature, i) => {
						return (
							<label
								key={i}
								className="container"
							>
								{feature}
								<Checkbox
									name={feature}
									checked={this.state.featureItems.includes(
										feature
									)}
									onChange={(e) =>
										this.handleFeatureCheckbox(
											e
										)
									}
								/>
								<span className="checkmark"></span>
							</label>
						);
					})}
				</div>
			</div>
		);
	}

	renderFinishFilter = (finishes) => {
		return (
			<div className="filter-wrapper-inner">
				<div className="filter-tag-header">
					<img
						className=""
						src={Images.featurIcon}
						alt={''}
					/>
					<h3>FEATURES</h3>
				</div>
				<div className="tag-container">
					{finishes.map((finish, i) => {
						return (
							<label
								key={i}
								className="container"
							>
								{finish}
								<Checkbox
									name={finish}
									checked={this.state.finishItems.includes(
										finish
									)}
									onChange={(e) =>
										this.handleFinishesCheckbox(
											e
										)
									}
								/>
								<span className="checkmark"></span>
							</label>
						);
					})}
				</div>
			</div>
		);
	}

	filterFinishes = (data, finishes) => {
		if (isEmpty(finishes)) {
			return data;
		} else {
			return data.filter((item, i) => {
				let hasFinish = false;
				if (item.specifications && item.specifications.construction_finish) {
					if (finishes.includes(item.specifications.construction_finish)) {
						hasFinish = true;
					}
				}
				return hasFinish;
			});
		}
	};

	renderColourFilter = (colours) => {
		const { selectedColour } = this.state;
		// const that = this;
		return (
			<div className="filter-wrapper-inner">
				<div className="filter-tag-header">
					<img
						className=""
						src={Images.colourIcon}
						alt={''}
					/>
					<h3>Colour</h3>
				</div>
				<div className="flex-row-even">
					{
						colours.map((colour, i) => {
							return (
								<ColourComponent 
									colour={colour}
									isActive={selectedColour === colour}
									key={i}
									onClick = {() => this.handleColour(colour)}
								/>
							);
						})
					}
				</div>
			</div>
		);
	}

	renderMealsFilter = (meals) => {
		const { meal } = this.state;
		// const that = this;
		return (
			<div className="filter-dropdown filter-wrapper-inner">
				<div className="filter-tag-header">
					<img
						className=""
						src={Images.filterMealtype}
						alt={''}
					/>
					<h3>Meal Type</h3>
				</div>

				<DropDownMenu
					value={meal}
					name="meal"
					onChange={(e) => this.handleDropdown(e)}
				>
					{meals.map((m, i) => {
						return (
							<option
								value={m.meal_title}
								key={i}
							>
								{m.meal_title}
							</option>
						);
					})}
				</DropDownMenu>
			</div>
		);
	}

	renderTagsFilter = (tags) => {
		return (
			<div className="filter-wrapper-inner">
				<div className="filter-tag-header">
					<img
						className=""
						src={Images.flag}
						alt={''}
					/>
					<h3>TAGS</h3>
				</div>
				<div className="tag-container">
					{tags.map((tag, i) => {
						return (
							<label
								key={i}
								className="container"
							>
								{tag.tag}
								<Checkbox
									name={tag.tag}
									checked={this.state.checkedItems.includes(
										tag.tag
									)}
									onChange={(e) =>
										this.handleCheckbox(
											e
										)
									}
								/>
								<span className="checkmark"></span>
							</label>
						);
					})}
				</div>
			</div>
		);
	}

	

	onSliderChange = (priceRange) => {
	  	this.setState({
	  		priceRange
	  	});
	}

	filterPrice = (data, priceRange) => {
		if (isEmpty(priceRange)) {
			return data;
		} else {
			const min = priceRange[0];
			const max = priceRange[1];
			return data.filter((item, i) => {
				const price = item.price && item.price.price_au;
				if(!price) return false;
				return min <= price && price <= max;
			});
		}
	};

	renderPiceRange = (maxPrice) => {
		const {priceRange} = this.state;
		// console.log(maxPrice);
		// console.log(priceRange);
		return (
			<div className="filter-wrapper-inner">
				<div className="filter-tag-header">
					<img
						className=""
						src={Images.priceIcon}
						alt={''}
					/>
					<h3>PRICE</h3>
				</div>
				<div className="tag-container -price">
					<RangeComponent
						maxPrice={Math.ceil(maxPrice)}
						value={priceRange}
						onSliderChange={this.onSliderChange}
						tipFormatter={value => `$${value}`}
					/>
				</div>
			</div>
		);
	}

	sortByDate = (data) => {
		return data.sort((a, b) => {
		  return new Date(b.created_at) - new Date(a.created_at);
		});
	}

	sortByPrice = (data, type) => {
		return data.sort((a, b) => {
		  if(!a.price) return -1;
		  if(!b.price) return -1;
		  return type === 'desc' ? a.price.price_au - b.price.price_au : b.price.price_au - a.price.price_au;
		});
	}

	sortProducts = (data) => {
		const {sortby} = this.state;
		// console.log(sortby);
		switch (Number(sortby)) {
		 	case 0:
		 		return this.sortByDate(data);
		 		break;
		 	case 1:
		 		return this.sortByPrice(data, 'desc');
		 		break;
		 	case 2:
		 		return this.sortByPrice(data, 'asc');
		 		break;
		 	default:
		 		return data;
		 		break;
		 }
	}

	renderSortOption = () => {
		const {sortby} = this.state;
		return (
			<div className="filter-wrapper-inner">
				<div className="tag-container">
					{Object.keys(sortOptions).map((option, i) => {
						return (
							<label
								key={i}
								className="container"
							>
								{option}
								<input type='radio' value={sortOptions[option]} name={option} checked={sortby == sortOptions[option]} onChange={(e)=>this.setState({sortby: e.target.value})} />
								<span className="checkmark"></span>
							</label>
						);
					})}
				</div>
			</div>
		);
	}

	render() {
		const { tags, data, meals, type, keyword, features, colours, maxPrice, finishes, onLoadMore, limit, subcategorySlug, sort } = this.props;
		const { checkedItems, meal, alliance, showFilter, featureItems, selectedColour, priceRange, finishItems, hasMore, sortby, showSort } = this.state;
		// console.log(data);
		// console.log(meal);
		// console.log(priceRange);
		// console.log(sortby);
		let filteredData = [];
		filteredData = this.filterTags(data, checkedItems);
		filteredData = this.filterMeal(filteredData, meal);
		filteredData = this.filterFeatures(filteredData, featureItems);
		filteredData = this.filterColours(filteredData, selectedColour);
		filteredData = this.filterPrice(filteredData, priceRange);
		filteredData = this.filterFinishes(filteredData, finishItems);
		if(sort) {
			filteredData = this.sortProducts(filteredData);
		}
		// console.log(filteredData);
		return (
			<section className="card-container">
				<div className="center-container">
					<div className="inner-content -info">
						<div className="filter-wrapper result">
							<h3 className="filter-title">Filter</h3>
							<img
								className="reset-icon"
								src={Images.reset}
								alt={''}
								onClick={this.resetFilter}
							/>
						</div>

						<div className="filter-mobile">
							{sort && <div className="filter-button-container">
								<button
									className="button -black -left -dark-grey-bg"
									onClick={this.toggleFilter}
								>
									{' '}
											Filter
								</button>
								<button
									className="button -black -right -dark-grey-bg"
									onClick={this.toggleSort}
								>
									{' '}
											Sort
								</button>
							</div>
							}
							{!sort && <div className="filter-button-container -single">
								<button
									className="button -black -dark-grey-bg -single"
									onClick={this.toggleFilter}
								>
									{' '}
											Filter
								</button>
							</div>
							}
							<div className="">
								{ keyword && <p>{filteredData.length} results for keyword <span style={{color: '#E0232F'}}>{keyword}</span></p>}
								{!keyword && <p>{filteredData.length} results</p>}
							</div>
						</div>

						<div className="card-wrapper -desktop">
							{ keyword && <p>{filteredData.length} results for keyword <span style={{color: '#E0232F'}}>{keyword}</span></p>}
							{!keyword && <p>{filteredData.length} results</p>}

							{sort && <div className='sort-container'> 
								<DropDownMenu value={sortby} name='sort' onChange={(e)=>this.setState({sortby: e.target.value})}>
									{Object.keys(sortOptions).map((op) => {
										return <option
											value={sortOptions[op]}
											key={sortOptions[op]}
										>
											{op}
										</option>;
									})}
								</ DropDownMenu>
							</div>}
						</div>
					</div>
					<div className="inner-content">
						<div className="filter-wrapper">
							{features && this.renderFeatureFilter(features)}
							{finishes && this.renderFinishFilter(finishes)}
							{maxPrice && this.renderPiceRange(maxPrice)}
							{colours && this.renderColourFilter(colours)}
							{meals && this.renderMealsFilter(meals)}
							{tags && this.renderTagsFilter(tags)}
						</div>

						{showFilter && (
							<div className="filter-wrapper-mobile">
								<div className="flex-row-between filter-wrapper-mobile-header">
									<h3>Filter</h3>
									<div>
										<img
											className="reset-icon"
											src={Images.resetWhite}
											alt={''}
											onClick={this.resetFilter}
										/>
										<img
											className="reset-icon"
											src={Images.closeWhite}
											alt={''}
											onClick={this.toggleFilter}
										/>
									</div>
								</div>
								{features && this.renderFeatureFilter(features)}
								{finishes && this.renderFinishFilter(finishes)}
								{maxPrice && this.renderPiceRange(maxPrice)}
								{colours && this.renderColourFilter(colours)}
								{meals && this.renderMealsFilter(meals)}
								{tags && this.renderTagsFilter(tags)}

								<div className="apply-button-container">
									<button
										className="button -orange -grey-background"
										onClick={this.toggleFilter}
									>
										Apply
									</button>
								</div>
							</div>
						)}
						{showSort && (
							<div className="filter-wrapper-mobile">
								<div className="flex-row-between filter-wrapper-mobile-header">
									<h3>Sort</h3>
									<div>
										<img
											className="reset-icon"
											src={Images.closeWhite}
											alt={''}
											onClick={this.toggleSort}
										/>
									</div>
								</div>
								{this.renderSortOption()}

								<div className="apply-button-container">
									<button
										className="button -orange -grey-background"
										onClick={this.toggleSort}
									>
										Apply
									</button>
								</div>
							</div>
						)}
						{
							type === 'product-index' ? 
								<ProductList items={filteredData} selectedColour={selectedColour} type="product-index" subcategorySlug={subcategorySlug} /> :
								<CardContainer data={filteredData} selectedColour={selectedColour} />
						}
					</div>
					{onLoadMore && hasMore &&
					<div className="load-more-container flex-row-center">
						<button className="button -orange" onClick={onLoadMore}>
								Load more
						</button>
					</div>
					}
				</div>
			</section>
		);
	}
}

export default CardFilterContainer;
