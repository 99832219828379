import React from 'react';

const DropDownMenu = ({
	type = 'checkbox',
	name,
	value,
	onChange,
	children,
}) => (
	<div className="selectWrapper">
		<select
			className="selectBox"
			value={value}
			onChange={onChange}
			name={name}
		>
			{children}
		</select>
	</div>
);

export default DropDownMenu;
