import React, { Component } from 'react';
import {isEmpty} from 'lodash';
import Salesforce from '../common/Salesforce';
import Config from '../common/config';
const isAU = Config.theme === 'AU';

export default class PromoForm extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			firstname: '',
			lastname: '',
			email: '',
			phone: '',
			message: '',
			agree: false,
			consent: false,
			streetaddress: '',
			suburb: '',
			state: '',
			postcode: '',
			country: '',
			attachmentData: null,
			gift: '',
			pop: '',
			product: '',
			drawanswer: '',
			sbError: '',
			buttonmsg: 'Submit',
			submitting: false
		};
	}

	checkFileSize= (event) => {
		let files = event.target.files;
		// let size = 1000000;
		let err = ''; 
		for(var x = 0; x < files.length; x++) {
			const filesize = ((files[x].size/1024)/1024).toFixed(4); //MB
			if (filesize > 10) {
				err += files[x].type + 'is too large, please pick a smaller file\n';
			}
		}
		if (err !== '') {
			event.target.value = null;
			console.log(err);

			this.setState({sbError: err});
			return false;
		}
		
		return true;
	}

	onFileChange = async e => {
		// Make new FileReader
		
		let files = e.target.files;
		if(!this.checkFileSize(e)) return; 
		  
		let that = this;
		let allFiles = [];

		for (let i = 0; i < files.length; i++) {
			let file = files[i];
			// Make new FileReader
			let reader = new FileReader();
			// Convert the file to base64 text
			reader.readAsDataURL(file);
			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				let fileInfo = {
					fileName: file.name,
					fileMimeType: file.type,
					fileBodyBase64: reader.result.replace('data:image/png;base64,', ''),
					// size: Math.round(file.size / 1000) + ' kB',
					// file: file,
				};

				allFiles.push(fileInfo);
				if(allFiles.length == files.length){
					that.setState({ attachmentData: allFiles, sbError: '' }); 
				}
			};
		}
	};

	handleInput = (e) => {
		const { name, value, type } = e.target;
		if (type === 'checkbox') {
			const isChecked = e.target.checked;
			return this.setState({
				[name]: isChecked,
				sbError: ''
			});
		}

		this.setState({
			[name]: value,
			sbError: '',
			successMsg: ''
		});
	};

	buildSubmitData = (
		firstname,
		lastname,
		email,
		phone,
		agree,
		consent,
		streetaddress,
		suburb,
		state,
		postcode,
		country,
		gift,
		pop,
		product,
		drawanswer,
		attachmentData
	) => {
		const {content} = this.props;
		const question = content.draw_question ? content.draw_question : '';
		const campaignID = content.campaign_id;
		const campaignName = content.campaign_name;
		const campaignType = content.campaign_type;

		if (attachmentData[0].fileMimeType === 'image/jpeg') {

			let str = attachmentData[0].fileBodyBase64;

			let newstr = str.replace('data:image/jpeg;base64,', '');

			attachmentData[0].fileBodyBase64 = newstr;
		}

		return {
			'brand': 'Sunbeam',
			'campaignType': campaignType,
			'campaignId': campaignID,
			'campaignName': campaignName,
			'contactData': {
				'email': email,
				'firstName': firstname,
				'lastName': lastname,
				'mailingCity': suburb,
				'mailingCountryCode': isAU ? 'AU': 'NZ',
				'mailingPostalCode': postcode,
				'mailingStateCode': state,
				'mailingstreet': streetaddress,
				'phone': phone, 
				'salutation': ''
			},
			'attachmentData': attachmentData[0],
			'locale': isAU ? 'en_AU': 'en_NZ',
			'languageIsoCode': 'en',
			'productData':{
				'productNumber': product,
				'quantity': 1
			},
			'giftData': {
				'giftItem': gift || '-',
				'question': question,
				'Answer': drawanswer
			},
			'additionalDataList': [
				{
					'key': 'placeofPurchase',
					'value': pop
				},
				{
					'key': 'subscriptionConsent',
					'value': consent
				}
			],
			'source': window.location.host
		};
	}

	handleSubmit = async e => {
		try {
			e.preventDefault();

			const {
				firstname,
				lastname,
				email,
				phone,
				agree,
				consent,
				streetaddress,
				suburb,
				state,
				postcode,
				country,
				gift,
				pop,
				product,
				drawanswer,
				attachmentData
			} = this.state;
			// console.log('submit here');

			if(firstname === '' || lastname === '' || email === '' || phone === '' || streetaddress === '' 
				|| suburb === '' || state === '' || postcode === ''  || attachmentData === []
			) {
				return this.setState({
					sbError: '* Please fill out all your details'
				});
			}

			if(!isEmpty(this.props.content.product_picker) && product === '') {
				return this.setState({
					sbError: '* Please choose products!'
				});
			}

			if(!isEmpty(this.props.content.gift) && gift === '') {
				return this.setState({
					sbError: '* Please choose gift type!'
				});
			}

			if(!isEmpty(this.props.content.draw_question) && drawanswer === '') {
				return this.setState({
					sbError: '* Please fill out drawanswer!'
				});
			}

			if(!agree) {
				return this.setState({
					sbError: '* You have to agree our privacy policy to continue'
				});
			}

			const formData = this.buildSubmitData(
				firstname,
				lastname,
				email,
				phone,
				agree,
				consent,
				streetaddress,
				suburb,
				state,
				postcode,
				country,
				gift,
				pop,
				product,
				drawanswer,
				attachmentData
			);

			console.log(formData);

			this.setState({submitting: true});
			this.setState({buttonmsg: "Submitting..."});
			const res = await Salesforce.promoForm(formData, email);
			// console.log(res);
			if(res.success) {
				const message = `You have successfully submitted your message with ContactId Id ${res.data.data && res.data.data.contactId}`;
				this.setState({
					firstname: '',
					lastname: '',
					email: '',
					phone: '',
					agree: false,
					consent: false,
					streetaddress: '',
					suburb: '',
					state: '',
					postcode: '',
					country: '',
					gift: '',
					pop: '',
					product: '',
					drawanswer: '',
					attachmentData: null,
					sbError: '',
					successMsg: message,
					buttonmsg: 'Submit'
				});
			} else {
				this.setState({
					sbError: res.error,
					buttonmsg: 'Submit'
				});
			}

			this.setState({submitting: false, buttonmsg: 'Submit'});

		} catch(err) {
			console.log(err);
		}
	};

	render() {
		// console.log(this.props.items);
		const {
			firstname,
			lastname,
			email,
			phone,
			message,
			agree,
			consent,
			streetaddress,
			suburb,
			state,
			postcode,
			// country,
			drawanswer,
			product,
			gift,
			pop,
			attachmentData,
			sbError,
			successMsg,
			buttonmsg,
		} = this.state;
		
		let StateDropdown;
		if (isAU) {
			StateDropdown = <div className="form-field -input">
				<label htmlFor="state">State</label><br/>
				<select id="state" name="state" required onChange={(e) => this.handleInput(e)} value={this.state.state}>
					<option value="" disabled hidden>Select your state</option>
					<option value="NSW" >NSW</option>
					<option value="QLD">QLD</option>
					<option value="ACT">ACT</option>
					<option value="SA">SA</option>
					<option value="TAS">TAS</option>
					<option value="VIC">VIC</option>
					<option value="WA">WA</option>
				</select>
				<p className="helper-text">Enter your state</p>
			</div>;
		} else {
			StateDropdown = <div className="form-field -input">
				<label htmlFor="state">State</label><br/>
				<select id="state" name="state" required onChange={(e) => this.handleInput(e)} value={this.state.state}>
					<option value="" disabled hidden>Select your state</option>
					<option value="AUK">AUK</option>
					<option value="BOP">BOP</option>
					<option value="CAN">CAN</option>
					<option value="GIS">GIS</option>
					<option value="HKB">HKB</option>
					<option value="MBH">MBH</option>
					<option value="MWT">MWT</option>
					<option value="NSN">NSN</option>
					<option value="NTL">NTL</option>
					<option value="OTA">OTA</option>
					<option value="STL">STL</option>
					<option value="TAS">TAS</option>
					<option value="TKI">TKI</option>
					<option value="WGN">WGN</option>
					<option value="WKO">WKO</option>
					<option value="WTC">WTC</option>
				</select>
				<p className="helper-text">Enter your state</p>
			</div>;
		}

		// console.log(date);
		// console.log(attachmentData);
		return (
			<section className="form-container -remove-padding-top">
				<div className="center-container grid-parent">
					<div className="form-wrapper form-left">
						<form onSubmit={e => this.handleSubmit(e)}
							method="POST"
						>
							<h4 className="form-heading">{this.props.content.title ? this.props.content.title : 'Contact Us'}</h4>
							<div className="form-field -input">
								<label htmlFor="firstname">Given name</label>
								<input
									id="firstname"
									type="text"
									minLength="2"
									name="firstname"
									value={firstname}
									required
									onChange={(e) => this.handleInput(e)}
								/>
								<p className="helper-text">Enter your given name</p>
							</div>

							<div className="form-field -input">
								<label htmlFor="lastname">Family name</label>
								<input
									id="lastname"
									type="text"
									minLength="2"
									name="lastname"
									value={lastname}
									required
									onChange={(e) => this.handleInput(e)}
								/>
								<p className="helper-text">Enter your family name</p>
							</div>

							<div className="form-field -input">
								<label htmlFor="phone">Phone number</label>
								<input
									id="phone"
									type="text"
									pattern="\d*"
									minLength="8"
									maxLength="10"
									name="phone"
									value={phone}
									required
									onChange={(e) => this.handleInput(e)}
								/>
								<p className="helper-text">
									Enter your phone number
								</p>
							</div>

							<div className="form-field -input">
								<label htmlFor="email">Email</label>
								<input
									id="email"
									type="email"
									minLength="3"
									name="email"
									value={email}
									required
									onChange={(e) => this.handleInput(e)}
								/>
								<p className="helper-text">Enter your email</p>
							</div>

							<div className="form-field -input">
								<label htmlFor="streetaddress">Street address</label>
								<input
									id="streetaddress"
									type="text"
									minLength="1"
									name="streetaddress"
									value={streetaddress}
									required
									onChange={(e) => this.handleInput(e)}
								/>
								<p className="helper-text">Enter your street address</p>
							</div>

							<div className="form-field -input">
								<label htmlFor="suburb">Suburb / Town</label>
								<input
									id="suburb"
									type="text"
									minLength="1"
									name="suburb"
									value={suburb}
									required
									onChange={(e) => this.handleInput(e)}
								/>
								<p className="helper-text">Enter your suburb</p>
							</div>

							<div>
								{StateDropdown}
							</div>

							{/* <div className="form-field -input">
								<label htmlFor="state">State</label><br/>
									<select id="state" name="state" required onChange={(e) => this.handleInput(e)} value={this.state.state}>
										<option value="" disabled selected hidden>Select your state</option>
										<option value="NSW" >NSW</option>
										<option value="QLD">QLD</option>
										<option value="SA">SA</option>
										<option value="TAS">TAS</option>
										<option value="VIC">VIC</option>
										<option value="WA">WA</option>
									</select>
								<p className="helper-text">Enter your state</p>
							</div>

							<div className="form-field -input">
								<label htmlFor="state">State</label><br/>
									<select id="state" name="state" required onChange={(e) => this.handleInput(e)} value={this.state.state}>
										<option value="" disabled selected hidden>Select your state</option>
										<option value="AUK">AUK</option>
										<option value="BOP">BOP</option>
										<option value="CAN">CAN</option>
										<option value="GIS">GIS</option>
										<option value="HKB">HKB</option>
										<option value="MBH">MBH</option>
										<option value="MWT">MWT</option>
										<option value="NSN">NSN</option>
										<option value="NTL">NTL</option>
										<option value="OTA">OTA</option>
										<option value="STL">STL</option>
										<option value="TAS">TAS</option>
										<option value="TKI">TKI</option>
										<option value="WGN">WGN</option>
										<option value="WKO">WKO</option>
										<option value="WTC">WTC</option>
									</select>
								<p className="helper-text">Enter your state</p>
							</div> */}

							<div className="form-field -input">
								<label htmlFor="postcode">Postcode</label>
								<input
									id="postcode"
									type="text"
									minLength="1"
									name="postcode"
									value={postcode}
									required
									onChange={(e) => this.handleInput(e)}
								/>
								<p className="helper-text">Enter your postcode</p>
							</div>

							{/* <div className="form-field -input">
								<label htmlFor="country">Country</label>
								<input
									id="country"
									type="text"
									minLength="1"
									name="country"
									value={country}
									required
									onChange={(e) => this.handleInput(e)}
								/>
								<p className="helper-text">Enter your country</p>
							</div> */}


							{ (!isEmpty(this.props.content.product_picker)) && 
								<div className="form-field -input">
									<label htmlFor="product">Product</label><br/>
									<select id="product" name="product" required onChange={(e) => this.handleInput(e)} value={this.state.product}>
										<option value="" disabled hidden>Select your purchased product</option>
										{this.props.content.product_picker.map((product, i) => {
											return <option value={product.code} key={i}>{product.title}</option>;
										})}
									</select>
									<p className="helper-text">Select your purchased product</p>
								</div>
							}

							{ (!isEmpty(this.props.content.gift)) && 
								<div className="form-field -input">
									<label htmlFor="gift">Gift</label><br/>
									<select id="gift" name="gift" required onChange={(e) => this.handleInput(e)} value={this.state.gift}>
										<option value="" disabled hidden>Select your gift</option>
										{this.props.content.gift.map((gift, i) => {
											return <option value={gift.title} key={i}>{gift.title}</option>;
										})}
									</select>
									<p className="helper-text">Select your gift</p>
								</div>
							}


							{ (!isEmpty(this.props.content.place_of_purchase)) && 
								<div className="form-field -input">
									<label htmlFor="pop">Place of purchase</label><br/>
									<select id="pop" name="pop" onChange={(e) => this.handleInput(e)} value={this.state.pop}>
										<option value="" disabled hidden>Select your place of purchase</option>
										{this.props.content.place_of_purchase.map((pop, i) => {
											return <option value={pop.title} key={i}>{pop.title}</option>;
										})}
									</select>
									<p className="helper-text">Select your place of purchase</p>
								</div>
							}
							
							{ this.props.content.draw_question && 
								<div className="form-field -input">
									<label htmlFor="drawanswer">Question</label>
	
									<p className="draw-question">{this.props.content.draw_question}</p>
	
									<textarea 
										id="drawanswer"
										name="drawanswer"
										value={drawanswer}
										required
										onChange={(e) => this.handleInput(e)}
										placeholder="Answer"></textarea>
									
									<p className="helper-text">Please enter an answer to the question</p>
								</div>
							}

							<div className="form-field -input">
								<label>Proof of purchase. Upload a scan or photo of your receipt (10MB limit, PDF, PNG, JPG)</label>
								<input
									id="selected-file"
									type="file"
									name="selected-file"
									required
									accept="image/*,.pdf,.doc,.docx"
									onChange={(e) => this.onFileChange(e)}
									className='file-upload'
								/>
								<div className='file-upload-section'>
									<span>{isEmpty(attachmentData) ? 'No file attached' : attachmentData[0].fileName}</span>
									
									<label htmlFor="selected-file" className="form-submit button upload-button">Choose File</label>
								</div>
								
								<p className="helper-text">Enter the proof you purchased the product</p>
							</div>

							<div className="form-field -checkbox">
								<input
									type="checkbox"
									name="agree"
									checked={agree}
									id="agree"
									required
									onChange={(e) => this.handleInput(e)}
								/>
								<label htmlFor="agree">
									By submitting, I agree to the promotion terms & conditions and I have read and accept your{' '}
									<a href="https://privacy.newellbrands.com" target="_blank" rel="noopener noreferrer">Privacy Statement</a>
								</label>
							</div>

							<div className="form-field -checkbox -consent">
								<input
									type="checkbox"
									name="consent"
									checked={consent}
									id="consent"
									onChange={(e) => this.handleInput(e)}
								/>
								<label htmlFor="consent">
								I give permission to be contacted about this promotion
								</label>
							</div>

							<div className="form-field -submit">
								<button
									type="submit"
									className="form-submit button -orange"
									aria-label="Login Button"
									title="Login Button"
									onClick={this.handleSubmit}
									disabled={this.state.submitting}
								>
									{buttonmsg}
								</button>
							</div>
							<div className="error-msg">
								{sbError}
							</div>
							<div className="success-msg">
								{successMsg}
							</div>
						</form>
					</div>
				</div>
			</section>
		);
	}
}
