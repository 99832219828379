import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import hexCode from '../../utils/hexCode';
import Swiper from 'react-id-swiper';
import { imageHelper } from '../../utils/helper';
import Images from '../../image/Images';
import Config from '../../common/config';
const isAU = Config.theme === 'AU';

const swiperParams = {
	slidesPerView: 5,
	freeMode: false,
	spaceBetween: 10,
	direction: 'vertical',
	navigation: {
		nextEl: '.hero-slider-next',
		prevEl: '.hero-slider-prev',
	},
};

const swiperParamsMobile = {
	slidesPerView: 4.5,
	freeMode: false,
	spaceBetween: 10,
};

class ProductHero extends Component {
	constructor(props) {
		super(props);
		this.imageClick = this.imageClick.bind(this);
		this.renderHero = this.renderHero.bind(this);

		this.state = {
			colour: this.props.colour,
			image: false,
			retailers: false,
			previousImage: false,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.colour && prevProps.colour !== this.props.colour) {
			this.setState({image: this.props.colour.key_image});
		}
	}

	renderHero() {
		if (
			this.props.colour &&
			!this.state.image &&
			!this.state.previousImage
		) {
			return (
				<div className="product-hero-image">
					<img
						src={imageHelper(this.props.colour.key_image && this.props.colour.key_image.file, 'large') || Images.fallbackPoster}
						alt={this.props.product.title || 'Sunbeam'}
					/>
				</div>
			);
		} else if (this.state.previousImage && this.state.image) {
			return (
				<div className="product-hero-image">
					<img
						src={imageHelper(this.props.colour.key_image.file, 'large') || Images.fallbackPoster}
						alt={this.props.product.title || 'Sunbeam'}
					/>
				</div>
			);
		} else if (this.state.image) {
			return (
				<div className="product-hero-image">
					<img
						src={imageHelper(this.state.image.file, 'large') || Images.fallbackPoster}
						alt={this.state.image.title || 'Sunbeam'}
					/>
				</div>
			);
		} else if (this.props.product.gallery.length > 0) {
			return (
				<div className="product-hero-image">
					<img
						src={imageHelper(this.props.product.gallery[0].file, 'large') || Images.fallbackPoster}
						alt={this.props.product.gallery[0].title || 'Sunbeam'}
					/>
				</div>
			);
		}
	}
	
	imageClick(e, image, keyImage) {
		e.preventDefault();
		if (keyImage) {
			this.setState({ image, previousImage: keyImage });
		} else {
			this.setState({ image: image, previousImage: false });
		}
	}

	render() {
		const { product, colour, categoryslug, subcategoryslug } = this.props;
		let manyColours;
		if (product.product_colour.length >= 6) {
			manyColours = true;
		}

		return (
			<section className="product-hero">
				<div className="center-container">
					<div className="product-hero-inner grid-parent">
						<div className="product-hero-info left-small-product-hero">
							<h2 className="product-hero-category small-heading">
								<Link to={`/kitchen-and-home/${categoryslug}`}>{product.subcategories[0].category.title}</Link>{'   '}/{'   '}
								<Link to={`/kitchen-and-home/${categoryslug}/${subcategoryslug}`}>{product.subcategories[0].title}</Link>
							</h2>
							<h3 className="product-hero-title large-subheading">
								{this.props.colour && this.props.colour.key_image.title ? this.props.colour.key_image.title : product.title}
							</h3>
							<h4 className="product-hero-model">
								{this.props.colour && this.props.colour.product_code ? this.props.colour.product_code : product.primary_product_code}
							</h4>
							<div className="product-hero-price-details -desktop">
								{isAU && <div>
									{product.price && 
										<p className="product-hero-price">
											{product.price.discontinued_au ? 'Discontinued' : `$${product.price.price_au}`}
										</p>
									}
									<div className="ratings-wrapper">
										<a href='#reviews' 
											data-bv-show="inline_rating"
											data-bv-product-id={product.primary_product_code}
										></a>
									</div>
								</div>
								}
								<a
									className="product-hero-buy button -orange"
									onClick={(e) =>
										this.props.toggleRetailers(e)
									}
									href="#"
								>
									Where to buy
								</a>
							</div>
						</div>
						<div className="product-hero-focus right-medium-product-hero">
							<div className="product-hero-image-container">
								{this.renderHero()}
							</div>
							<div className="product-hero-ui">
								<div className="colour-award-container">
									{product.awards_and_icons && product.awards_and_icons.icons && (
										<div className="product-hero-icon-container -desktop">
											{product.awards_and_icons &&
												product.awards_and_icons.icons &&
												product.awards_and_icons.icons.map(
													(award, i) => {
														return (
															<a
																href={award.link}
																key={i}
																className="product-award-link product-award"
																target="_blank"
																rel="noopener noreferrer"
															>
																<img
																	className="product-award-icon"
																	src={
																		award.image
																			.url
																	}
																	alt={
																		award.title
																	}
																/>
															</a>
														);
													}
												)}
											{product.awards_and_icons &&
												product.awards_and_icons
													.featured_icon &&
												product.awards_and_icons
													.featured_icon.icon.image && product.awards_and_icons.featured_icon.anchor_to_info && (
												<a
													href="#icon-anchor"
													className="product-featured-icon-link product-award"
												>
													<img
														className="product-award-icon"
														src={
															product
																.awards_and_icons
																.featured_icon
																.icon.image.url
														}
														alt={
															product
																.awards_and_icons
																.featured_icon
																.title
														}
													/>
												</a>
											)}
											{product.awards_and_icons &&
												product.awards_and_icons
													.featured_icon &&
												product.awards_and_icons
													.featured_icon.icon.image && !product.awards_and_icons.featured_icon.anchor_to_info && (
												<div
													className="product-featured-icon-link product-award"
												>
													<img
														className="product-award-icon"
														src={
															product
																.awards_and_icons
																.featured_icon
																.icon.image.url
														}
														alt={
															product
																.awards_and_icons
																.featured_icon
																.title
														}
													/>
												</div>
											)}
										</div>
									)}
									{product.product_colour && colour && (
										<div
											className={
												manyColours
													? 'product-colours-container -many'
													: 'product-colours-container'
											}
										>
											{product.product_colour.map(
												(productColour, j) => {
													let activeColour = false;
													if (
														productColour &&
														productColour.colour &&
														productColour.colour
															.param ==
															this.props.colour
																.colour.param
													) {
														activeColour = true;
													}

													return (
														<a
															key={j}
															onClick={(e) =>
																this.props.colourChange(
																	e,
																	productColour
																)
															}
															className={
																activeColour
																	? 'single-product-colour -active'
																	: 'single-product-colour'
															}
														>
															<div
																className="colour-inside"
																style={hexCode(
																	productColour.colour
																)}
															></div>
														</a>
													);
												}
											)}
										</div>
									)}
								</div>
								{product.gallery && colour && (
									<div className="product-hero-gallery -mobile">
										<Swiper {...swiperParamsMobile}>
											{colour.key_image && colour.key_image.file && (
												<a
													onClick={(e) =>
														this.imageClick(
															e,
															false
														)
													}
													href="#"
													className="product-hero-gallery-button"
												>
													<img
														className="product-hero-gallery-image"
														src={
															colour.key_image
																.file.url
														}
														alt={product.title}
													/>
												</a>
											)}
											{product.gallery.map(
												(galleryImage, k) => {
													return (
														<a
															key={k}
															onClick={(e) =>
																this.imageClick(
																	e,
																	galleryImage
																)
															}
															href="#"
															className="product-hero-gallery-button"
														>
															<img
																className="product-hero-gallery-image"
																src={
																	galleryImage
																		.file
																		.url
																}
																alt={
																	galleryImage.title
																}
															/>
														</a>
													);
												}
											)}
										</Swiper>
									</div>
								)}
								{product.gallery && this.props.colour && (
									<div className="product-hero-gallery -desktop">
										<Swiper {...swiperParams}>
											{this.props.colour.key_image && this.props.colour.key_image
												.file && (
												<a
													onClick={(e) =>
														this.imageClick(
															e,
															this.props.colour
																.key_image,
															true
														)
													}
													href="#"
													className="product-hero-gallery-button"
												>
													<img
														className="product-hero-gallery-image"
														src={
															this.props.colour
																.key_image.file
																.url
														}
														alt={product.title}
													/>
												</a>
											)}
											{product.gallery.map(
												(galleryImage, k) => {
													return (
														<a
															key={k}
															onClick={(e) =>
																this.imageClick(
																	e,
																	galleryImage,
																	false
																)
															}
															href="#"
															className="product-hero-gallery-button"
														>
															<img
																className="product-hero-gallery-image"
																src={
																	galleryImage
																		.file
																		.url
																}
																alt={
																	galleryImage.title
																}
															/>
														</a>
													);
												}
											)}
										</Swiper>
									</div>
								)}
								{product.gallery && !colour && !this.props.colour && (
									<div className="product-hero-gallery -desktop">
										<Swiper {...swiperParams}>
											{product.gallery.map(
												(galleryImage, k) => {
													return (
														<a
															key={k}
															onClick={(e) =>
																this.imageClick(
																	e,
																	galleryImage,
																	false
																)
															}
															href="#"
															className="product-hero-gallery-button"
														>
															<img
																className="product-hero-gallery-image"
																src={
																	galleryImage
																		.file
																		.url
																}
																alt={
																	galleryImage.title
																}
															/>
														</a>
													);
												}
											)}
										</Swiper>
									</div>
								)}
								{product.gallery && !colour && !this.props.colour && (
									<div className="product-hero-gallery -mobile">
										<Swiper {...swiperParamsMobile}>
											{product.gallery.map(
												(galleryImage, k) => {
													return (
														<a
															key={k}
															onClick={(e) =>
																this.imageClick(
																	e,
																	galleryImage
																)
															}
															href="#"
															className="product-hero-gallery-button"
														>
															<img
																className="product-hero-gallery-image"
																src={
																	galleryImage
																		.file
																		.url
																}
																alt={
																	galleryImage.title
																}
															/>
														</a>
													);
												}
											)}
										</Swiper>
									</div>
								)}

								<div className="product-hero-price-details -mobile">
									{ isAU && <div><p className="product-hero-price">
												$
										{product.price &&
													product.price.price_au}
									</p>
									<div className="ratings-wrapper">
										<div 
											data-bv-show="inline_rating"
											data-bv-product-id={product.primary_product_code}
										></div>
									</div>
									</div>
									}
									<a
										className="product-hero-buy button -orange"
										onClick={(e) =>
											this.props.toggleRetailers(e)
										}
										href="#"
									>
										Where to buy
									</a>
								</div>
							</div>
							<div className="product-hero-icon-container -mobile">
								{product.awards_and_icons &&
									product.awards_and_icons.icons &&
									product.awards_and_icons.icons.map(
										(award, i) => {
											return (
												<a
													href={award.link}
													key={i}
													className="product-award-link product-award"
													target="_blank"
													rel="noopener noreferrer"
												>
													<img
														className="product-award-icon"
														src={award.image.url}
														alt={award.title}
													/>
												</a>
											);
										}
									)}
								{product.awards_and_icons &&
									product.awards_and_icons.featured_icon &&
									product.awards_and_icons.featured_icon.icon
										.image && product.awards_and_icons.featured_icon.anchor_to_info && (
									<a
										href="#icon-anchor"
										className="product-featured-icon-link product-award"
									>
										<img
											className="product-award-icon"
											src={
												product.awards_and_icons
													.featured_icon.icon
													.image.url
											}
											alt={
												product.awards_and_icons
													.featured_icon.title
											}
										/>
									</a>
								)}
								{product.awards_and_icons &&
									product.awards_and_icons.featured_icon &&
									product.awards_and_icons.featured_icon.icon
										.image && !product.awards_and_icons.featured_icon.anchor_to_info && (
									<div
										className="product-featured-icon-link product-award"
									>
										<img
											className="product-award-icon"
											src={
												product.awards_and_icons
													.featured_icon.icon
													.image.url
											}
											alt={
												product.awards_and_icons
													.featured_icon.title
											}
										/>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

ProductHero.propTypes = {
	product: PropTypes.object,
	colour: PropTypes.object,
	toggleRetailers: PropTypes.func,
	colourChange: PropTypes.func,
	categoryslug: PropTypes.string,
	subcategoryslug: PropTypes.string,
};

export default ProductHero;
