import React, { useState, useRef, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const useDebounce = (value, delay) => {
	// State and setters for debounced value
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(
		() => {
			// Update debounced value after delay
			const handler = setTimeout(() => {
				setDebouncedValue(value);
			}, delay);
			return () => {
				clearTimeout(handler);
			};
		},
		[value, delay] // Only re-call effect if value or delay changes
	);
	return debouncedValue;
};


const SearchBar = ({closeMenu, history, setSearchResult}) => {
	const wrapperRef = useRef(null);
	const [search, setSearch] = useState('');


	const debounceSearch = useDebounce(search, 800);
	// console.log(debounceSearch);
	const { data } = useQuery(HEADER_PRODUCT_QUERY, {
		variables: { search: debounceSearch },
		skip: !debounceSearch
	});

	useEffect(() => {
	    const handleClickOutside = (event) => {
	      if (event.target.name !== 'searchIcon' && event.target.name !== 'search' &&   wrapperRef.current && !wrapperRef.current.contains(event.target)) {
	        setSearch('');
	      }
	    };
	    // Bind the event listener
	    document.addEventListener('mousedown', handleClickOutside);
	    // console.log(data);
	    if(data) {
	    	setSearchResult(true);
	    } else {
	    	setSearchResult(false);
	    }
	    return () => {
	      // Unbind the event listener on clean up
	      document.removeEventListener('mousedown', handleClickOutside);
	    };
	}, [wrapperRef, data]);
	// console.log(data);
	const handleSubmit = (e) => {
		e.preventDefault();
		if(search) {
			history.push({
				pathname: '/search',
				state: {
					keyword: search,
				},
			});
			setSearch('');
		}
	};

	const onClickLink = (product) => {
		const link = `/kitchen-and-home/${product.subcategories[0].category.slug}/${product.subcategories[0].slug}/${product.slug}`;
		setSearch('');
		closeMenu && closeMenu();
		history.push({
			pathname: link,
		});
	};

	return (
		<div className="search-bar">
			<form onSubmit={handleSubmit}>
				<div className="input-field">
					<input
						type="search"
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						id="search"
						// eslint-disable-next-line react/jsx-no-duplicate-props
						type="search"
						name="search"
						className={'search-field'}
						placeholder="Search"
						autoComplete="off"
					/>
					<a name='searchIcon' onClick={(e) => handleSubmit(e)} className="search-icon"></a>
				</div>
				<div ref={wrapperRef}>
					{search && data && data.products && (
						<div className="dropdown-list">
							{data.products.map((p, i) => {
								return (
									<div className="dropdown" key={i}>
										<div
											className="product-preview"
											onClick={() => onClickLink(p)}
										>
											<img
												src={p.card_image.url}
												className="product-image"
											/>
											<div className="info-container">
												<p className="code">
													{p.primary_product_code}{' '}
												</p>
												<p className="title">{p.title} </p>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					)}
				</div>
			</form>
		</div>
	);
};

export default withRouter(SearchBar);

const HEADER_PRODUCT_QUERY = gql`
	query SearchProducts($search: String!) {
		products(limit: 5, where: { search_terms_contains: $search }) {
			id
			title
			slug
			search_terms
			primary_product_code
			categories {
				id
				slug
				title
			}
			social_sharing {
				title
				description
				image {
					url
				}
			}
			card_image {
				url
			}
			subcategories {
				id
				slug
				title
				category {
					id
					slug
					title
				}
			}
		}
	}
`;
