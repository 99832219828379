import React, { Component } from 'react';
import PropTypes from 'prop-types';
import hexCode from '../../utils/hexCode';
import Swiper from 'react-id-swiper';
import Images from '../../image/Images';
import { imageHelper } from '../../utils/helper';

const swiperParams = {
	slidesPerView: 5,
	freeMode: false,
	spaceBetween: 10,
	direction: 'vertical',
	navigation: {
		nextEl: '.hero-slider-next',
		prevEl: '.hero-slider-prev',
	},
};

const swiperParamsMobile = {
	slidesPerView: 4.5,
	freeMode: false,
	spaceBetween: 10,
};

class CollectionHero extends Component {
	constructor(props) {
		super(props);
		this.imageClick = this.imageClick.bind(this);
		this.renderHero = this.renderHero.bind(this);

		this.state = {
			colour: this.props.colour,
			image: false,
			retailers: false,
		};
	}

	renderHero() {
		if (this.props.colour && !this.state.image) {
			return (
				<div className="product-hero-image">
					<img
						src={imageHelper(this.props.colour.key_image, 'large') || Images.fallbackPoster }
						alt={this.props.collection.title || 'Sunbeam'}
					/>
				</div>
			);
		} else if (this.state.image) {
			return (
				<div className="product-hero-image">
					<img
						src={imageHelper(this.state.image.file, 'large') || Images.fallbackPoster}
						alt={this.state.image.title || 'Sunbeam'}
					/>
				</div>
			);
		} else if (this.props.collection.card_image) {
			return (
				<div className="product-hero-image">
					<img
						src={imageHelper(this.props.collection.card_image, 'large') || Images.fallbackPoster}
						alt={this.props.collection.card_image.alternativeText || 'Sunbeam'}
					/>
				</div>
			);
		}
	}
	//
	imageClick(e, image) {
		e.preventDefault();
		this.setState({ image: image });
	}

	render() {
		// const product = this.props.product;
		const { collection, colour } = this.props;
		let manyColours;
		if (collection.colours.length >= 6) {
			manyColours = true;
		}
		return (
			<section className="product-hero">
				<div className="center-container">
					<div className="product-hero-inner grid-parent">
						<div className="product-hero-info left-small">
							<h2 className="product-hero-category small-heading">
								COLLECTION
							</h2>
							<h3 className="product-hero-title large-subheading">
								{collection.title}
							</h3>
						</div>
						<div className="product-hero-focus right-medium">
							<div className="product-hero-image-container">
								{this.renderHero()}
							</div>
							<div className="product-hero-ui">
								<div className="colour-award-container">
									
									{collection.awards_icon &&
									collection.awards_icon.image && (
										<div className="product-hero-icon-container -desktop">
											<a
												href={
													collection.awards_icon
														.link
												}
												className="product-award-link"
												target="_blank"
												rel="noopener noreferrer"
											>
												<img
													className="product-award-icon"
													src={
														collection
															.awards_icon
															.image.url
													}
													alt={
														collection
															.awards_icon
															.title
													}
												/>
											</a>
										</div>
									)}
									{collection.colours && (
										<div
											className={
												manyColours
													? 'product-colours-container -many'
													: 'product-colours-container'
											}
										>
											{collection.colours.map(
												(productColour, j) => {
													let activeColour = false;
													if (
														this.props.colour &&
														productColour &&
														productColour.colour &&
														productColour.colour
															.param ==
															this.props.colour
																.colour.param
													) {
														activeColour = true;
													}

													return (
														<a
															key={j}
															onClick={(e) =>
																this.props.colourChange(
																	e,
																	productColour
																)
															}
															className={
																activeColour
																	? 'single-product-colour -active'
																	: 'single-product-colour'
															}
														>
															<div
																className="colour-inside"
																style={hexCode(
																	productColour.colour
																)}
															></div>
														</a>
													);
												}
											)}
										</div>
									)}
								</div>
								{colour && colour.key_image.file && (
									<div className="product-hero-gallery -mobile">
										<Swiper {...swiperParamsMobile}>
											{colour.key_image.file && (
												<a
													onClick={(e) =>
														this.imageClick(
															e,
															false
														)
													}
													href="#"
													className="product-hero-gallery-button"
												>
													<img
														className="product-hero-gallery-image"
														src={
															imageHelper(colour.key_image, 'large') || Images.fallbackPoster
														}
														alt={product.title}
													/>
												</a>
											)}
										</Swiper>
									</div>
								)}
								{colour && colour.key_image.file && (
									<div className="product-hero-gallery -desktop">
										<Swiper {...swiperParams}>
											{colour.key_image.file && (
												<a
													onClick={(e) =>
														this.imageClick(
															e,
															this.props.colour
																.key_image
														)
													}
													href="#"
													className="product-hero-gallery-button"
												>
													<img
														className="product-hero-gallery-image"
														src={
															imageHelper(this.props.colour
																.key_image.file, 'large') || Images.fallbackPoster
														}
														alt={collection.title}
													/>
												</a>
											)}
										</Swiper>
									</div>
								)}
							</div>
							<div className="product-hero-icon-container -mobile">
								{collection.awards_icon &&
									collection.awards_icon.image && (
									<a
										href={collection.awards_icon.link}
										className="product-award-link"
										target="_blank"
										rel="noopener noreferrer"
									>
										<img
											className="product-award-icon"
											src={
												collection.awards_icon.image
													.url
											}
											alt={
												collection.awards_icon.title
											}
										/>
									</a>
								)}
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

CollectionHero.propTypes = {
	product: PropTypes.object,
	colour: PropTypes.object,
	collection: PropTypes.object,
	colourChange: PropTypes.func
};

export default CollectionHero;
