import React from 'react';
import PropTypes from 'prop-types';

const Inclusions = ({inclusions}) => {
	return (
		<div className="inclusions-inner">
			{inclusions.image_list_items.map((item, i) => {
				return (
					<div key={i} className="single-inclusion">
						{item.image && inclusions.show_images && (
							<img
								className="single-inclusion-image"
								src={item.image.url}
								alt={item.image.alt}
							/>
						)}
						<p className="inclusion-details">{item.text}</p>
					</div>
				);
			})}
		</div>
	);
};

Inclusions.propTypes = {
	inclusions: PropTypes.object,
};

export default Inclusions;
