import { ApolloClient } from 'apollo-client';
import {
	InMemoryCache,
	IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import introspectionQueryResultData from '../fragmentTypes.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
	introspectionQueryResultData,
});

const cache = new InMemoryCache({ fragmentMatcher });
const link = new HttpLink({
	uri: process.env.REACT_APP_BACKEND_URL,
});
const client = new ApolloClient({
	cache,
	link,
});

export default client;
