import React, { Component } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Images from '../../image/Images';

const areas = [
	'Australian_Capital_Territory',
	'New_South_Wales',
	'New_Zealand',
	'Northern_Territory',
	'Queensland',
	'Victoria',
	'Western_Australia',
	'Tasmania',
	'South_Australia',
];

class AccordionToggle extends Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.state = {
			toggleClass: false,
		};
	}

	handleClick(e) {
		e.preventDefault();
		this.setState((prev) => ({
			toggleClass: !prev.toggleClass,
		}));
	}

	render() {
		const serviceAgents = this.props.serviceAgents;
		const area = this.props.area;
		let agentContent = [];
		const displayTitle = area.replace(/_/g, ' ');
		serviceAgents.map((agent, i) => {
			if (agent.state_region == area) {
				agentContent.push(
					<div key={i} className="single-service-agent">
						<div className="agent-line -top">
							<h4 className="single-service-agent-title">
								{agent.agent_name}
							</h4>
						</div>
						{agent.service_type && (
							<div className="agent-line -service">
								<img
									src={Images.repairIcon}
									alt=""
									className="single-service-agent-icon"
								/>
								<div className="agent-line-text">
									<p className="single-service-agent-details">
										{agent.service_type}
									</p>
								</div>
							</div>
						)}
						<div className="agent-line -address">
							<img
								src={Images.addressIcon}
								alt=""
								className="single-service-agent-icon"
							/>
							<div className="agent-line-text">
								{agent.address_line_one && (
									<p className="single-service-agent-details">
										{agent.address_line_one}
									</p>
								)}
								{agent.address_line_two && (
									<p className="single-service-agent-details">
										{agent.address_line_two}
									</p>
								)}
								{agent.address_map_url && (
									<a
										href={agent.address_map_url}
										className="single-service-agent-link"
									>
										View on maps
									</a>
								)}
							</div>
						</div>
						{agent.phone_number && (
							<div className="agent-line -contact">
								<img
									src={Images.callIcon}
									alt=""
									className="single-service-agent-icon"
								/>
								<div className="agent-line-text">
									<p className="single-service-agent-details">
										{agent.phone_number}
									</p>
									<a
										href={'tel: ' + agent.phone_number}
										className="single-service-agent-link"
									>
										Call now
									</a>
								</div>
							</div>
						)}
					</div>
				);
			}
		});
		if (agentContent[0]) {
			return (
				<div className="single-accordion">
					<a
						className={
							this.state.toggleClass
								? 'accordion-title -active'
								: 'accordion-title'
						}
						onClick={(e) => this.handleClick(e)}
						href="#"
					>
						{displayTitle} ({agentContent.length})
					</a>
					{this.state.toggleClass && agentContent && (
						<div className="agents-container">{agentContent}</div>
					)}
				</div>
			);
		} else {
			return false;
		}
	}
}

const ServiceAgent = () => {
	const { loading, error, data } = useQuery(SERVICE_QUERY);
	if (loading) return null;
	if (error) return <p>error</p>;
	const serviceAgents = data.serviceAgents;
	return (
		<div className="service-agent">
			{areas.map((area, i) => {
				return (
					<AccordionToggle
						area={area}
						serviceAgents={serviceAgents}
						key={i}
					/>
				);
			})}
		</div>
	);
};

const SERVICE_QUERY = gql`
	query SeviceAgents {
		serviceAgents {
			address_line_one
			address_line_two
			address_map_url
			agent_name
			phone_number
			service_type
			state_region
		}
	}
`;

export default ServiceAgent;
