const Images = {
	blogo: require('./icons/sunbeam-logo-black.svg'),
	search: require('./icons/search.svg'),
	burger: require('./icons/burger.svg'),
	favicon: require('./icons/favicon.ico'),
	productEample: require('./examples/EM5300-FOPD_edited_no_cups_edited_HB_v2_No_Grinder_No_Cups 1.svg'),
	arrowRight: require('./icons/enter-orange.svg'),
	lineArrowRight: require('./icons/arrowright.svg'),
	lineArrowLeft: require('./icons/arrowleft.svg'),
	infoIcon: require('./icons/infoicon.svg'),
	ex1: require('./examples/ex1.svg'),
	starsExample: require('./examples/stars.png'),
	espresso: require('./examples/espresso.png'),
	fallbackCardImage: require('./fallback/sunbeam-card-image.jpg'),
	fallbackHeroBg: require('./fallback/sunbeam-hero-bg.jpg'),
	fallbackHeroFg: require('./fallback/sunbeam-hero-fg.png'),
	fallbackPoster: require('./fallback/sunbeam-poster.jpg'),
	fallbackHomeHeroBg: require('./fallback/sunbeam-home-hero-bg.jpg'),
	fallbackKitchen: require('./fallback/sunbeam-kitchen.jpg'),
	flag: require('./icons/flag.svg'),
	repairIcon: require('./icons/repair-icon.svg'),
	triangleUp: require('./icons/triangle-up-white.svg'),
	addressIcon: require('./icons/address-icon.svg'),
	callIcon: require('./icons/call-icon.svg'),
	reset: require('./icons/reset.svg'),
	filterAppliance: require('./icons/filter-appliance.svg'),
	filterMealtype: require('./icons/filter-meal-type.svg'),
	resetWhite: require('./icons/resetwhite.svg'),
	closeWhite: require('./icons/closewhite.svg'),
	colourIcon: require('./icons/colour-icon.svg'),
	priceIcon: require('./icons/price-icon.svg'),
	featurIcon: require('./icons/feature-icon.svg'),
	featurIconOld: require('./icons/feature-icon-old.svg'),
	facebookgrey: require('./share/facebookgrey.svg'),
	insgrey: require('./share/insgrey.svg'),
	emailgrey: require('./share/emailgrey.svg'),
	share: require('./share/share.svg'),
	twittergrey: require('./share/twittergrey.svg'),
	loader: require('./icons/loader.gif'),
};

export default Images;
