import React from 'react';
import PropTypes from 'prop-types';

const ImageBreak = ({ content }) => {
	// console.log(content);
	if (!content) return null;
	const { desktop, mobile } = content;
	if (!desktop) return null;
	return (
		<div className="image-break-container">
			<img
				className="image-break-element -desktop"
				src={desktop.formats.medium.url}
				alt={desktop.alternativeText}
			/>
			<img
				className="image-break-element -mobile"
				src={desktop.formats.small.url}
				alt={desktop.alternativeText}
			/>
		</div>
	);
};

ImageBreak.propTypes = {
	content: PropTypes.object,
};

export default ImageBreak;
