import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Link, withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import Images from '../image/Images';
import HelpModule from '../container/partials/HelpModule';
import CategoryHero from '../container/partials/CategoryHero';
import WarrantyTable from '../container/partials/WarrantyTable';
import ContentBlock from '../component/ContentBlock';
import SeoComponent from '../component/SeoComponent';
import Loader from '../component/Loader';

const Warranty = () => {
	const { loading, error, data } = useQuery(WARRANTYPAGE, {
		fetchPolicy: 'no-cache'
	});

	if (loading) return <Loader />;
	if (error)  return  null;

	const { warrantyPage, supportLandingPage } = data;
	const {
		block_one_heading,
		block_three_heading,
		block_three_copy,
		block_one_copy,
		block_one_button_slug,
		block_one_button_text,
		block_two_heading,
		block_two_visible_text,
		block_two_read_more,
		social_sharing
	} = warrantyPage;
	const content1 = {
		title: block_one_heading,
		copy: block_one_copy,
		button_text: block_one_button_text,
		button_link: block_one_button_slug,
	};

	const content2 = {
		title: block_two_heading,
		copy: block_two_visible_text,
		read_more: block_two_read_more,
	};

	const content3 = {
		title: block_three_heading,
		copy: block_three_copy,
	};

	return (
		<div className="warranty-page">
			{social_sharing && <SeoComponent social={social_sharing} />}
			<CategoryHero content={warrantyPage} />
			<ContentBlock content={content1} />
			<ContentBlock content={content2} anchorID="guarentee" />
			<ContentBlock content={content3} anchorID="table" />
			<section className="warranty-container -remove-padding-top">
				<div className="center-container grid-parent">
					<div className="grid-child-8">
						<WarrantyTable />
					</div>
				</div>
			</section>
			<HelpModule content={supportLandingPage.services} color="white" />
			<HelpModule content={supportLandingPage.product_support} color="grey" />
			<HelpModule content={supportLandingPage.general_support} color="white" />
			<HelpModule content={supportLandingPage.contact} color="grey" />
		</div>
	);
};

export default Warranty;
const WARRANTYPAGE = gql`
	query WarrantyPageQuery {
		supportLandingPage {
			services {
				id
				heading
				links {
					id
					link_text
					link_slug
				}
			}

			contact {
				id
				heading
				links {
					id
					link_text
					link_slug
				}
			}

			general_support {
				id
				heading
				links {
					id
					link_text
					link_slug
				}
			}

			product_support {
				id
				heading
				links {
					id
					link_text
					link_slug
				}
			}
		}
		
		warrantyPage {
			id
			title
			block_one_heading
			block_one_copy
			block_one_button_text
			block_one_button_slug
			block_two_heading
			block_two_visible_text
			block_two_read_more
			block_three_heading
			block_three_copy

			social_sharing {
				id
				title
				description
				image {
					id
					url
					alternativeText
				}
			}
			hero_with_foreground {
				foreground {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
				background {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
			}
		}
	}
`;
