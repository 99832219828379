import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AccordionToggle extends Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.state = {
			toggleClass: false,
		};
	}

	handleClick(e) {
		e.preventDefault();
		this.setState((prev) => ({
			toggleClass: !prev.toggleClass,
		}));
	}

	render() {
		const heading = this.props.heading;
		const content = this.props.content;
		return (
			<>
				<a
					className={
						this.state.toggleClass
							? 'mobile-accordion-header'
							: 'mobile-accordion-header -hide'
					}
					onClick={(e) => this.handleClick(e)}
					href="#"
				>
					{heading}
				</a>
				<div
					className={
						this.state.toggleClass
							? 'mobile-accordion'
							: 'mobile-accordion -hide'
					}
				>
					{content}
				</div>
			</>
		);
	}
}

AccordionToggle.propTypes = {
	heading: PropTypes.object,
	content: PropTypes.object,
};

const MobileAccordion = (data) => {
	const heading = data.heading;
	const content = data.content;
	return <AccordionToggle heading={heading} content={content} />;
};

export default MobileAccordion;
