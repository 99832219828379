import React from 'react';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import Facebook from '../image/icons/facebook-red.svg';
import Instagram from '../image/icons/instagram-red.svg';
import Youtube from '../image/icons/youtube.svg';
import MobileAccordion from '../component/MobileAccordion';
import Config from '../common/config';

const isAU = Config.theme === 'AU';
const categoryOne = <h4 className="footer-link-category">Kitchen & Home</h4>;

const categoryTwo = <h4 className="footer-link-category">Resources</h4>;
const contentTwo = (
	<ul className="footer-link-list">
		<li className="footer-link">
			<Link to={'/recipes'}>Recipes</Link>
		</li>
		<li className="footer-link">
			<Link to={'/hints-and-tips'}>Hints & Tips</Link>
		</li>
		<li className="footer-link">
			<Link to={'/promotions'}>Offers & Promotions</Link>
		</li>
	</ul>
);
const categoryThree = <h4 className="footer-link-category">Support</h4>;
const contentThree = (
	<ul className="footer-link-list">
		<li className="footer-link">
			<Link to={'/support/product-registration'}>
				Register your Product
			</Link>
		</li>
		<li className="footer-link">
			<Link to={'/support/warranty'}>Warranty Information</Link>
		</li>
		<li className="footer-link">
			<Link to={'/support/faq'}>FAQs</Link>
		</li>
		<li className="footer-link">
			<Link to={'/support/services'}>Service Agent Locator</Link>
		</li>
		<li className="footer-link">
			<Link to={'/support/where-to-buy'}>Where to buy</Link>
		</li>
		<li className="footer-link">
			<Link to={'/support/contact'}>Contact Us</Link>
		</li>
	</ul>
);

const Footer = () => {
	const { loading, error, data } = useQuery(CONTACT_QUERY);
	if (loading) return null;
	if (error) return <p>error</p>;
	// console.log(data);
	const { categories, contactDetail } = data;

	const contentOne = (
		<ul className="footer-link-list">
			{categories &&
				categories.map((ca, i) => {
					return (
						<li key={i} className="footer-link">
							<Link to={`/kitchen-and-home/${ca.slug}`}>
								{ca.title}
							</Link>
						</li>
					);
				})}

			<li className="footer-link">
				<Link to={'/collections'}>Collections</Link>
			</li>
		</ul>
	);

	return (
		<footer className="footer">
			{/* <SubscribeModule /> */}
			<div className="footer-top">
				<div className="centred-flex">
					<div className="footer-left">
						<div className="footer-links-container">
							<div className="footer-link-container">
								<MobileAccordion
									heading={categoryOne}
									content={contentOne}
								/>
							</div>
							<div className="footer-link-container">
								<MobileAccordion
									heading={categoryTwo}
									content={contentTwo}
								/>
							</div>
							<div className="footer-link-container">
								<MobileAccordion
									heading={categoryThree}
									content={contentThree}
								/>
							</div>
						</div>
					</div>
					<div className="footer-right">
						{contactDetail && (
							<div className="footer-contact contact">
								<h3 className="contact contact-heading">
									Get in touch if you have any questions
								</h3>
								{contactDetail.phone && (
									<a
										className="contact-number"
										href="tel:1300881861"
									>
										{contactDetail.phone}
									</a>
								)}
								{contactDetail.hours_of_operation &&
									contactDetail.hours_of_operation.map(
										(item, i) => {
											return (
												<p
													key={i}
													className="contact-hours"
												>
													{item.hours}
												</p>
											);
										}
									)}
								<div className="footer-social-container">
									<h4 className="footer-social-heading">
										Follow us
									</h4>
									{contactDetail.facebook_url && (
										<a
											className="footer-social-link"
											href={contactDetail.facebook_url}
										>
											<img src={Facebook} />
										</a>
									)}
									{contactDetail.instagram_url && (
										<a
											className="footer-social-link"
											href={contactDetail.instagram_url}
										>
											<img src={Instagram} />
										</a>
									)}
									{contactDetail.youtube_url && (
										<a
											className="footer-social-link"
											href={contactDetail.youtube_url}
										>
											<img src={Youtube} />
										</a>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="footer-bottom">
				<div className="centred-flex">
					<div className="footer-copyright-container">
						<p className="footer-copyright">
							&copy; {contactDetail.copyright || 'Sunbeam 2020'}
						</p>
					</div>
					<div className="footer-legal">
						<a className="footer-legal-link" href={isAU ? 'https://www.newellbrands.com/terms-of-use-en-au' : 'https://www.newellbrands.com/terms-of-use-en-nz'}>
							Terms of use
						</a>
						<a className="footer-legal-link" href="https://privacy.newellbrands.com/index_en.html" target="_blank" rel="noopener noreferrer">
							Privacy statement
						</a>
						<a className="footer-legal-link" href="https://privacy.newellbrands.com/cookie_en.html" target="_blank" rel="noopener noreferrer">
							Cookie policy
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
};

const CONTACT_QUERY = gql`
	query ContactDetail {
		contactDetail {
			id
			facebook_url
			instagram_url
			youtube_url
			phone
			twitter_url
			copyright
			hours_of_operation {
				id
				hours
			}
		}
		categories {
			id
			slug
			title
		}
	}
`;

export default Footer;
