import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import ReactMarkdown from 'react-markdown';
import {isEmpty} from 'lodash';
import HelpModule from '../container/partials/HelpModule';
import ManualBlock from '../component/ManualBlock';
import CategoryHero from '../container/partials/CategoryHero';
import SeoComponent from '../component/SeoComponent';
import Breaks from 'remark-breaks';
import {MANUAL_SEARCH_QUERY, PRODUCTMANUAL, PRODUCTMANUALPAGE} from '../queries/manual';
import Loader from '../component/Loader';

const ProductManualsPage = () => {
	const [search, setSearch] = useState('');
	const [keyword, setKeyword] = useState('');
	const { loading, error, data } = useQuery(PRODUCTMANUALPAGE, {
		fetchPolicy: 'no-cache'
	});
	if (loading) return <Loader />;
	if (error) return <p>error</p>;

	const { supportLandingPage, productManualsPage, manualsAndGuides } = data;
	const { warranty, services, general_support, contact } = supportLandingPage;

	const handleSubmit = (e) => {
		e.preventDefault();
		if(!search) return;
		setKeyword(search);
	};

	const onChange = (e) => {
		setSearch(e.target.value);
		if(isEmpty(e.target.value)){
			setKeyword('');
		}
	};
	// console.log(keyword);
	return (
		<div className="manual-page">
			{productManualsPage.social_sharing && <SeoComponent social={productManualsPage.social_sharing} />}
			<CategoryHero content={productManualsPage} />
			<section className="text-block -remove-padding-top">
				<div className="center-container grid-parent">
					<div className="text-wrapper grid-child-6-3 markdown-block">
						<ReactMarkdown children={productManualsPage.copy} plugins={[Breaks]} />
					</div>
				</div>
			</section>
			<div className="search-module-inner">
				<div className="center-container grid-parent">
					<div className="form-container grid-child-8">
						<form onSubmit={handleSubmit} method="post">
							<div className="form-field -input -searchform">
								<input
									type="text"
									value={search}
									onChange={(e) => onChange(e)}
									id="search"
									name="search"
									className="white-bg -search"
									placeholder="Search product name or code"
									autoComplete="off"
								/>
								<div onClick={handleSubmit} className="search-icon"></div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div style={{ minHeight: 500 }}>
				<ProductManualsContainer keyword={keyword} />
			</div>
			<HelpModule content={warranty} color="white" />
			<HelpModule content={services} color="grey" />
			<HelpModule content={general_support} color="white" />
			<HelpModule content={contact} color="grey" />
		</div>
	);
};

export default ProductManualsPage;

const ProductManualsContainer = ({keyword}) => {
	let query;
	let option;

	if(keyword) {
		query = MANUAL_SEARCH_QUERY;
		option = {
			variables: { keyword },
			skip: !keyword,
			fetchPolicy: 'cache-and-network'
		};
	} else {
		query = PRODUCTMANUAL;
		option = {
			variables: { start: 0, limit: 10 },
			fetchPolicy: 'cache-and-network',
		};
	}
	const { data, loading, error, fetchMore } = useQuery(query, option);
	if (loading) return null;
	if (error) return <p>error</p>;
	const { manualsAndGuides } = data;

	const onLoadMore = () => {
		fetchMore({
			variables: {
				start: data.manualsAndGuides.length,
			},
			updateQuery: (prev, { fetchMoreResult }) => {
				if (!fetchMoreResult) return prev;
				return Object.assign({}, prev, {
					manualsAndGuides: [
						...prev.manualsAndGuides,
						...fetchMoreResult.manualsAndGuides,
					],
				});
			},
		});
	};

	const filteredManuals = manualsAndGuides.filter((manual) => manual.products.length > 0);

	return (
		<div className="manual-container bg-grey">
			<div className="center-container grid-parent">
				<div className="grid-child-8 result-block">
					{
						keyword ?
						 <p className="small-heading">
							results: {manualsAndGuides.length}
							</p> : 
							<p className="small-heading">
							All Manuals
							</p>
					}					
				</div>
				<div id="service" className="service service-containter grid-child-8">
					{filteredManuals.map((manual, i) => {
						return <ManualBlock manual={manual} key={i} />;
					})}
					{!keyword && <div className="flex-row-center">
						<button className="button -orange" onClick={onLoadMore}>
								Load more
						</button>
					</div>
					}
				</div>
			</div>
		</div>
	);
};
