import gql from 'graphql-tag';

export const RECIPE_SEARCH_QUERY = gql`
	query SearchRecipe($keyword: String!) {
		tags (sort: "tag:asc") { 
			id
			tag
			recipes {
				id
			}
		}
		recipes(where: { title_contains: $keyword }) {
			id
			title
			tagline
			slug
			status
			preparation_time
			tags (sort: "tag:asc") {
				id
				tag
			}
			meal {
				id
				meal_title
			}
			card_image {
                id
                name
                url
                alternativeText
                caption
                width
                height
                formats
                previewUrl
            }
		}
	}
`;

export const PRODUCT_SEARCH_QUERY = gql`
	query SearchProducts($keyword: String!) {
		recipes(where: { title_contains: $keyword }) {
			id
			title
			tagline
			slug
			status
			preparation_time
			tags (sort: "tag:asc") {
				id
				tag
			}
			meal {
				id
				meal_title
			}
			card_image {
                id
                name
                url
                alternativeText
                caption
                width
                height
                formats
                previewUrl
            }
		}
		products(where: { search_terms_contains: $keyword }) {
			id
			title
			slug
			search_terms
			primary_product_code
			price {
				id
				price_au
				discontinued_au
			}
			categories {
        		id
        		title
        		slug
        	}
        	subcategories {
				id
				slug
				title
				category {
					id
					slug
					title
				}
			}
			manuals {
				id
				title
				type
				file {
					id
					url
				}
			}
			card_image {
				id
				url
			}
		}
	}
`;

export const SEARCHPAGE = gql `
	query SearchProductsPage($keyword: String!) {
		products(where: { search_terms_contains: $keyword, status: "published" }) {
			id
			title
			slug
			search_terms
			primary_product_code
			price {
				id
				price_au
				discontinued_au
			}
			categories {
        		id
        		title
        		slug
        	}
        	subcategories {
				id
				slug
				title
				category {
					id
					slug
					title
				}
			}
			manuals {
				id
				title
				type
				file {
					id
					url
				}
			}
			card_image {
				id
				url
			}
		}
		recipes(where: { title_contains: $keyword, status: "published" }) {
			id
			title
			tagline
			slug
			status
			preparation_time
			tags (sort: "tag:asc") {
				id
				tag
			}
			meal {
				id
				meal_title
			}
			card_image {
                id
                name
                url
                alternativeText
                caption
                width
                height
                formats
                previewUrl
            }
		}
		hintsAndTips(where: { title_contains: $keyword, status: "published"})  {
			id
			title
			tagline
			slug
			status
			created_at
			card_image {
				id
				name
				url
				alternativeText
				caption
				width
				height
				formats
				previewUrl
			}
			
		}
		promotions (where: { title_contains: $keyword, status: "published" }) {
			id
			title
			tagline
			slug
			status
			date_end
			date_start
			created_at
			card_image {
				id
				name
				url
				alternativeText
				caption
				width
				height
				formats
				previewUrl
			}
		}
		searchKeywords (where: { keywords_contains: $keyword }) {
			id
			title
			description
			slug
			external_url
			keywords
		}
		searchResultsPage {
			id
			title
			social_sharing {
		        id
		        title
		        description
		        image {
		            id
		            url
		            alternativeText
		        }
		    }
			hero_with_foreground {
				foreground {
					id
		            name
		            hash
		            url
		            alternativeText
		            caption
		            width
		            height
		            previewUrl
		            formats
				}
				background {
					id
		            name
		            hash
		            url
		            alternativeText
		            caption
		            width
		            height
		            previewUrl
		            formats
				}
			}
		}
	}
`;



export default {SEARCHPAGE, PRODUCT_SEARCH_QUERY, RECIPE_SEARCH_QUERY};