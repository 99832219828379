import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import { Link } from 'react-router-dom';
import {isEmpty} from 'lodash';
import { imageHelper } from '../../utils/helper';
import Config from '../../common/config';
const isAU = Config.theme === 'AU';


export default class ProductList extends Component {
	constructor(props) {
		super(props);
	}

	renderColourImage = (data, selectedColour) => {
		const selected = data.product_colour.find((colour) => {
			return colour.colour.title === selectedColour;
		});
		// console.log(selected);
		// console.log(selected[0].key_image);
		return (
			selected && selected.key_image && (
				<img
					className="product-item-image"
					src={imageHelper(selected.key_image.file, 'small')}
					alt={data.alternativeText}
				/>
			)
		);
	};

	renderRegularImage = (data) => {
		return (
			data.card_image && (
				<img
					className="product-item-image"
					src={imageHelper(data.card_image, 'small')}
					alt={data.alternativeText}
				/>
			)
		);
	};

	renderPrimaryProductCode = (primary_product_code) => {
		if(primary_product_code) {
			return (
				<p className="product-item-code">
					{primary_product_code}
				</p>
			);
		}
	}

	renderColorProductCode = (data, selectedColour) => {
		const selected = data.product_colour.find((colour) => {
			return colour.colour.title === selectedColour;
		});
		// console.log('item:');
		// console.log(data);
		// console.log(selected);
		return (
			selected && (
				<p className="product-item-code">
					{selected.product_code}
				</p>
			)
		);
	};

	render() {
		// console.log(this.props.items);
		const { items, selectedColour, type } = this.props;
		// console.log(type);
		const swiperParams = {
			slidesPerView: 2,
			freeMode: true,
			breakpoints: {
				320: {
					slidesPerGroup: 1.5,
				},
				640: {
					slidesPerGroup: 2.5,
				},
			},
		};

		const filterItems = items.filter((item) => {
			return item && !isEmpty(item.subcategories);
		});

		const itemRender = filterItems.map((item, i) => {
			const url = `/kitchen-and-home/${item.subcategories[0].category.slug}/${item.subcategories[0].slug}/${item.slug}`;
			return (
				<Link to={url} key={i} className="product-item">
					{item.awards_and_icons && item.awards_and_icons.featured_icon && item.awards_and_icons.featured_icon.icon && item.awards_and_icons.featured_icon.icon.image && item.awards_and_icons.featured_icon.icon.image.url && (
						<img
							className="product-item-featured"
							src={item.awards_and_icons.featured_icon.icon.image.url}
							alt={''}
						/>
					)}
					{
						selectedColour ? this.renderColorProductCode(item, selectedColour) : this.renderPrimaryProductCode(item.primary_product_code)
					}
					{
						selectedColour ? this.renderColourImage(item, selectedColour) : this.renderRegularImage(item)
					}
					{item.title && (
						<h4 className="product-item-title">{item.title}</h4>
					)}
	
					{isAU && 
						<div className="product-item-info">
							<div className="ratings-wrapper">
								<div 
									data-bv-show="inline_rating"
									data-bv-product-id={item.primary_product_code}
								></div>
							</div>
							{item.price && (
								<p className="product-item-price">
									{item.price.discontinued_au ? 'Discontinued' : `$${item.price.price_au}`}
								</p>
							)}
						</div>
					}
				</Link>
			);
		});
		return (
			<div className="product-list-container">
				{
					type === 'product-index' ? 
						<div className="product-list-wrapper list-index">
							<div className="product-list-inner -desktop">{itemRender}</div>
						</div>
						:
						<div className="product-list-wrapper list-module">
							<div className="product-list-inner -desktop">{itemRender}</div>
							<div className="product-list-inner -mobile">
								<Swiper {...swiperParams}>{itemRender}</Swiper>
							</div>
						</div>
				}
			</div>
		);
	}
}

ProductList.propTypes = {
	items: PropTypes.array,
};
