import React from 'react';
import Images from '../../image/Images';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const CollectionRange = ({CollectionRange}) => {
	// console.log(CollectionRange);
	const {collection} = CollectionRange;
	return (
		<section className="collection-range">
			<div className="center-container">
				<div className="collections-wrapper grid-parent">
					<div className="collection-range-headings-container narrow-left">
						<h2 className="collection-range-title small-heading">
							Collections Range
						</h2>
						<h3 className="collection-range-subtitle large-subheading">
							{collection.title}
						</h3>
						<p className="collection-range-description">
							{collection.tagline}
						</p>
						<Link
							to={`/collections/${collection.slug}`}
							className="collection-range-button orange-arrow-link"
						>
							<img src={Images.arrowRight} alt="View Collection" />
						</Link>
						<a
							className="collection-range-cta -desktop"
							href="/collections"
						>
							View all collections
						</a>
					</div>
					<div className="product-range-image-container wide-right sixteen-nine">
						<div className="aspect-content">
							{collection.card_image_large && (
								<Link
									to={`/collections/${collection.slug}`}
								>
									<img
										className="product-range-image"
										src={collection.card_image_large.formats.large.url}
										alt={collection.title}
									/>
								</Link>
							)}
						</div>
					</div>
					<a className="collection-range-cta narrow-left -mobile" href="/collections">
						View all collections
					</a>
				</div>
			</div>
		</section>
	);
};

CollectionRange.propTypes = {
	CollectionRange: PropTypes.object,
};

export default CollectionRange;
