import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { useQuery } from '@apollo/react-hooks';
// import { useParams } from 'react-router';
import colourParam from '../utils/colour';
// import ProductHero from '../container/product/ProductHero';
// import ProductIntro from '../container/product/ProductIntro';
import Accordion from '../component/Accordion';
// import Specifications from '../container/product/Specifications';
// import ProductNav from '../container/product/ProductNav';
// import Inclusions from '../container/product/Inclusions';
import StickyImage from '../container/product/StickyImage';
// import ManualDownload from '../container/partials/ManualDownload';
import LifestyleSlider from '../container/partials/LifestyleSlider';
// import RelatedArticles from '../container/partials/RelatedArticles';
import FeaturedProducts from '../container/partials/FeaturedProducts';
// import Warranty from '../container/product/Warranty';
import MobileAccordion from '../component/MobileAccordion';
// import ProductRetailers from '../container/product/ProductRetailers';
import CollectionHero from '../container/collection/CollectionHero';
import CollectionFullHero from '../container/partials/CollectionFullHero';
import VideoIntro from '../component/VideoIntro';
import { isEmpty } from 'lodash';
import { imageHelper } from '../utils/helper';
import Images from '../image/Images';

class CollectionContainer extends Component {
	constructor(props) {
		super(props);
		this.colourChange = this.colourChange.bind(this);
		this.state = {
			colour: null,
			retailers: false,
		};
	}

	componentDidMount() {
		const colour = colourParam(this.props.collection);
		this.setState({
			colour,
		});
	}

	colourChange(e, colour) {
		e.preventDefault();
		if (history.pushState) {
			const newurl =
				window.location.protocol +
				'//' +
				window.location.host +
				window.location.pathname +
				`?colour=${colour.colour.param}`;
			window.history.pushState({ path: newurl }, '', newurl);
		}
		this.setState({ colour: colour });
	}

	groupProducts = (colours, products) => {
		let colourGroup = {};
		// console.log(colours);
		// console.log(products);
		colours.forEach((col) => {
			if (col.colour) {
			// if(!colourGroup[])
				const title = col.colour.title;
				colourGroup[title] = [];

				products.forEach((product) => {
					product.product_colour && product.product_colour.forEach( (pc) => {
						if(pc.colour && pc.colour.title === title) {
							colourGroup[title].push(product);
						}
					});
				});
			}
		});

		return colourGroup;
	}


	render() {
		const { collection } = this.props;
		const group_poducts = this.groupProducts(collection.colours, collection.products) || {};
		// console.log(group_poducts);
		const colourFeatureContent = Object.keys(group_poducts).map((key, i) => {
			// console.log(key);
			const content = {
				heading: 'colour',
				subheading: key,
				products: group_poducts[key]
			};

			return (
				<FeaturedProducts content={content} key={i} selectedColour={key} />
			);
		});

		const featureBlock = collection.accordion_with_title.map((p, i) => {
			if (!p.accordion_items) return null;
			// console.log(p);
			return (
				<div key={i} className="features">
					<MobileAccordion
						heading={
							<h2 className="section-heading">
								{p.title}
							</h2>
						}
						content={<Accordion accordion={p} />}
					/>
				</div>
			);
		});

		return (
			<div className="collection">
				{
					collection.full_bleed_hero ? 
						<CollectionFullHero data={collection} type="Collection" />
						:
						<CollectionHero
							collection={collection}
							colour={this.state.colour}
							colourChange={this.colourChange}
						/>
				}
				{collection.intro && <VideoIntro content={collection.intro} />}

				<section className="product-content">
					<div className="center-container">
						<div className="grid-parent">
							<div className="left-small">
								{collection.sticky_image &&
									collection.sticky_image.image && (
									<StickyImage
										stickyImage={collection.sticky_image}
									/>
								)}
							</div>
							<div className="right-medium">
								{featureBlock}

								{collection.mini_images && (
									<div className="mini-images">
										<h2 className="alt-section-heading">
											{collection.mini_images.heading}
										</h2>
										<p>{collection.mini_images.copy}</p>
										<div className="image-container flex-row">
											{collection.mini_images.square_images.map(
												(image, i) => {
													return (
														<img
															key={i}
															src={
																imageHelper(image, 'medium') || Images.fallbackCardImage
															}
															className="square-images"
														/>
													);
												}
											)}
										</div>

									</div>
								)}
							</div>
						</div>
					</div>
				</section>

				{colourFeatureContent}
				{!isEmpty(collection.image_carousel) && (
					<LifestyleSlider
						lifestyleImages={collection.image_carousel}
					/>
				)}
			</div>
		);
	}
}

CollectionContainer.propTypes = {
	collection: PropTypes.object,
};

export default CollectionContainer;
