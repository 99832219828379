import React from 'react';
import DownloadManual from '../../image/icons/download-manual.svg';

const ManualDownload = (data) => {
	// FIGURE OUT PRODUCT NULL
	let product = false;
	if (data.product) {
		product = data.product;
	}
	const singleManual = data.manuals.map((manual, i) => {
		if (manual.file) {
			return (
				<div key={i} className="single-manual-download">
					<a
						className="manual-download-button"
						href={manual.file.url}
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							className="manual-download-icon"
							alt={
								manual.type == 'manual'
									? 'Download User Guide'
									: 'Download Quick Guide'
							}
							src={DownloadManual}
						/>
						{manual.type == 'manual'
							? 'Download User Guide'
							: 'Download Quick Guide'}
					</a>
				</div>
			);
		}
	});
	return (
		<>
			<div className="manual-download">
				<div className="manual-download-container">
					<div className="manual-download-inner">
						{product.card_image.url && (
							<img
								className="manual-download-image"
								src={product.card_image.url}
								alt={product.title}
							/>
						)}
						<div className="manual-download-content">
							<div className="manual-download-top">
								{product.primary_product_code && (
									<p className="manual-download-product-code">
										{product.primary_product_code}
									</p>
								)}
								{product.title && (
									<p className="manual-download-product-title">
										{product.title}
									</p>
								)}
							</div>
							{product.manuals[0] && product.manuals[0].file && (
								<div className="manual-download-bottom">
									{product.manuals[0].file.url &&
										singleManual}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="manual-download-outro">
				<p className="manual-download-outro-copy">
					Visit our support page to view all FAQS
				</p>
				<a
					className="manual-download-outro-button button -orange"
					href="/support"
				>
					Go to support page
				</a>
			</div>
		</>
	);
};

export default ManualDownload;
