import React from 'react';
import Images from '../image/Images';
import { Link } from 'react-router-dom';
import CardContainer from '../container/partials/CardContainer';
// fix styling here
const RecipeList = ({ content }) => {
	// console.log(content);
	return (
		<div className="inner-content">
			<div className="filter-wrapper">
				<div className="filter-wrapper-inner">
					<h2 className="large-subheading">
						{content.heading}
					</h2>
				</div>
			</div>
			<CardContainer data={content.recipes} />
		</div>
	);
};

export default RecipeList;