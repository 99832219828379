import React, { useState, Fragment } from 'react';
import { useQuery } from '@apollo/react-hooks';
// import { useParams } from 'react-router';
import gql from 'graphql-tag';
import {isEmpty} from 'lodash';
import { Link } from 'react-router-dom';
import { imageHelper } from '../utils/helper';
import Images from '../image/Images';

const CategoryDropdownItem = ({category, number, active, setCategorySubmenu, setCategoryMenu}) => {
	let top_style = ['sub-menu-item'], bottom_style = ['subcategories-container'], category_link_style = ['category-link'] ;
	if(active) {
		top_style.push('-active');
		bottom_style.push('-active');
		category_link_style.push('-active');
	}

	const filterOptions = category.subcategories.filter((sub) => {
		// return !isEmpty(sub.products);
		return true;
	});

	return (
		<div className={top_style.join(' ')}>
			<Link to={`/kitchen-and-home/${category.slug}`} 
				className={category_link_style.join(' ')}
				onMouseEnter={() => {
					// console.log(number);
					setCategorySubmenu(number);
				}}
				onClick={() => setCategoryMenu(false)}
			>
				{category.title}
			</Link>
			<div className={bottom_style.join(' ')} 
				onMouseLeave={() => {
				// uncomment here to see how onMouseLeave work
					// if(active) {
					// 	setCategoryMenu(false);
					// }
				}}
			>
				{
					filterOptions.map((subcategory, index) => {
						const cardImage = imageHelper(subcategory.card_image, 'small') || Images.fallbackCardImage;
						return (
							<Link to={`/kitchen-and-home/${category.slug}/${subcategory.slug}`} className="subcategory-link" key={index} onClick={() => setCategoryMenu(false)}>
								<img src={cardImage} className="subcategory-image" />
								<p>{subcategory.title}</p>
							</Link>
						);
					})
				}
			</div>
		</div>
	);
};

export default CategoryDropdownItem;