import React from 'react';
import Images from '../image/Images';
import { Link } from 'react-router-dom';
import { imageHelper } from '../utils/helper';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const CardComponent = ({ data, selectedColour }) => {
	let url;
	let preHeader;
	let startDate = data.date_start
		? new Date(data.date_start).toLocaleDateString()
		: null;
	let endDate = data.date_end
		? new Date(data.date_end).toLocaleDateString()
		: null;

	switch (data.__typename) {
	case 'HintsAndTips':
		url = '/hints-and-tips/';
		preHeader = 'Hints & Tips';
		break;
	case 'Recipes':
		url = '/recipes/';
		preHeader = (data.meal && data.meal.meal_title) || data.__typename;
		break;
	case 'Promotions':
		url = '/promotions/';

		if (startDate && endDate) {
			preHeader = `${startDate} - ${endDate}`;
		} else if (endDate) {
			preHeader = endDate;
		} else {
			preHeader = data.__typename;
		}

		break;
	case 'Products':
		url = `/product/${data.categories[0].slug}/`;
		break;
	case 'Collections':
		url = '/collections/';
		break;
	default:
		break;
	}
	// console.log(data);
	// console.log(selectedColour);
	const renderColourImage = (data) => {
		const selected = data.product_colour.filter((colour) => {
			return colour.colour === selectedColour;
		});
		// console.log(selected);
		// console.log(selected[0].key_image);
		return (
			selected &&
			selected[0] &&
			selected[0].key_image && (
				<LazyLoadImage
					alt={''}
					src={imageHelper(selected[0].key_image.file, 'small')}
					wrapperClassName="card-image-wrapper"
				/>
			)
		);
	};

	const renderRegularImage = (data) => {
		// console.log(data);
		return (
			<LazyLoadImage
				alt={''}
				src={
					imageHelper(data.card_image, 'small') ||
					Images.fallbackCardImage
				} // use normal <img> attributes as props
				wrapperClassName="card-image-wrapper"
			/>
		);
	};

	// <img
	// 			className="card-image"
	// 			src={imageHelper(data.card_image, 'small') || Images.fallbackCardImage}
	// 			alt={''}
	// 		/>

	return (
		<Link to={url + data.slug} className="card">
			{data.awards_icon &&
				data.awards_icon.image &&
				data.awards_icon.image.url && (
					<img
						className="card-featured-icon"
						src={data.awards_icon.image.url}
						alt={''}
					/>
				)}
			{selectedColour
				? renderColourImage(data)
				: renderRegularImage(data)}
			<div className="content-wrapper">
				<div className="card-inner">
					<p className="date">{preHeader || data.__typename}</p>
					<p className="title">{data.title}</p>
					<div className="des-container">
						<p className="des">{data.tagline}</p>
						<img
							className="arrow-icon"
							src={Images.arrowRight}
							alt={''}
						/>
					</div>
				</div>
			</div>
		</Link>
	);
};

CardComponent.propTypes = {
	data: PropTypes.object,
};

export default CardComponent;
