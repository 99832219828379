import React from 'react';
import { isEmpty } from 'lodash';
import DownloadManual from '../image/icons/download-manual.svg';
import PropTypes from 'prop-types';
import Images from '../image/Images';
import { imageHelper } from '../utils/helper';

const ManualBlock = ({ manual }) => {
	if(!manual || isEmpty(manual.products)) return null;
	const { products, file, title, type } = manual;
	const product = products && products[0];

	return (
		<>
			<div className="manual-download">
				<div className="manual-download-container">
					<div className="manual-download-inner">
						<img
							className="manual-download-image"
							src={imageHelper(product && product.card_image, 'small') || Images.fallbackCardImage}
							alt={product && product.title || 'Sunbeam'}
						/>
						<div className="manual-download-content">
							<div className="manual-download-top">
								{product && product.primary_product_code && (
									<p className="manual-download-product-code">
										{product.primary_product_code}
										{product && product.price.discontinued_au && (
											<span>(Discontinued)</span>
										)}
									</p>
								)}
								{product && product.title && (
									<p className="manual-download-product-title">
										{product.title}
									</p>
								)}
								{/* {product && product.price && (
									<p className="manual-download-product-title">
										{`$${product.price.price_au}`}
									</p>
								)} */}
							</div>
							{file && (
								<div className="manual-download-bottom">
									{file.url && (
										<div className="single-manual-download">
											<a
												className="manual-download-button"
												target="_blank"
												rel="noopener noreferrer"
												href={file.url}
											>
												<img
													className="manual-download-icon"
													alt={
														type == 'manual'
															? 'Download User Guide'
															: 'Download Quick Guide'
													}
													src={DownloadManual}
												/>
												{type == 'manual'
													? 'Download User Guide'
													: 'Download Quick Guide'
												}
											</a>
										</div>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

ManualBlock.propTypes = {
	manual: PropTypes.object,
};

export default ManualBlock;
