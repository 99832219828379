import gql from 'graphql-tag';

const HOME_QUERY = gql`
	query HomeSearch {
		homePage {
			id
			title
			carousel {
				id
				title
				subtitle
				tagline
				button_link
				button_text
				background_image {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
				foreground_image {
					id
					name
					url
					alternativeText
					caption
					width
					height
					formats
					previewUrl
				}
			}
			social_sharing {
				id
				title
				description
				image {
					id
					url
					alternativeText
				}
			}
			video {
				embed_link
				poster {
					alternativeText
					url
				}
				title
			}
			product_range {
				id
				heading
				categories {
					id
					title
					slug
					created_at
					updated_at
					card_image {
						id
						name
						url
						alternativeText
						caption
						width
						height
						formats
						previewUrl
					}
				}
			}
			related_content_heading
			related_content {
				... on ComponentRepeatedRelatedRecipe {
					recipe {
						title
						status
						slug
						tagline
						card_image {
							id
							url
						}
					}
				}
				... on ComponentRepeatedRelatedHintsTip {
					hints_tip {
						title
						status
						slug
						tagline
						card_image {
							id
							url
						}
					}
				}
			}

			featured_promotions {
				id
				promotion {
					id
					title
					tagline
					date_start
					date_end
					slug
					status
					card_image {
						id
						name
						url
						alternativeText
						caption
						width
						height
						formats
						previewUrl
					}
				}
			}

			helper_links {
				id
				heading
				site_links {
					id
					link_text
					link_slug
				}
			}

			collections_range {
				id
				collection {
					id
					title
					tagline
					slug
					card_image {
						id
						name
						url
						alternativeText
						caption
						width
						height
						formats
						previewUrl
					}
					card_image_large {
						id
						name
						hash
						url
						alternativeText
						width
						height
						formats
						previewUrl
					}
				}
			}
		}
	}
`;

export default HOME_QUERY;
