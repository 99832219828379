import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useHistory, useLocation, useParams } from 'react-router-dom';
// import HelpModule from '../container/partials/HelpModule';
import CategoryHero from '../container/partials/CategoryHero';
import DropDownMenu from '../component/DropDownMenu';
import CtaBanner from '../container/partials/CtaBanner';
import SearchFilterContainer from '../container/partials/SearchFilterContainer';
import {findIndex, isEmpty} from 'lodash';
import CardFilterContainer from '../container/partials/CardFilterContainer';
import CardContainer from '../container/partials/CardContainer';
import SearchLinkComponent from '../component/SearchLinkComponent';
import { PRODUCT_SEARCH_QUERY, SEARCHPAGE, RECIPE_SEARCH_QUERY } from '../queries/search';
import SeoComponent from '../component/SeoComponent';
import ProductBlock from '../component/ProductBlock';
import Loader from '../component/Loader';

const SearchResultPage = () => {  
	const location = useLocation();
	const {keyword} = location.state;
	const [pageType, setPageType] = useState('products');
	const { data, loading, error, fetchMore } = useQuery(SEARCHPAGE, {
		variables: { keyword },
		skip: !keyword,
		fetchPolicy: 'cache-and-network'
	});

	if(loading) return <div className="search-page display-page"><Loader /></div>;
	if(error) return <div className="search-page display-page"></div>;
	const {searchResultsPage, products, recipes, hintsAndTips, promotions, searchKeywords} = data;
	// console.log(data);
	// console.log(pageType);
	return( 
		<div className="search-page display-page">
			{searchResultsPage && searchResultsPage.social_sharing && (
				<SeoComponent social={searchResultsPage.social_sharing} />
			)}
			{searchResultsPage && <CategoryHero content={searchResultsPage} />}
			<div className='center-container grid-parent bd-grey'>
				<div className='search-page-left'>
					{products && (
						<div className="search-results-products search-results-block">
							<SearchFilterContainer  
								data={products}
								keyword = {keyword}
							/>
						</div>
					)}
					{!isEmpty(recipes) && <SearchSectionComponent keyword={keyword} data={recipes} title='Recipes' />}
					{!isEmpty(hintsAndTips) && <SearchSectionComponent keyword={keyword} data={hintsAndTips} title='Hints & Tips' />}
					{!isEmpty(promotions) && <SearchSectionComponent keyword={keyword} data={promotions} title='Promotions'/>}
					{!isEmpty(searchKeywords) && <SearchSectionComponentKeywords keyword={keyword} data={searchKeywords} title='Links' />}
				</div>

				{false && <div className='search-page-right'> 
					<DropDownMenu value={pageType} name='meal' onChange={(e)=>setPageType(e.target.value)}>
						<option value="products">products</option>
						<option value="recipe">Recipe</option>
					</ DropDownMenu>
				</div>}
			</div>
		</div>
	);
};

const SearchSectionComponent = ({keyword, data, title}) => {
	return(
		<div className='flex-column search-results-block'>
			<div className='title-section'>
				<h1>{title}</h1>
				<h3 className='header-title'>{data.length} results for keyword <span className='brand-colour'>{keyword}</span></h3>
			</div>
			<CardContainer data={data} />
		</div>
	);
};

const SearchSectionComponentKeywords = ({keyword, data, title}) => {
	return(
		<div className='flex-column search-results-block'>
			<div className='title-section'>
				<h1>{title}</h1>
				<h3 className='header-title'>{data.length} results for keyword <span className='brand-colour'>{keyword}</span></h3>
			</div>
			<div className="grid-parent">
				<div className="grid-child-8">
					{data.map((item, i) => {
						return <SearchLinkComponent data={item} key={i} />;
					})}
				</div>
			</div>
		</div>
	);
};

const RecipeComponent = ({keyword, data}) => {
	// const { data, loading, error, fetchMore } = useQuery(RECIPE_SEARCH_QUERY, {
	// 	variables: { keyword },
	// 	skip: !keyword,
	// 	fetchPolicy: 'cache-and-network'
	// });

	// if(loading) return null;
	// if(error) return null;
	// if(!data) return null;
	// const {recipes, tags, meals} = data;
	// const filteredTags = tags.filter((tag)=> {
	// 	return !isEmpty(tag.recipes);
	// });
	return(
		<div className='flex-column'>
			<div className='title-section'>
				<h1>Recipes</h1>
				<h3 className='header-title'>{data.length} results for keyword <span className='brand-colour'>{keyword}</span></h3>
			</div>
			<CardContainer data={data} />
		</div>
	);
};


const ProductResultComponent = ({keyword}) => {

	const { data, loading, error, fetchMore } = useQuery(PRODUCT_SEARCH_QUERY, {
		variables: { keyword },
		skip: !keyword,
		fetchPolicy: 'cache-and-network'
	});

	if(loading) return null;
	if(error) return null;
	if(!data) return null;
	const {products} = data;
	// const onLoadMore = () => {
	// 	fetchMore({
	//     	variables: {
	//         	start: data.products.length
	//       	},
	//       	updateQuery: (prev, { fetchMoreResult }) => {
	//         	if (!fetchMoreResult) return prev;
	//         	return Object.assign({}, prev, {
	//           		products: [...prev.products, ...fetchMoreResult.products]
	//        		});
	//       	}
	//     });
	// }; onLoadMore={onLoadMore} categories = {categories}
	return(
		<SearchFilterContainer  
			data={products}
			keyword = {keyword}
		/>
	);
};


export default SearchResultPage;