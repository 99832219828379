import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Helmet } from 'react-helmet';
import Images from '../image/Images';
import gql from 'graphql-tag';
import Config from '../common/config';
const isAU = Config.theme === 'AU';
import { useParams, useLocation } from 'react-router';

const MainSeoComponent = () => {
	const { pathname } = useLocation();

	return (
		<Helmet
			meta={[
				{ property: 'og:image:width', content: '1200' },
				{ property: 'og:image:height', content: '630' },
				{ property: 'og:image:type', content: 'image/jpeg' },
				{ property: 'og:url', content: isAU ? 'https://www.sunbeam.com.au' : 'https://www.sunbeam.co.nz' }	
			]}
		>	
			{isAU ? (
				<link
					rel="canonical"
					href={`https://www.sunbeam.com.au${pathname}`}
				></link>
			) : (
				<link
					rel="canonical"
					href={`https://www.sunbeam.co.nz${pathname}`}
				></link>
			)}
			{isAU ? (
				<link rel="alternate" href={`https://www.sunbeam.co.nz${pathname}`} hrefLang="en-NZ"></link>
			) : (
				<link rel="alternate" href={`https://www.sunbeam.com.au${pathname}`} hrefLang="en-AU"></link>
			)}
			{isAU && 
				<script src="https://apps.bazaarvoice.com/deployments/sunbeam-au/main_site/production/en_AU/bv.js" async />
			}
		</Helmet>
	);
};

export default MainSeoComponent;

// const SEO_QUERY = gql`
// 	query SEOquery {
// 		seo {
// 			id
// 			title
// 			description
// 			image {
// 				id
// 				alternativeText
// 				url
// 			}	
// 		}	
// 	}
// `;