import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router';
import gql from 'graphql-tag';
import {isEmpty} from 'lodash';
import Images from '../image/Images';
// import { Link, withRouter } from 'react-router-dom';
// import ArticleHero from '../container/partials/ArticleHero';
// import RecipeBrief from '../container/partials/RecipeBrief';
// import RecipeTags from '../container/partials/RecipeTags';
import ImageBreak from '../component/ImageBreak';
import ImageCopyBlock from '../component/ImageCopyBlock';
import LargeHeading from '../component/LargeHeading';
import RecipeTip from '../container/partials/RecipeTip';
import AccordionToggle from '../component/Accordion';
import TextBlock from '../component/TextBlock';
import RepeatVideo from '../component/RepeatVideo';
import PromoForm from '../component/PromoForm';
import LifestyleSlider from '../container/partials/LifestyleSlider';
import FeaturedProducts from '../container/partials/FeaturedProducts';
import VideoIntro from '../component/VideoIntro';
import RecipeList from '../component/RecipeList';

const DynamicContentContainer = ({dynamicContent, share}) => {	
	// console.log(dynamicContent);
	return (
		<div className='dynamic-block'>

			{dynamicContent && (
				<div className="dynamic-block-container">
					{dynamicContent.map((contentType, i) => {
						if (
							contentType.__typename ===
						'ComponentRepeatedSingleImage'
						) {
							return (
								<section className="dynamic-image-break" key={i}>
									<div className="center-container grid-parent">
										<div className="grid-child-8">
											<ImageBreak content={contentType}  />
										</div>
									</div>
								</section>
							);
						}

						if (
							contentType.__typename ===
						'ComponentTemplatesLargeHeading'
						) {
							return (
								<section className="dynamic-large-heading"  key={i}>
									<div className="center-container grid-parent">
										<div className="grid-child-6-3">
											<LargeHeading content={contentType} />
										</div>
									</div>
								</section>
							);
						}

						if (
							contentType.__typename ===
						'ComponentTemplatesImageCopyBlock'
						) {
							return (
								<section className="dynamic-image-copy"  key={i}>
									<div className="center-container grid-parent">
										<div className="grid-child-6-3">
											<ImageCopyBlock content={contentType}  />
										</div>
									</div>
								</section>
							);
						}

						if (
							contentType.__typename ===
						'ComponentTemplatesHighlightBlock'
						) {
							return (
								<section className="dynamic-recipe-tip" key={i}>
									<div className="center-container grid-parent">
										<div className="grid-child-6-3">
											<RecipeTip content={contentType}  />
										</div>
									</div>
								</section>
							);
						}

						if (
							contentType.__typename ===
						'ComponentRepeatedAccordion'
						) {
							return (
								<section className="dynamic-accordion" key={i}>
									<div className="center-container grid-parent">
										<div className="grid-child-6-3">
											<AccordionToggle
												accordion={contentType}
												
											/>
										</div>
									</div>
								</section>
							);
						}

						if (
							contentType.__typename ===
						'ComponentTemplatesCta'
						) {
							return (
								<section className="dynamic-cta -remove-padding" key={i}>
									<div className="center-container grid-parent">
										<div className="grid-child-6-3">
											<div className="cta-wrapper">
												{contentType.open_new_browser && (
													<a className="button -orange" href={contentType.cta_link} target="_blank" rel="noopener noreferrer">
														{contentType.cta_text}
													</a>
												)}
												{!contentType.open_new_browser && (
													<a className="button -orange" href={contentType.cta_link}>
														{contentType.cta_text}
													</a>
												)}
											</div>
										</div>
									</div>
								</section>
							);
						}

						if (
							contentType.__typename ===
						'ComponentTemplatesContentBlock'
						) {
							return (
								<section className="dynamic-text-block" key={i}>
									<div className="center-container grid-parent">
										<div className="grid-child-6-3">
											<TextBlock content={contentType} key={i} />
										</div>
									</div>
								</section>
							);
						}

						if (
							contentType.__typename ===
						'ComponentRepeatedVideoCarousel'
						) {
							return (
								<section className="dynamic-repeat-video" key={i}>
									<div className="center-container grid-parent">
										<div className="grid-child-8">
											<RepeatVideo content={contentType} key={i} />
										</div>
									</div>
								</section>
							);
						}

						if (
							contentType.__typename ===
						'ComponentTemplatesImageSlider'
						) {
							return (
								<section className="dynamic-lifestyle-slider -remove-padding" key={i}>
									<LifestyleSlider
										lifestyleImages={contentType.single_image}
										key={i}
									/>
								</section>
							);
						}

						if (
							contentType.__typename ===
						'ComponentTemplatesProductShowcase'
						) {
							return (
								<section className="dynamic-featured-products" key={i}>
									<FeaturedProducts
										key={i}
										content={contentType}
										type='dynamic'
									/>
								</section>
							);
						}

						if (
							contentType.__typename === 'ComponentRepeatedIntro'
						) {
							return (
								<section className="dynamic-video-intro" key={i}>
									<div className="center-container grid-parent">
										<div className="grid-child-8">
											<VideoIntro key={i} content={contentType} />
										</div>
									</div>
								</section>
							);
						}

						if (
							contentType.__typename === 'ComponentTemplatesFormBlock'
						) {
							return (
								<section className="dynamic-form-block" key={i}>
									<PromoForm key={i} content={contentType} />
								</section>
							);
						}

						if (
							contentType.__typename ===
						'ComponentTemplatesRecipeList' && !isEmpty(contentType.recipes)
						) {
							return (
								<section className="dynamic-recipe-list" key={i}>
									<div className="card-container">
										<div className="center-container">
											<RecipeList key={i} content={contentType} />
										</div>
									</div>
								</section>
							);
						}
					})}
				</div>
			)}
		</div>
		
	);
};

export default DynamicContentContainer;
