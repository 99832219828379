import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { imageHelper } from '../../utils/helper';
import PropTypes from 'prop-types';
import Images from '../../image/Images';

const CategoryHero = ({ content, categoryslug }) => {
	let fg, bg;
	let subtitle;
	if (content.hero_with_foreground) {
		fg = imageHelper(content.hero_with_foreground.foreground);
		bg = imageHelper(content.hero_with_foreground.background);
	}

	switch (content.__typename) {
	case 'Categories':
		subtitle = 'Kitchen & Home';
		break;
	case 'Subcategories':
		subtitle = <Fragment><Link to={'/'}>Kitchen & Home</Link> /{' '}<Link to={`/kitchen-and-home/${categoryslug}`}>{content.category.title}</Link></Fragment>;
		break;
	case 'ProductRegistrationPage':
		subtitle = 'Support';
		break;
	case 'WarrantyPage':
		subtitle = 'Support';
		break;
	case 'ProductManualsPage':
		subtitle = 'Support';
		break;
	case 'ServicesPage':
		subtitle = 'Support';
		break;
	case 'FaqPage':
		subtitle = 'Support';
		break;
	case 'ContactPage':
		subtitle = 'Support';
		break;
	default:
		break;
	}

	return (
		<section className="category-hero category-hero-bg" style={bg ? { backgroundImage: 'url(' + bg + ')' } : { backgroundImage: 'url(' + Images.fallbackHeroBg + ')' }}>
			<div className="center-container grid-parent">
				<div className="grid-child-custom">
					<div className="category-hero-inner">
						<div className="category-hero-headings-wrapper">
							{content.subtitle || subtitle && (
								<h2 className="category-hero-title small-heading">
									{content.subtitle || subtitle}
								</h2>
							)}
							{content.title && (
								<h3 className="category-hero-subtitle large-subheading">
									{content.title}
								</h3>
							)}
							{/* {content.copy && (
								<div className="category-hero-copy">{content.copy}</div>
							)} */}
						</div>
						<div className="category-hero-fg-wrapper">
							<img className="category-hero-fg" src={fg ? fg : Images.fallbackHeroFg } alt="Sunbeam"/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

CategoryHero.propTypes = {
	content: PropTypes.object,
};

export default CategoryHero;