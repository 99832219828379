import React, { Component } from 'react';
import Salesforce from '../common/Salesforce';
import Config from '../common/config';
const isAU = Config.theme === 'AU';

export default class ContactForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			firstname: '',
			lastname: '',
			email: '',
			phone: '',
			message: '',
			agree: false,
			sbError: '',
			successMsg: ''
		};
	}

	handleInput = (e) => {
		const { name, value, type } = e.target;
		if (type === 'checkbox') {
			const isChecked = e.target.checked;
			return this.setState({
				[name]: isChecked,
				sbError: '',
				successMsg: ''
			});
		}

		this.setState({
			[name]: value,
			sbError: '',
			successMsg: ''
		});
	};

	buildSubmitData = (firstname,
		lastname,
		email,
		phone,
		message) => {
	

		return {
			'WebFormDetails': {
				'salutation':'',
				'FirstName': firstname,
				'LastName': lastname,
				'Email': email,
				'PhoneNumber': phone,
				'Country': isAU ? 'AU': 'NZ',
				'Address1': '',
				'PostalCode': '',
				'City': '',
				'KeyValuePairs': {
					'comments': message,
					'language':'English',
					'locale':'en_AU',
					'subject':'Contact Us',
					'dateOfPurchase':'',
					'model':''
				},
				'State': '',
				'SendEmail': 'true',
				'Type': 'Consumer_Default',
				'productList': [],
				'attachmentsList': []
			}
		};

	}


	handleSubmit = async e => {
		try {
			e.preventDefault();

			const {
				firstname,
				lastname,
				email,
				phone,
				message,
				agree,
			} = this.state;
			// console.log('submit here');
			if(firstname === '' || lastname === '' || email === '' || phone === '' || message === '' ) {
				return this.setState({
					sbError: '* Please fill out all your details'
				});
			}

			if(!agree) {
				return this.setState({
					sbError: '* You have to agree our privacy policy to continue'
				});
			}

			// const token = await Salesforce.getSFToken();
			
			// if(token) {
			// 	const formData = this.buildSubmitData(firstname,
			// 		lastname,
			// 		email,
			// 		phone,
			// 		message);
			// 	const res = await Salesforce.contactSF(token, formData, email);

			// 	if(res.success) {
			// 		this.setState({
			// 			firstname: '',
			// 			lastname: '',
			// 			email: '',
			// 			phone: '',
			// 			message: '',
			// 			agree: false,
			// 			successMsg: `You have successfully submitted your message with case Id ${res.data.caseNumber}`
			// 		});
			// 	} else if(res.error) {
			// 		this.setState({
			// 			sbError: res.error
			// 		});
			// 	}
			// } else {
			// 	this.setState({
			// 		sbError: 'An error occurred, please try again later.'
			// 	});
			// }

			const formData = this.buildSubmitData(firstname,
				lastname,
				email,
				phone,
				message);
			const res = await Salesforce.contactSF(formData, email);
			
			let newRes = JSON.parse(res.data.CaseResponse.ResponseText);

			if(res.success) {
				this.setState({
					firstname: '',
					lastname: '',
					email: '',
					phone: '',
					message: '',
					agree: false,
					successMsg: `You have successfully submitted your message with case number ${newRes.CaseNumber}`
				});
			} else if(res.error) {
				this.setState({
					sbError: res.error
				});
			}
		} catch(err) {
			console.log(err);
		}
	};

	render() {
		const {
			firstname,
			lastname,
			email,
			phone,
			message,
			agree,
			sbError,
			successMsg
		} = this.state;
		return (
			<section className="form-container -remove-padding-top">
				<div className="center-container grid-parent">
					<div className="form-wrapper form-left">
						<h3 className="form-type">Feedback</h3>
						<h4 className="form-heading">Send us a message</h4>
						<form 
							onSubmit={e => this.handleSubmit(e)}
							method="POST"
						>
							{/* <div className="form-field -input">
								<label htmlFor="lastname">Title</label>
								<select required>
									<option value="mr">Mr</option>
									<option value="miss">Miss</option>
									<option value="miss">Mrs</option>
								</select>
								<p className="helper-text">Enter your title</p>
							</div> */}
							<div className="form-field -input">
								<label htmlFor="firstname">Given name</label>
								<input
									id="firstname"
									type="text"
									minLength="2"
									name="firstname"
									value={firstname}
									required
									onChange={(e) => this.handleInput(e)}
								/>
								<p className="helper-text">Enter your given name</p>
							</div>

							<div className="form-field -input">
								<label htmlFor="lastname">Family name</label>
								<input
									id="lastname"
									type="text"
									minLength="2"
									name="lastname"
									value={lastname}
									required
									onChange={(e) => this.handleInput(e)}
								/>
								<p className="helper-text">Enter your family name</p>
							</div>

							<div className="form-field -input">
								<label htmlFor="phone">Phone number</label>
								<input
									id="phone"
									type="text"
									pattern="\d*"
									minLength="8"
									maxLength="10"
									name="phone"
									value={phone}
									required
									onChange={(e) => this.handleInput(e)}
								/>
								<p className="helper-text">
									Enter your phone number
								</p>
							</div>

							<div className="form-field -input">
								<label htmlFor="email">Email</label>
								<input
									id="email"
									type="email"
									minLength="3"
									name="email"
									value={email}
									required
									onChange={(e) => this.handleInput(e)}
								/>
								<p className="helper-text">Enter your email</p>
							</div>

							<div className="form-field -textarea">
								<label htmlFor="message">Comment or Question</label>
								<textarea
									id="message"
									type="text"
									name="message"
									value={message}
									onChange={(e) => this.handleInput(e)}
								></textarea>
							</div>

							<div className="form-field -checkbox">
								<input
									type="checkbox"
									name="agree"
									checked={agree}
									id="agree"
									required
									onChange={(e) => this.handleInput(e)}
								/>
								<label htmlFor="agree">
									By submitting, I confirm I have read and accept your{' '}
									<a href="https://privacy.newellbrands.com" target="_blank" rel="noopener noreferrer">Privacy Statement</a>
								</label>
							</div>

							<div className="form-field -submit">
								<button
									type="submit"
									className="form-submit button -orange"
									aria-label="Login Button"
									title="Login Button"
									onClick={this.handleSubmit}
								>
									Submit
								</button>
							</div>

							<div className="error-msg">
								{sbError}
							</div>
							<div className="success-msg">
								{successMsg}
							</div>
						</form>
					</div>
				</div>
			</section>
		);
	}
}
