import React, { useMemo, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import queryString from 'query-string';
import { isEmpty } from 'lodash';
import CardPaginationFilterContainer from '../container/partials/CardPaginationFilterContainer';
import CategoryHero from '../container/partials/CategoryHero';
import RECIPE_QUERY from '../queries/recipe';
import RelatedArticles from '../container/partials/RelatedArticles';
import SeoComponent from '../component/SeoComponent';
import Loader from '../component/Loader';

// eslint-disable-next-line react/prop-types
const RecipesIndex = ({ location }) => {
	const { data, loading, error } = useQuery(RECIPE_QUERY, {
		fetchPolicy: 'no-cache',
	});

	const getDropdownTags = (data) => {
		return (
			data &&
			data.recipes.reduce((accumulator, currentValue) => {
				if (currentValue.dropdown_tags.length !== 0) {
					currentValue.dropdown_tags.forEach((dt) => {
						if (
							accumulator.filter((e) => e.id === dt.id).length ===
							0
						) {
							accumulator.push(dt);
						}
					});
				}
				return accumulator;
			}, [])
		);
	};
	const applicansOptions = useMemo(() => getDropdownTags(data), [data]);
	if (loading) return <Loader />;
	if (error) return <p>error</p>;
	const { recipes, recipesPage, tags, meals, dropdown_tags } = data;
	const filteredTags = tags.filter((tag) => {
		return !isEmpty(tag.recipes);
	});
	const params = queryString.parse(location.search);

	// console.log('applicansOptions', applicansOptions);
	return (
		<div className="recipes-index display-page">
			{recipesPage.social_sharing && (
				<SeoComponent social={recipesPage.social_sharing} />
			)}
			<CategoryHero content={recipesPage} />
			<CardPaginationFilterContainer
				data={recipes || []}
				tags={filteredTags || []}
				meals={meals}
				defaultTag={params.tag}
				appliances={applicansOptions || []}
			/>
			{recipesPage.related_content && (
				<RelatedArticles
					relatedContent={recipesPage.related_content || []}
					relatedHeading={recipesPage.related_content_heading}
				/>
			)}
		</div>
	);
};

export default RecipesIndex;
