import gql from 'graphql-tag';

const PRODUCT_QUERY = gql`
	query Products($productslug: String) {
		products(where: { status: "published", slug: $productslug }, limit: 1) {
				id
				title
				slug
				primary_product_code
				status
				social_sharing {
					id
					title
					description
					image {
						id
						alternativeText
						url
					}
				}
				faqs {
					id
					visible_text
					dropdown_text
				}
				card_image {
					id
					alternativeText
					url
				}
				price {
					id
					price_au
					discontinued_au
				}
				product_colour {
					id
					colour {
						id
						title
						param
						hex_primary
						hex_optional
					}
					product_code
					ean
					key_image {
						id
						file {
							id
							url
							formats
						}
						title

					}
					au_retailers {
						id
						retailer {
							id
							title
							logo {
								id
								alternativeText
								url
							}
						}
						url
					}
					nz_retailers {
						id
						retailer {
							id
							title
							logo {
								id
								url
								alternativeText
							}
						}
						url
					}
				}
				gallery {
					file {
						url
						alternativeText
					}
					title
				}
				intro_video {
					heading
					tagline
					tagline_extended
					description
					video_embed {
						poster {
							url
						}
						title
						embed_link
					}
				}
				awards_and_icons {
					icons {
						title
						link
						image {
							url
						}
					}
					featured_icon {
						anchor_to_info
						icon {
							title
							link
							image {
								url
							}
						}
						title
						tagline
						link
					}
				}
				sticky_image {
					id
					image {
						id
						url
						alternativeText
					}
				}
				features {
					id
					show_images
					accordion_items {
						id
						image {
							id
							url
						}
						visible_text
						dropdown_text
					}
				}
				specifications {
					id
					construction_finish
					weight {
						id
						net_weight_kg
						gross_weight_kg
					}
					custom_specifications {
						id
						label
						value
					}
					dimensions {
						id
						height_mm
						width_mm
						depth_mm
					}
				}
				in_the_box {
					id
					show_images
					image_list_items {
						id
						image {
							id
							url
						}
						text
					}
				}
				works_with {
					products (where: { status: "published"}) {
						id
						title
						slug
					}
				}
				faqs {
					id
					visible_text
					dropdown_text
				}
				warranty {
					id
					type
					duration_months
					note
					copy
				}
				image_carousel {
					id
					desktop {
						id
						url
						alternativeText
					}
					mobile {
						id
						url
						alternativeText
					}
				}
				related_products {
					id
					heading
					subheading
					button_link
					button_text
					products (where: { status: "published"}) {
						id
						status
						title
						primary_product_code
						slug
						card_image {
							id
							url
						}
						awards_and_icons {
							id
							featured_icon {
								id
								icon {
									id
									image {
										id
										url
										alternativeText
									}
								}
							}
						}
						price {
							id
							price_au
						}
						subcategories {
							id
							slug
							title
							category {
								id
								slug
								title
							}
						}				
					}
				}
				collection {
					id
					title
				}
				related_content_heading
				related_content {
					... on ComponentRepeatedRelatedRecipe {
						recipe {
							id
							title
							slug
							tagline
							status
							card_image {
								id
								url
							}
						}
					}
					... on ComponentRepeatedRelatedHintsTip {
						hints_tip {
							id
							title
							slug
							status
							tagline
							card_image {
								id
								url
							}
						}
					}
				}
				subcategories {
					id
					title
					category {
						id
						title
					}
				}
				manuals {
					id
					title
					type
					file {
						id
						url
					}
				}
				hints_tips {
					id
					title
				}
			}
	}
`;

// need fragment for related content fields

export default PRODUCT_QUERY;
