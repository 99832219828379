import axios from 'axios';
import apisauce from 'apisauce';
import Config from './config';
import moment from 'moment';
const isAU = Config.theme === 'AU';

const apis = apisauce.create({
	timeout: 15000
});

export default class Salesforce {
	// static async getSFToken() {
	// 	const backendurl = process.env.REACT_APP_BACKEND_URL;
	// 	const url = backendurl.replace('/graphql', '');
	// 	// console.log(url);
	// 	const res = await axios.get(`${url}/salesforce`);
	// 	let token = res.data.token;
	// 	// console.log(token);
	// 	return token;
	// }

	// static async contactSF(token, formdata, email) {
	static async contactSF(formdata, email) {
		const { api, api_key } = Config.salesforce.contactApi;

		apis.setHeaders({
			'Content-Type': 'application/json',
			// Authorization: `Bearer ${token}`,
		});
		apis.setBaseURL(api);

		let newData = { ...formdata };
		const time = moment();

		const dateTime = time.format('MM/DD/YYYYTHH:MM:SS');
		const callingDate = time.format('MM/DD/YYYY');

		newData['Header'] = {
			APIKey: api_key,
			DateTime: dateTime,
			RequestID: 'WCSCC123',
			Base: {
				CallingApplication: 'Sunbeam',
				CallingDate: callingDate,
				CallingLanguage: 'en',
				CallingLocale: isAU ? 'AU' : 'NZ',
				UserIdentification: email,
				CreateCaseSystem: 'SFDC',
			},
		};
		// console.log(newData);
		const res = await apis.post('', newData);
		// console.log(res);
		const { ok, data } = res;
		if (ok) {
			return { success: true, data: data };
		} else {
			return { error: 'An error occurred, please try again later.' };
		}
	}

	static async registerProduct(formdata) {
		const { api, api_key } = Config.salesforce.registerApi;

		apis.setHeaders({
			'Content-Type': 'application/json',
		});
		apis.setBaseURL(api);

		let newData = { ...formdata };
		const time = moment();

		const dateTime = time.format('MM/DD/YYYYTHH:MM:SS');
		const callingDate = time.format('MM/DD/YYYY');

		newData['Header'] = {
			APIKey: api_key,
			DateTime: dateTime,
			RequestID: 'WCSPR456',
			Base: {
				CallingApplication: 'Sunbeam',
				CallingDate: callingDate,
				CallingLanguage: 'en',
				CallingLocale: isAU ? 'AU' : 'NZ',
				CreateCaseSystem: 'SFDC',
			},
		};

		// console.log(newData);
		const res = await apis.post('/registerproduct', newData);
		// console.log(res);
		const { ok, data } = res;
		if (ok) {
			return { success: true, data };
		} else {
			return { error: 'An error occurred, please try again later.' };
		}
	}

	static async promoForm(formdata) {
		const { api, api_key } = Config.salesforce.promoFormApi;

		apis.setHeaders({
			'Content-Type': 'application/json',
			APIKey: api_key,
		});
		apis.setBaseURL(api);

		let newData = { ...formdata };
		// const time = moment();
		// const dateTime = time.format('MM/DD/YYYYTHH:MM:SS');
		// const callingDate = time.format('MM/DD/YYYY');
		// console.log(newData);
		const res = await apis.post('/campaign/registration', newData);
		// console.log(res);
		const { ok, data } = res;
		if (ok) {
			return { success: true, data };
		} else {
			return { error: 'An error occurred, please try again later.' };
		}
	}
}
