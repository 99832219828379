import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router';
import  { Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
// import { Link, withRouter } from 'react-router-dom';
import ArticleHero from '../container/partials/ArticleHero';
// import RecipeBrief from '../container/partials/RecipeBrief';
import DynamicContentContainer from '../container/DynamicContentContainer';
import SeoComponent from '../component/SeoComponent';
import SocialSharingContainer from '../container/SocialSharingContainer';
import Loader from '../component/Loader';

const CustomPage = () => {
	let { customslug } = useParams();
	console.log(customslug);
	const { loading, error, data } = useQuery(CUSTOMPAGE_QUERY, {
		variables: { customslug },
		fetchPolicy: 'no-cache'
	});
	// console.log(data);
	if (loading) return <Loader />;
	if (error) return <p>error</p>;
	const { customPages } = data;
	console.log(customPages);
	if ( customPages && customPages[0]) {
		const customPage = customPages && customPages[0];
		const dynamicContent = customPage && customPage.dynamic_content;
		return (
			<div className="promotion custom-page">
				{customPage.social_sharing && <SeoComponent social={customPage.social_sharing} />}
				<ArticleHero data={customPage} type="" />
				<SocialSharingContainer type="link" />

				{dynamicContent && <DynamicContentContainer dynamicContent={dynamicContent} share />}
			</div>
		);
	}
	else {
		return <Redirect to='/promotions'  />;
	}
};

export default CustomPage;

const CUSTOMPAGE_QUERY = gql`
	query CustomPagesQuery($customslug: String) {	
		customPages(where: { status: "published", slug: $customslug }) {
			id
			title
			tagline
			slug
			status
			hero {
				full_width_image
				desktop {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
				mobile {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
			}
			dynamic_content {
				... on ComponentTemplatesContentBlock {
					id
					content
				}
				... on ComponentTemplatesCta {
					id
					cta_text
					cta_link
					open_new_browser
				}
				... on ComponentTemplatesImageSlider {
					id
					single_image {
						id
						desktop {
							id
							url
							alternativeText
							formats
						}
					}
				}
				... on ComponentTemplatesImageCopyBlock {
					reverse
					copy
					image_media {
						alternativeText
						url
						formats
					}
				}

				... on ComponentTemplatesFormBlock {
					id
					title
					campaign_id
					campaign_name
					campaign_type
					gift {
						title
					}
					place_of_purchase {
						title
					}
					product_picker {
						title
						code
					}
					products {
						title
						primary_product_code
						primary_ean
					}
					draw_question
				}

				... on ComponentTemplatesLargeHeading {
					id
					heading
				}
				... on ComponentTemplatesHighlightBlock {
					id
					title
					content
				}
				... on ComponentRepeatedIntro {
					id
					video_poster {
						id
						alternativeText
						url
					}
					video_embed_url
					heading
					content
					button_url
					button_text
					button_new_window
				}
				... on ComponentRepeatedVideoCarousel {
					id
					video {
						id
						embed_link
						poster {
							id
							alternativeText
							url
						}
						title
					}
				}
				... on ComponentRepeatedSingleImage {
					id
					desktop {
						id
						url
						alternativeText
						formats
					}
					mobile {
						id
						url
						alternativeText
						formats
					}
				}
				... on ComponentTemplatesProductShowcase {
					products (where: { status: "published"}) {
						id
						title
						status
						slug
						primary_product_code
						subcategories {
							id
							slug
							title
							category {
								id
								slug
								title
							}
						}
						card_image {
							id
							alternativeText
							url
						}
						price {
							id
							price_au
							discontinued_au
						}
						awards_and_icons {
							id
							featured_icon {
								id
								icon {
									id
									image {
										id
										url
										alternativeText
									}
								}
							}
						}
					}
					section_heading
					heading
				}
				... on ComponentRepeatedAccordion {
					id
					show_images
					accordion_items {
						id
						visible_text
						image {
							id
							alternativeText
							url
						}
						dropdown_text
					}
				}
				... on ComponentTemplatesRecipeList {
					id
					heading
					recipes {
						id
						title
						tagline
						slug
						status
						preparation_time
						cooking_time
						servings
						meal {
							id
							meal_title
						}
						card_image {
							id
							name
							url
							alternativeText
							caption
							width
							height
							formats
							previewUrl
						}
						tags (sort: "tag:asc") {
							id
							tag
						}
					}
				}
			}
			social_sharing {
				id
				title
				description
				image {
					id
					url
					alternativeText
				}
			}
		}
	}
`;
