import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Playbutton from '../image/icons/video-play.svg';
import WatchVideo from '../image/icons/watch-video.svg';
import Images from '../image/Images';

const example_productIntro = {
	heading: 'Espresso Machine with Integrated Grinder',
	tagline:
		'This all-in-one machine brings together everything you need to easily make great-tasting, café-style coffee at home.',
	description:
		'The New York Collection will transport you to the city where dreams are made.',
	video_embed: [
		{
			poster: {
				url: 'https://dummyimage.com/550x345',
			},
			title: 'Introducing this product 1',
			embed_link: 'https://www.youtube.com/embed/0OYYDX45Czw',
		},
		{
			poster: {
				url: 'https://dummyimage.com/550x345',
			},
			title: 'Introducing this product 2',
			embed_link: 'https://www.youtube.com/embed/0OYYDX45Czw',
		},
		{
			poster: {
				url: 'https://dummyimage.com/550x345',
			},
			title: 'Introducing this product 3',
			embed_link: 'https://www.youtube.com/embed/0OYYDX45Czw',
		},
	],
};

const example_anchor = {
	icon: {
		title: 'Gift with purchase',
		link: '/',
		image: {
			url: 'https://dummyimage.com/45',
		},
	},
	title: 'Gifts with purchase',
	tagline:
		'Maecenas cursus elit ut tincidunt tincidunt. Maecenas et nulla metus. Mauris at ipsum in arcu porttitor ',
	link: '/',
};

class RepeatVideoModule extends Component {
	constructor(props) {
		super(props);
		// console.log(props);
		this.toggleDescription = this.toggleDescription.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.updateVideo = this.updateVideo.bind(this);
		let currentVideo = false;
		if (props.videos) {
			currentVideo = props.videos[0];
		}

		this.state = {
			showDescription: false,
			videoModal: false,
			currentVideo: currentVideo,
		};
	}

	toggleModal(e) {
		e.preventDefault();
		this.setState((prev) => ({
			videoModal: !prev.videoModal,
		}));
	}

	toggleDescription(e) {
		e.preventDefault();
		this.setState((prev) => ({
			showDescription: !prev.showDescription,
		}));
	}

	updateVideo(e, video) {
		e.preventDefault();
		this.setState({ currentVideo: video, videoModal: false });
	}

	render() {
		const { videos } = this.props;
		// console.log(productIntro);
		// console.log(anchor);

		return (
			<div className="product-intro-content-container">
				{this.state.currentVideo && (
					<div className="product-intro-video-gallery">
						<div className="single-product-intro-video">
							{this.state.videoModal && (
								<div className="intro-video-wrapper">
									<iframe
										className="intro-video-element"
										src={
											this.state.currentVideo.embed_link +
											'?autoplay=1'
										}
										frameBorder="0"
										allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
										allowFullScreen
									></iframe>
								</div>
							)}
							{!this.state.videoModal && (
								<div className="product-intro-video-poster-container">
									<img
										className="product-intro-video-poster"
										src={this.state.currentVideo.poster && this.state.currentVideo.poster.url || Images.fallbackPoster}
										alt={this.state.currentVideo.title}
									/>
									<div className="video-poster-button-wrapper">
										<a
											href="#"
											onClick={(e) => this.toggleModal(e)}
											className="video-poster-button"
										>
											<img
												className="video-poster-button-icon"
												src={Playbutton}
												alt="Play video"
											/>
										</a>
									</div>
								</div>
							)}
							<div className="product-intro-video-details">
								{this.state.currentVideo.title && (
									<h4 className="product-intro-video-title">
										{this.state.currentVideo.title}
									</h4>
								)}
								<a
									className="play-video-button"
									href="#"
									onClick={(e) => this.toggleModal(e)}
								>
									<img src={WatchVideo} alt="Play Video" />
								</a>
							</div>
						</div>
					</div>
				)}

				{this.state.currentVideo && (
					<div className="product-intro-selector-container swiper-pagination-bullets">
						{videos.map((video, i) => {
							let active = false;
							if (this.state.currentVideo == video) {
								active = true;
							}
							return (
								<a
									key={i}
									className={
										active
											? 'product-intro-selector swiper-pagination-bullet -active'
											: 'product-intro-selector swiper-pagination-bullet'
									}
									href="#"
									onClick={(e) => this.updateVideo(e, video)}
								></a>
							);
						})}
					</div>
				)}
			</div>
		);
	}
}

const RepeatVideo = ({ content }) => {
	// console.log(content);
	return (
		<div className="repeat-video">
			<RepeatVideoModule videos={content.video} />
		</div>
	);
};

export default RepeatVideo;
