import React from 'react';
import { Route } from 'react-router-dom';
import Header from '../component/Header';
import Footer from '../component/Footer';

const AppLayout = ({ component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(matchProps) => (
				<div className="app">
					<Header />
					<div className="master-container">
						<Component {...matchProps} />
					</div>
					<Footer />
				</div>
			)}
		/>
	);
};

export default AppLayout;
