import React from 'react';
// import { Link, withRouter } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

const HelpModule = ({ content, color, type }) => {
	if (!content) return null;

	let links;
	if (type === 'home') {
		links = content.site_links;
	} else {
		links = content.links;
	}
		
	const helpLinkList =
		links &&
		links.map((item, i) => {
			return (
				<li className="help-module-link" key={i}>
					<Link to={item.link_slug}>{item.link_text}</Link>
				</li>
			);
		});
	return (
		<section
			className={'help-module -' + color}
		>
			<div className="help-module-inner centred-flex grid-parent">
				<div className="help-left">
					<h2 className="help-module-heading">{content.heading}</h2>
				</div>
				<ul className="help-module-right">{helpLinkList}</ul>
			</div>
		</section>
	);
};

export default HelpModule;