import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';

const NotFound = () => {
	return (
		<div
			className="no-found-page"
			style={{
				minHeight: 350,
			}}
		>
			<div
				className="center-container"
				style={{
					margin: 30,
					marginTop: 80,
				}}
			>
				<div className="centre-container">
					<h3>Sorry, that page isn’t here.</h3>
					<p>
						You didn’t do anything wrong. We may have moved the page
						you’re looking for.
					</p>
					<p>
						<Link
							to={'/'}
							className='brand-colour'
						>
							Back Home
						</Link>
					</p>
				</div>
			</div>
		</div>
	);
};

export default NotFound;
