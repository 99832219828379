import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
// import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import MainSeoComponent from './MainSeoComponent';
import Images from '../image/Images';

class TrackerWrapper extends Component {
	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			window.scrollTo(0, 0);
			// this.gaTrack(this.props.location.pathname);
		}
	}

	gaTrack = (pathname) => {
		// console.log(`@@@location ${pathname}`);
		// ReactGA.set({ page: pathname });
		// ReactGA.pageview(pathname);
	};

	render() {
		return (
			<Fragment>
				<MainSeoComponent />
				{this.props.children}
			</Fragment>
		);
	}
}

export default withRouter(TrackerWrapper);
