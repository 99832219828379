import React from 'react';
import ProductList from './ProductList';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const FeaturedProducts = ({ content, type, selectedColour, categorySlug }) => {
	if (!content) return null;
	
	let heading, subheading;

	if (type === 'subcategory') {
		heading = 'feature';
		subheading = content.title;
	} else if (type === 'dynamic') {
		heading = content.section_heading;
		subheading = content.heading;
	} else {
		heading = content.heading;
		subheading = content.subheading;
	}

	const items = content.prioritise_product ?  content.prioritise_product.products : content.products;
	// const title = content.heading || content.title
	return (
		<section className="featured-products">
			<div className="center-container">
				<div className="featured-product-wrapper">
					<div className="featured-product-heading-container">
						{heading && (
							<h3 className="featured-product-heading">
								{heading}
							</h3>
						)}
						{subheading && (
							<h4 className="featured-product-subheading">
								{subheading}
							</h4>
						)}
					</div>

					{
						// fix url here
						content.slug && (
							<Link
								className="feature-products-button button -orange -desktop"
								to={`/kitchen-and-home/${categorySlug}/${content.slug}`}
							>
								{`View all ${content.title}`}
							</Link>
						)
					}
				</div>
				<div className="featured-products-container">
					<ProductList items={items} selectedColour={selectedColour} />
				</div>
				{
					// fix url here
					content.slug && (
						<Link
							className="feature-products-button button -orange -mobile"
							to={`/kitchen-and-home/${categorySlug}/${content.slug}`}
						>
							{`View all ${content.title}`}
						</Link>
					)
				}
			</div>
		</section>
	);
};

FeaturedProducts.propTypes = {
	content: PropTypes.object,
};

export default FeaturedProducts;
