import React from 'react';
import Swiper from 'react-id-swiper';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Images from '../../image/Images';

// const products = [
// 	{
// 		title: 'Coffee',
// 		image: 'https://dummyimage.com/400',
// 	},
// 	{
// 		title: 'Blenders & Juicers',
// 		image: 'https://dummyimage.com/400',
// 	},
// 	{
// 		title: 'Kettles & Toasters',
// 		image: 'https://dummyimage.com/400',
// 	},
// 	{
// 		title: 'Food Preparation',
// 		image: 'https://dummyimage.com/400',
// 	},
// ];

const swiperParams = {
	slidesPerView: 2,
	freeMode: true,
	breakpoints: {
		320: {
			slidesPerView: 1.5,
		},
		640: {
			slidesPerView: 2.5,
		},
	},
};

const ProductRange = ({ productRange }) => {
	const productBlocks = productRange.categories.map((category, i) => {
		return (
			<Link
				to={`/kitchen-and-home/${category.slug}`}
				key={i}
				className="product-range-product"
			>
				<div className="product-range-product-inner">
					<img
						className="product-range-product-image"
						src={category.card_image && category.card_image.url || Images.fallbackCardImage}
						alt={category.card_image && category.card_image.alternativeText || 'Sunbeam'}
					/>
					<p className="product-range-product-title">
						{category.title}
					</p>
				</div>
			</Link>
		);
	});
	return (
		<section className="product-range-module-container">
			<div className="center-container grid-parent">
				<div className="product-range-headings-container narrow-left">
					<h2 className="product-range-title small-heading">
						Product Range
					</h2>
					<h3 className="product-range-subtitle large-subheading">
						{productRange.heading}
					</h3>
				</div>
				<div className="product-range-products-container wide-right -desktop">
					{productBlocks}
				</div>
				<div className="product-range-products-container wide-right -mobile">
					<Swiper {...swiperParams}>{productBlocks}</Swiper>
				</div>
			</div>
		</section>
	);
};

ProductRange.propTypes = {
	productRange: PropTypes.object,
};

export default ProductRange;
