import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import colourParam from '../utils/colour';
import ProductHero from '../container/product/ProductHero';
import ProductIntro from '../container/product/ProductIntro';
import Accordion from '../component/Accordion';
import Specifications from '../container/product/Specifications';
import ProductNav from '../container/product/ProductNav';
// import ProductBuySticky from '../container/product/ProductBuySticky';
import Inclusions from '../container/product/Inclusions';
import StickyImage from '../container/product/StickyImage';
import ManualDownload from '../container/partials/ManualDownload';
import LifestyleSlider from '../container/partials/LifestyleSlider';
import RelatedArticles from '../container/partials/RelatedArticles';
import FeaturedProducts from '../container/partials/FeaturedProducts';
import Warranty from '../container/product/Warranty';
import MobileAccordion from '../component/MobileAccordion';
import ProductRetailers from '../container/product/ProductRetailers';
import ErrorBoundary from '../component/ErrorBoundary';
import Config from '../common/config';
const isAU = Config.theme === 'AU';

class ProductContainer extends Component {
	constructor(props) {
		super(props);
		this.colourChange = this.colourChange.bind(this);
		this.toggleRetailers = this.toggleRetailers.bind(this);
		this.state = {
			colour: null,
			retailers: false,
		};
	}

	componentDidMount() {
		const colour = colourParam(this.props.product);
		this.setState({
			colour,
		});
	}

	toggleRetailers(e) {
		e.preventDefault();
		this.setState((prev) => ({
			retailers: !prev.retailers,
		}));
	}

	colourChange(e, colour) {
		e.preventDefault();
		if (history.pushState) {
			const newurl =
				window.location.protocol +
				'//' +
				window.location.host +
				window.location.pathname +
				`?colour=${colour.colour.param}`;
			window.history.pushState({ path: newurl }, '', newurl);
		}
		this.setState({ colour: colour });
	}

	render() {
		const { product, categoryslug, subcategoryslug } = this.props;
		// console.log(product);
		let featured_icon;
		if (product.awards_and_icons) {
			featured_icon = product.awards_and_icons.featured_icon;
		}
		const headingElement = (title, id) => {
			return <h2 className={id}>{title}</h2>;
		};

		let showInTheBox = !isEmpty(product.in_the_box);
		let showFaqs = !isEmpty(product.faqs);
		let showFeatures = !isEmpty(product.features);

		// console.log("prdiuct featutes");
		// console.log(product.primary_product_code);

		return (
			<div className="product">
				{/* <ProductBuySticky toggleRetailers={this.toggleRetailers} /> */}
				{this.state.retailers && (
					<ErrorBoundary>
						<ProductRetailers
							product={product}
							colour={this.state.colour}
							colourchange={this.colourChange}
							toggleRetailers={this.toggleRetailers}
						/>
					</ErrorBoundary>
					
				)}
				<ErrorBoundary>
					<ProductHero
						product={product}
						colour={this.state.colour}
						colourChange={this.colourChange}
						toggleRetailers={this.toggleRetailers}
						categoryslug={categoryslug}
						subcategoryslug={subcategoryslug}
					/>
				</ErrorBoundary>
				{/* <PromoForm/> */}

				{product.intro_video && (
					<ErrorBoundary>
						<ProductIntro
							anchor={featured_icon}
							productIntro={product.intro_video}
						/>
					</ErrorBoundary>
				)}
				<section className="product-content">
					<ErrorBoundary>
						<ProductNav toggleRetailers={this.toggleRetailers} showInTheBox={showInTheBox} showFaqs={showFaqs} showFeatures={showFeatures} />
					</ErrorBoundary>
					<div className="center-container">
						<div className="grid-parent">
							<div className="left-small">
								{product.sticky_image &&
									product.sticky_image.image && (
									<StickyImage
										stickyImage={product.sticky_image}
									/>
								)}
							</div>
							<div className="right-medium">
								{!isEmpty(product.features) && (
									<div id="features" className="features">
										<MobileAccordion
											heading={headingElement(
												'Features',
												'features-heading section-heading'
											)}
											content={
												<Accordion
													accordion={product.features}
												/>
											}
										/>
									</div>
								)}
								{!isEmpty(product.specifications) && (
									<div
										id="specifications"
										className="specifications"
									>
										<MobileAccordion
											heading={headingElement(
												'Specifications',
												'specifications-heading section-heading'
											)}
											content={
												<Specifications
													specifications={
														product.specifications
													}
													colour={
														this.state.colour &&
														this.state.colour
															.colour &&
														this.state.colour.colour
															.title
													}
												/>
											}
										/>
									</div>
								)}
								{!isEmpty(product.in_the_box) && (
									<div id="in-the-box" className="inclusions">
										<MobileAccordion
											heading={headingElement(
												'In The Box',
												'inclusions-heading section-heading'
											)}
											content={
												<Inclusions
													inclusions={
														product.in_the_box
													}
												/>
											}
										/>
									</div>
								)}

								{!isEmpty(product.faqs) && (
									<div id="faq" className="inclusions">
										<MobileAccordion
											heading={headingElement(
												'FAQs',
												'inclusions-heading section-heading'
											)}
											content={
												<Accordion accordion={product.faqs} />
											}
										/>
									</div>
								)}

								{isAU && (
									<div id="reviews" className="review">
										<MobileAccordion
											heading={headingElement(
												'Reviews',
												'reviews-heading section-heading'
											)}
											content={
												<div
													data-bv-show="reviews"
													data-bv-product-id={
														product.primary_product_code
													}
												>
												</div>
											}
										/>
									</div>
								)}

								{!isEmpty(product.warranty) && (
									<div
										id="warranty"
										className="product-warranty"
									>
										<MobileAccordion
											heading={headingElement(
												'Warranty',
												'warranty-heading section-heading'
											)}
											content={
												<Warranty
													warranty={product.warranty}
													model={product.primary_product_code}
												/>
											}
										/>
									</div>
								)}
								{!isEmpty(product.manuals) && (
									<div id="service" className="service">
										<MobileAccordion
											heading={headingElement(
												'Service & Support',
												'service-heading section-heading'
											)}
											content={
												<ManualDownload
													product={product}
													manuals={product.manuals}
												/>
											}
										/>
									</div>
								)}
							</div>
						</div>
					</div>
				</section>
				{!isEmpty(product.image_carousel) && (
					<ErrorBoundary><LifestyleSlider lifestyleImages={product.image_carousel} /></ErrorBoundary>
				)}
				{product.related_content[0] && (
					<ErrorBoundary><RelatedArticles
						relatedContent={product.related_content || []}
						relatedHeading={product.related_content_heading}
					/></ErrorBoundary>
				)}
				{product.related_products && (
					<ErrorBoundary><FeaturedProducts content={product.related_products} /></ErrorBoundary>
				)}
			</div>
		);
	}
}

ProductContainer.propTypes = {
	product: PropTypes.object,
};

export default ProductContainer;
