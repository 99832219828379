import React from 'react';

const LargeHeading = ({ content }) => {
	// console.log(content);
	if (!content) return null;
	if (content.heading) {
		return (
			<h3 className="large-subheading">{content.heading}</h3>
		);
	}
};

export default LargeHeading;
