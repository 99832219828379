import React from 'react';
import { useQuery } from '@apollo/react-hooks';
// import CardFilterContainer from '../container/partials/CardFilterContainer';
import CategoryHero from '../container/partials/CategoryHero';
import SeoComponent from '../component/SeoComponent';
import ReactMarkdown from 'react-markdown';
import Breaks from 'remark-breaks';
import Loader from '../component/Loader';
import gql from 'graphql-tag';

const WhereToBuy = () => {
	const { data, loading, error } = useQuery(WHERETOBUYPAGE);
	if (loading) return <Loader />;
	if (error) return null;
	const { whereToBuyPage } = data;
	const { social_sharing } = whereToBuyPage;
	return (
		<div className="wtb-page">
			{social_sharing && <SeoComponent social={social_sharing} />}
			<CategoryHero content={whereToBuyPage} />
			<section className="text-block -remove-padding-top -remove-padding-bottom">
				<div className="center-container grid-parent">
					<div className="text-wrapper grid-child-6-3 markdown-block">
						<ReactMarkdown children={whereToBuyPage.copy} plugins={[Breaks]} />
					</div>
				</div>
			</section>
			<section className="wtb-links -remove-padding-top">
				<div className="center-container grid-parent">
					<div className="link-wrapper grid-child-8">
						{whereToBuyPage.retailers.map((item, j) => {
							return (
								<a
									key={j}
									href={item.url}
									target="_blank"
									rel="noopener noreferrer"
									className="single-product-retailer"
								>
									<img
										className="product-retailer-logo"
										src={item.logo.url}
										alt={item.title}
									/>
								</a>
							);
						})}
					</div>
				</div>
			</section>
		</div>
	);
};

export default WhereToBuy;

const WHERETOBUYPAGE = gql`
	query WhereToBuyPageQuery {
		whereToBuyPage {
			id
			title
			copy
			social_sharing {
				id
				title
				description
				image {
					id
					url
					alternativeText
				}
			}
			retailers {
				title
				url
				logo {
					url
				}
			}
			hero_with_foreground {
				id
				foreground {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
				background {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
			}
		}
	}
`;
