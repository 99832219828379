import React from 'react';
import PropTypes from 'prop-types';

const StickyImage = ({ stickyImage }) => {
	const url = (stickyImage.image && stickyImage.image.url) || stickyImage.url;
	const text =
		(stickyImage.image && stickyImage.image.alternativeText) ||
		stickyImage.alternativeText;

	return (
		<div className="product-sticky-image-container">
			<img className="product-image-sticky" src={url} alt={text} />
		</div>
	);
};

StickyImage.propTypes = {
	stickyImage: PropTypes.object,
};

export default StickyImage;
