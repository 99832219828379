import React from 'react';
import PropTypes from 'prop-types';
import Images from '../../image/Images';
import { imageHelper } from '../../utils/helper';

//Add boolean to CategoryHero param for seperating publish date and promotion expiration date
const ArticleHero = ({ data, type }) => {
	if (!data) return null;
	
	let preHeader;
	let startDate = data.date_start ? new Date(data.date_start).toLocaleDateString() : null;
	let endDate = data.date_end ? new Date(data.date_end).toLocaleDateString() : null;

	switch (data.__typename) {
	case 'Recipes':
		preHeader = data.meal && data.meal.meal_title || data.__typename;
		break;
	case 'Promotions':
		if (startDate && endDate) {
			preHeader = `${startDate} - ${endDate}`;
		} else if (endDate) {
			preHeader = endDate;
		} else {
			preHeader = data.__typename;
		}
	
		break;
	default:
		break;
	}

	if (data.hero && data.hero.full_width_image) {
		return (
			<section className="article-hero -full-width">
				<div className="article-hero-desktop" style={{
					backgroundImage:
						'url(' + data.hero.desktop.url + ')',
				}}>
					<div className="center-container grid-parent">
						<div className="article-hero-inner grid-child-8">
							<h2 className="article-hero-title small-heading">{type}</h2>
							<h3 className="article-hero-subtitle large-subheading">
								{data.title}
							</h3>
							<p className="article-hero-tagline">{data.tagline}</p>
							{preHeader && (
								<p className="article-hero-date">{preHeader}</p>
							)}
						</div>
					</div>
				</div>

				<div className="article-hero-mobile" style={{
					backgroundImage:
						'url(' + imageHelper(data.hero.mobile, 'large') + ')',
				}}>
					<div className="center-container grid-parent">
						<div className="article-hero-inner grid-child-8">
							<h2 className="article-hero-title small-heading">{type}</h2>
							<h3 className="article-hero-subtitle large-subheading">
								{data.title}
							</h3>
							{preHeader && (
								<p className="article-hero-date">{preHeader}</p>
							)}
						</div>
					</div>
				</div>
			</section>
		);
	} else {
		return (
			<section className="article-hero -contained">
				<div className="center-container grid-parent">
					<div className="article-hero-inner grid-child-8">
						<h2 className="article-hero-title small-heading">{type}</h2>
						<h3 className="article-hero-subtitle large-subheading">
							{data.title}
						</h3>
						{preHeader && (
							<p className="article-hero-date">{preHeader}</p>
						)}
						<img
							className="article-hero-image -desktop"
							src={data.hero && data.hero.desktop.url || Images.fallbackPoster}
							alt={data.title || 'Sunbeam'}
						/>
						<img
							className="article-hero-image -mobile"
							src={imageHelper(data.hero && data.hero.mobile, 'large') || Images.fallbackCardImage}
							alt={data.title || 'Sunbeam'}
						/>
					</div>
				</div>
			</section>
		);
	}
};

ArticleHero.propTypes = {
	data: PropTypes.object,
	type: PropTypes.string
};

export default ArticleHero;