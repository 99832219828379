import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import CardContainer from '../container/partials/CardContainer';
import CategoryHero from '../container/partials/CategoryHero';
import SeoComponent from '../component/SeoComponent';
import Loader from '../component/Loader';

const PromotionIndex = () => {
	const { data, loading, error, fetchMore } = useQuery(PROMOTION_QUERY);
	if (loading) return <Loader />;
	if (error) return <p>error</p>;
	const { promotions, promotionsPage } = data;

	return (
		<div className="promotions-index display-page">
			{promotionsPage.social_sharing && <SeoComponent social={promotionsPage.social_sharing} />}
			<CategoryHero content={promotionsPage} />
			<section className="card-module -remove-padding-top">
				<div className="center-container grid-parent">
					<div className="grid-child-10">
						<CardContainer 
							data={promotions || []} 
						/>
					</div>
				</div>
			</section>
		</div>
	);
};

export default PromotionIndex;

const PROMOTION_QUERY = gql`
	query PromotionQuery {
		promotions (sort: "created_at:desc", where: { status: "published" }) {
			id
			title
			tagline
			slug
			status
			date_end
			date_start
			created_at
			card_image {
				id
				name
				url
				alternativeText
				caption
				width
				height
				formats
				previewUrl
			}
		}
		promotionsPage {
			id
			title
			social_sharing {
				id
				title
				description
				image {
					id
					url
					alternativeText
				}
			}
			hero_with_foreground {
				id
				foreground {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
				background {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
			}
		}
	}
`;
