import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import Breaks from 'remark-breaks';

// const text = '# This is a header\n\nAnd this is a paragraph';

const TextBlock = ({ content, col }) => {
	if (!content) return null;
	let columnClass = '';
	if (col) {
		columnClass = ' grid-child-' + col;
	}
	return (
		<div className={'text-block markdown-block' + columnClass}>
			<ReactMarkdown children={content.content} plugins={[Breaks]} />
		</div>
	);
};

TextBlock.propTypes = {
	content: PropTypes.object,
};

export default TextBlock;
