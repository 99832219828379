import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import Breaks from 'remark-breaks';

const ContentBlock = ({ content, color, anchorID }) => {
	if (!content) return null;

	const { title, copy, button_text, button_link, link, read_more } = content;
	return (
		<section
			className="general-content-block"
			id={anchorID}
			style={color ? { background: color } : {}}
		>
			<div className="center-container grid-parent">
				<div className="grid-child-6-3">
					<div className="inner-content">
						<h3 className="general-content-block-subtitle">{title}</h3>
						<div className="general-content-block-content markdown-block"><ReactMarkdown children={copy} plugins={[Breaks]} /></div>
						
						{button_text && (
							<div className="cta-container">
								<Link
									to={button_link ? button_link : '#'}
									className="feature-products-button button -orange -desktop"
								>
									{button_text}
								</Link>
							</div>
						)}

						{/* {read_more && (
							<div className="cta-container">
								<Link
									to={read_more ? read_more : '#'}
									className="general-content-block-content-link"
								>
									{read_more}
								</Link>
							</div>
						)} */}
					</div>
				</div>
			</div>
		</section>
	);
};

ContentBlock.propTypes = {
	colour: PropTypes.object,
	content: PropTypes.object,
};


export default ContentBlock;
