import React, { Component } from 'react';
import moment from 'moment';
import {isEmpty} from 'lodash';
import Salesforce from '../common/Salesforce';
import Config from '../common/config';
const isAU = Config.theme === 'AU';

export default class RegisterForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			firstname: '',
			lastname: '',
			email: '',
			phone: '',
			message: '',
			agree: false,
			streetaddress: '',
			suburb: '',
			state: '',
			postcode: '',
			country: '',
			model: '',
			retailer: '',
			storelocation: '',
			date: '',
			attachmentData: null,
			sbError: ''
		};
	}

	checkFileSize= (event) => {
		let files = event.target.files;
		// let size = 1000000;
		let err = ''; 
		for(var x = 0; x < files.length; x++) {
			const filesize = ((files[x].size/1024)/1024).toFixed(4); //MB
			if (filesize > 10) {
				err += files[x].type + 'is too large, please pick a smaller file\n';
			}
		}
		if (err !== '') {
			event.target.value = null;
			console.log(err);

			this.setState({sbError: err});
			return false;
		}
		
		return true;
	}

	onFileChange = async e => {
		// Make new FileReader
		let files = e.target.files;
		if(!this.checkFileSize(e)) return; 
		  
		let that = this;
		let allFiles = [];

		for (let i = 0; i < files.length; i++) {
			let file = files[i];
			// Make new FileReader
			let reader = new FileReader();
			// Convert the file to base64 text
			reader.readAsDataURL(file);
			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				let fileInfo = {
					fileName: file.name,
					fileMimeType: file.type,
					fileBodyBase64: reader.result.replace('data:image/png;base64,', ''),
					// size: Math.round(file.size / 1000) + ' kB',
					// file: file,
				};

				allFiles.push(fileInfo);
				if(allFiles.length == files.length){
					that.setState({ attachmentData: allFiles, sbError: '' }); 
				}
			};
		}
	};

	handleInput = (e) => {
		const { name, value, type } = e.target;
		if (type === 'checkbox') {
			const isChecked = e.target.checked;
			return this.setState({
				[name]: isChecked,
				sbError: ''
			});
		}

		this.setState({
			[name]: value,
			sbError: '',
			successMsg: ''
		});
	};

	buildSubmitData = (
		firstname,
		lastname,
		email,
		phone,
		agree,
		streetaddress,
		suburb,
		state,
		postcode,
		country,
		model,
		retailer,
		storelocation,
		date,
		attachmentData
	) => {
		const dateofpurchase = moment(date).format('YYYY-MM-DDTHH:MM:SS');
		// console.log(dateofpurchase);
		// console.log(date);
		return {
			'WebFormDetails': {
				'FirstName': firstname,
				'LastName': lastname,
				'Email': email,
				'PhoneNumber': phone,
				'Country': isAU ? 'AU': 'NZ',
				'Address1': streetaddress,
				'PostalCode': postcode,
				'City': suburb,
				'KeyValuePairs': {
					dateofpurchase,
					'epUserAccountId': '',
					'language': 'English',
					'locale': 'en_AU',
					'marketingEmailSignUp': 'true'
				},
				'State': state,
				'Salutation': '',
				'SendEmail': 'true',
				'Product': model,
				'Qty': '1',
				'PurchasedFrom': retailer,
				'StoreLocation': storelocation,
				'attachmentData': attachmentData
			}
		};
	}

	handleSubmit = async e => {
		try {
			e.preventDefault();

			const {
				firstname,
				lastname,
				email,
				phone,
				agree,
				streetaddress,
				suburb,
				state,
				postcode,
				country,
				model,
				retailer,
				storelocation,
				date,
				attachmentData
			} = this.state;
			// console.log('submit here');

			if(firstname === '' || lastname === '' || email === '' || phone === '' || streetaddress === '' 
				|| suburb === '' || state === '' || postcode === ''  || model === '' 
				|| retailer === '' || storelocation === '' || date === '' || attachmentData === []
			) {
				return this.setState({
					sbError: '* Please fill out all your details'
				});
			}

			if(!agree) {
				return this.setState({
					sbError: '* You have to agree our privacy policy to continue'
				});
			}

			const formData = this.buildSubmitData(
				firstname,
				lastname,
				email,
				phone,
				agree,
				streetaddress,
				suburb,
				state,
				postcode,
				country,
				model,
				retailer,
				storelocation,
				date,
				attachmentData
			);

				
			const res = await Salesforce.registerProduct(formData, email);

			if(res.success) {
				const message = `You have successfully submitted your message with ContactId Id ${res.data.ProductRegistrationResponse && res.data.ProductRegistrationResponse.ContactId}`;
				this.setState({
					firstname: '',
					lastname: '',
					email: '',
					phone: '',
					agree: false,
					streetaddress: '',
					suburb: '',
					state: '',
					postcode: '',
					country: '',
					model: '',
					retailer: '',
					storelocation: '',
					date: '',
					attachmentData: null,
					sbError: '',
					successMsg: message,
				});
			} else {
				this.setState({
					sbError: res.error
				});
			}

		} catch(err) {
			console.log(err);
		}
	};

	render() {
		// console.log(this.props.items);
		const {
			firstname,
			lastname,
			email,
			phone,
			message,
			agree,
			streetaddress,
			suburb,
			state,
			postcode,
			// country,
			model,
			retailer,
			storelocation,
			date,
			attachmentData,
			sbError,
			successMsg
		} = this.state;
		
		let StateDropdown;
		if (isAU) {
			StateDropdown = <div className="form-field -input">
				<label htmlFor="state">State</label><br/>
				<select id="state" name="state" required onChange={(e) => this.handleInput(e)} value={this.state.state}>
					<option value="" disabled hidden>Select your state</option>
					<option value="NSW" >NSW</option>
					<option value="QLD">QLD</option>
					<option value="ACT">ACT</option>
					<option value="SA">SA</option>
					<option value="TAS">TAS</option>
					<option value="VIC">VIC</option>
					<option value="WA">WA</option>
				</select>
				<p className="helper-text">Enter your state</p>
			</div>;
		} else {
			StateDropdown = <div className="form-field -input">
				<label htmlFor="state">State</label><br/>
				<select id="state" name="state" required onChange={(e) => this.handleInput(e)} value={this.state.state}>
					<option value="" disabled hidden>Select your state</option>
					<option value="AUK">AUK</option>
					<option value="BOP">BOP</option>
					<option value="CAN">CAN</option>
					<option value="GIS">GIS</option>
					<option value="HKB">HKB</option>
					<option value="MBH">MBH</option>
					<option value="MWT">MWT</option>
					<option value="NSN">NSN</option>
					<option value="NTL">NTL</option>
					<option value="OTA">OTA</option>
					<option value="STL">STL</option>
					<option value="TAS">TAS</option>
					<option value="TKI">TKI</option>
					<option value="WGN">WGN</option>
					<option value="WKO">WKO</option>
					<option value="WTC">WTC</option>
				</select>
				<p className="helper-text">Enter your state</p>
			</div>;
		}

		// console.log(date);
		// console.log(attachmentData);
		return (
			<section className="form-container -remove-padding-top">
				<div className="center-container grid-parent">
					<div className="form-wrapper form-left">
						<form onSubmit={e => this.handleSubmit(e)}
							method="POST"
						>
							<h3 className="form-type">Register</h3>
							<h4 className="form-heading">Product details</h4>

							<div className="form-field -input">
								<label htmlFor="model">Model number</label>
								<input
									id="model"
									type="text"
									minLength="2"
									name="model"
									value={model}
									required
									onChange={(e) => this.handleInput(e)}
								/>
								<p className="helper-text">Enter your model number</p>
							</div>

							<div className="form-field -input">
								<label htmlFor="model">Date of Purchase</label>
								<input
									id="date"
									type="date"
									name="date"
									value={date}
									required
									className='datepicker'
									onChange={(e) => this.handleInput(e)}
								/>
								<p className="helper-text">Enter your model number</p>
							</div>

							<div className="form-field -input">
								<label htmlFor="retailer">Purchased from (retailer)</label>
								<input
									id="retailer"
									type="text"
									minLength="2"
									name="retailer"
									value={retailer}
									required
									onChange={(e) => this.handleInput(e)}
								/>
								<p className="helper-text">Enter the retailer you purchased the product</p>
							</div>

							<div className="form-field -input">
								<label htmlFor="storelocation">Store location</label>
								<input
									id="storelocation"
									type="text"
									minLength="2"
									name="storelocation"
									value={storelocation}
									required
									onChange={(e) => this.handleInput(e)}
								/>
								<p className="helper-text">Enter the location of the store you purchased the product from</p>
							</div>

							<div className="form-field -input">
								<label>Proof of purchase. Upload a scan or photo of your receipt (10MB limit, PDF, PNG, JPG)</label>
								<input
									id="selected-file"
									type="file"
									name="selected-file"
									required
									accept="image/*,.pdf,.doc,.docx"
									onChange={(e) => this.onFileChange(e)}
									className='file-upload'
								/>
								<div className='file-upload-section'>
									<span>{isEmpty(attachmentData) ? 'No file attached' : attachmentData[0].fileName}</span>
									
									<label htmlFor="selected-file" className="form-submit button upload-button">Choose File</label>
								</div>
								
								<p className="helper-text">Enter the proof you purchased the product</p>
							</div>


							<h4 className="form-heading">Contact details</h4>
							<div className="form-field -input">
								<label htmlFor="firstname">Given name</label>
								<input
									id="firstname"
									type="text"
									minLength="2"
									name="firstname"
									value={firstname}
									required
									onChange={(e) => this.handleInput(e)}
								/>
								<p className="helper-text">Enter your given name</p>
							</div>

							<div className="form-field -input">
								<label htmlFor="lastname">Family name</label>
								<input
									id="lastname"
									type="text"
									minLength="2"
									name="lastname"
									value={lastname}
									required
									onChange={(e) => this.handleInput(e)}
								/>
								<p className="helper-text">Enter your family name</p>
							</div>

							<div className="form-field -input">
								<label htmlFor="phone">Phone number</label>
								<input
									id="phone"
									type="text"
									pattern="\d*"
									minLength="8"
									maxLength="10"
									name="phone"
									value={phone}
									required
									onChange={(e) => this.handleInput(e)}
								/>
								<p className="helper-text">
									Enter your phone number
								</p>
							</div>

							<div className="form-field -input">
								<label htmlFor="email">Email</label>
								<input
									id="email"
									type="email"
									minLength="3"
									name="email"
									value={email}
									required
									onChange={(e) => this.handleInput(e)}
								/>
								<p className="helper-text">Enter your email</p>
							</div>

							<div className="form-field -input">
								<label htmlFor="streetaddress">Street address</label>
								<input
									id="streetaddress"
									type="text"
									minLength="1"
									name="streetaddress"
									value={streetaddress}
									required
									onChange={(e) => this.handleInput(e)}
								/>
								<p className="helper-text">Enter your street address</p>
							</div>

							<div className="form-field -input">
								<label htmlFor="suburb">Suburb / Town</label>
								<input
									id="suburb"
									type="text"
									minLength="1"
									name="suburb"
									value={suburb}
									required
									onChange={(e) => this.handleInput(e)}
								/>
								<p className="helper-text">Enter your suburb</p>
							</div>

							<div>
								{StateDropdown}
							</div>

							{/* <div className="form-field -input">
								<label htmlFor="state">State</label><br/>
									<select id="state" name="state" required onChange={(e) => this.handleInput(e)} value={this.state.state}>
										<option value="" disabled selected hidden>Select your state</option>
										<option value="NSW" >NSW</option>
										<option value="QLD">QLD</option>
										<option value="SA">SA</option>
										<option value="TAS">TAS</option>
										<option value="VIC">VIC</option>
										<option value="WA">WA</option>
									</select>
								<p className="helper-text">Enter your state</p>
							</div>

							<div className="form-field -input">
								<label htmlFor="state">State</label><br/>
									<select id="state" name="state" required onChange={(e) => this.handleInput(e)} value={this.state.state}>
										<option value="" disabled selected hidden>Select your state</option>
										<option value="AUK">AUK</option>
										<option value="BOP">BOP</option>
										<option value="CAN">CAN</option>
										<option value="GIS">GIS</option>
										<option value="HKB">HKB</option>
										<option value="MBH">MBH</option>
										<option value="MWT">MWT</option>
										<option value="NSN">NSN</option>
										<option value="NTL">NTL</option>
										<option value="OTA">OTA</option>
										<option value="STL">STL</option>
										<option value="TAS">TAS</option>
										<option value="TKI">TKI</option>
										<option value="WGN">WGN</option>
										<option value="WKO">WKO</option>
										<option value="WTC">WTC</option>
									</select>
								<p className="helper-text">Enter your state</p>
							</div> */}

							<div className="form-field -input">
								<label htmlFor="postcode">Postcode</label>
								<input
									id="postcode"
									type="text"
									minLength="1"
									name="postcode"
									value={postcode}
									required
									onChange={(e) => this.handleInput(e)}
								/>
								<p className="helper-text">Enter your postcode</p>
							</div>

							{/* <div className="form-field -input">
								<label htmlFor="country">Country</label>
								<input
									id="country"
									type="text"
									minLength="1"
									name="country"
									value={country}
									required
									onChange={(e) => this.handleInput(e)}
								/>
								<p className="helper-text">Enter your country</p>
							</div> */}

							<div className="form-field -checkbox">
								<input
									type="checkbox"
									name="agree"
									checked={agree}
									id="agree"
									required
									onChange={(e) => this.handleInput(e)}
								/>
								<label htmlFor="agree">
									By submitting, I confirm I have read and accept your{' '}
									<a href="https://privacy.newellbrands.com" target="_blank" rel="noopener noreferrer">Privacy Statement</a>
								</label>
							</div>

							<div className="form-field -submit">
								<button
									type="submit"
									className="form-submit button -orange"
									aria-label="Login Button"
									title="Login Button"
									onClick={this.handleSubmit}
								>
									Submit
								</button>
							</div>
							<div className="error-msg">
								{sbError}
							</div>
							<div className="success-msg">
								{successMsg}
							</div>
						</form>
					</div>
				</div>
			</section>
		);
	}
}
