import gql from 'graphql-tag';

export const MANUAL_SEARCH_QUERY = gql`
	query SearchProductManualsQuery($keyword: String!) {
		manualsAndGuides(where: { title_contains: $keyword }) {
			id
			title
			type
			products (where: { status_in: ["published", "archive"] }) {
				id
				title
				slug
				status
				primary_product_code
				card_image {
					id
					alternativeText
					url
				}
				price {
					id
					price_au
					discontinued_au
				}
			}
			file {
				id
				name
				url
				provider
				caption
				formats
				previewUrl
			}
		}
	}
`;

export const PRODUCTMANUAL = gql`
	query ProductManualsQuery($start: Int, $limit: Int) {
		manualsAndGuides(limit: $limit, start: $start) {
			id
			title
			type
			products (where: { status_in: ["published", "archive"] }) {
				id
				title
				slug
				status
				primary_product_code
				card_image {
					alternativeText
					url
				}
				price {
					price_au
					discontinued_au
				}
			}
			file {
				id
				name
				url
				provider
				caption
				formats
				previewUrl
			}
		}
	}
`;

export const PRODUCTMANUALPAGE = gql`
	query ProductManualPageQuery {
		supportLandingPage {
			id
			title
			copy

			warranty {
				id
				heading
				links {
					id
					link_text
					link_slug
				}
			}

			services {
				id
				heading
				links {
					id
					link_text
					link_slug
				}
			}

			contact {
				id
				heading
				links {
					id
					link_text
					link_slug
				}
			}

			general_support {
				id
				heading
				links {
					id
					link_text
					link_slug
				}
			}

			product_support {
				id
				heading
				links {
					id
					link_text
					link_slug
				}
			}
		}
		productManualsPage {
			id
			title
			copy
			social_sharing {
				id
				title
				description
				image {
					id
					url
					alternativeText
				}
			}
			hero_with_foreground {
				id
				foreground {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
				background {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
			}
		}
	}
`;
