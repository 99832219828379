import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const SearchLinkComponent = ({ data }) => {

	return (
		<div className="search-link-component">
			{data.slug &&
			<Link to={data.slug} className="card">
				<p className="title">{data.title}</p>
				<p>{data.description}</p>
			</Link>}
			{data.external_url &&
			<a href={`${data.external_url}`} target="_blank" rel="noopener noreferrer"className="card">
				<p className="title">{data.title}</p>
				<p>{data.description}</p>
			</a>}
		</div>
	);
};


SearchLinkComponent.propTypes = {
	data: PropTypes.object,
};

export default SearchLinkComponent;
