import React from 'react';
import Appliances from '../../image/icons/recipe-appliances.svg';
import Time from '../../image/icons/recipe-cooking-time.svg';
import Servings from '../../image/icons/recipe-servings.svg';
import Preparation from '../../image/icons/recipe-preparation.svg';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const RecipeBrief = ({ recipe }) => {
	return (
		<section className="recipe-brief -remove-padding">
			<div className="center-container grid-parent">
				<div className="grid-child-8">
					{recipe.preparation_time && (
						<div className="recipe-brief-row">
							<div className="recipe-brief-row-left">
								<img
									className="recipe-brief-icon"
									src={Preparation}
									alt=""
								/>
								<p className="recipe-brief-heading">Preparation time</p>
							</div>
							<div className="recipe-brief-row-right">
								<p className="recipe-brief-details">
									{recipe.preparation_time}
								</p>
							</div>
						</div>
					)}
					{recipe.cooking_time && (
						<div className="recipe-brief-row">
							<div className="recipe-brief-row-left">
								<img className="recipe-brief-icon" src={Time} alt="" />
								<p className="recipe-brief-heading">Cooking time</p>
							</div>
							<div className="recipe-brief-row-right">
								<p className="recipe-brief-details">
									{recipe.cooking_time}
								</p>
							</div>
						</div>
					)}
					{recipe.servings && (
						<div className="recipe-brief-row">
							<div className="recipe-brief-row-left">
								<img
									className="recipe-brief-icon"
									src={Servings}
									alt=""
								/>
								<p className="recipe-brief-heading">Servings</p>
							</div>
							<div className="recipe-brief-row-right">
								<p className="recipe-brief-details">
									{recipe.servings}
								</p>
							</div>
						</div>
					)}
					{recipe.products && (
						<div className="recipe-brief-row">
							<div className="recipe-brief-row-left">
								<img
									className="recipe-brief-icon"
									src={Appliances}
									alt=""
								/>
								<p className="recipe-brief-heading">Appliances</p>
							</div>
							<div className="recipe-brief-row-right">
								{recipe.products.map((item, i) => {
									if (item.slug && item.title) {
										const link = `/kitchen-and-home/${item.subcategories[0].category.slug}/${item.subcategories[0].slug}/${item.slug}`; 

										return (
											<Link
												key={i}
												to={link}
												className="recipe-brief-details -appliances"
											>
												{item.title}
											</Link>
										);
									}
								})}
							</div>
						</div>
					)}
				</div>
			</div>
		</section>
	);
};

RecipeBrief.propTypes = {
	recipe: PropTypes.object,
};

export default RecipeBrief;
