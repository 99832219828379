import React from 'react';
import { useQuery } from '@apollo/react-hooks';
// import gql from 'graphql-tag';
import HOME_QUERY from '../queries/home/home';
import ProductRange from '../container/partials/ProductRange';
import CollectionRange from '../container/partials/CollectionRange';
import RelatedArticles from '../container/partials/RelatedArticles';
import HelpModule from '../container/partials/HelpModule';
// import PromotionBanner from '../container/partials/PromotionBanner';
import HomePromotion from '../container/home/HomePromotion';
import HomeHero from '../container/partials/HomeHero';
import SeoComponent from '../component/SeoComponent';
import HomeVideo from '../component/HomeVideo';
import Loader from '../component/Loader';

const Home = () => {
	const { data, loading, error } = useQuery(HOME_QUERY);
	// console.log(data);
	if (loading) return <Loader />;
	if (error) return <p>error</p>;
	const { homePage } = data;
	// console.log(homePage);
	return (
		<div className="home">
			{homePage.social_sharing && <SeoComponent social={homePage.social_sharing} />}
			{homePage.carousel && <HomeHero carousel={homePage.carousel} />}
			{homePage.product_range && (
				<ProductRange productRange={homePage.product_range} />
			)}
			{homePage.video && homePage.video[0] && (
				<section className="dynamic-repeat-video">
					<div className="center-container grid-parent">
						<div className="grid-child-12">
							<HomeVideo content={homePage.video} />
						</div>
					</div>
				</section>
			)}
			{homePage.collections_range && (
				<CollectionRange CollectionRange={homePage.collections_range} />
			)}
			{homePage.related_content && (
				<RelatedArticles
					relatedContent={homePage.related_content || []}
					relatedHeading={homePage.related_content_heading}
				/>
			)}
			{homePage.featured_promotions && (
				<HomePromotion content={homePage.featured_promotions} type="home" />
			)}
			{homePage.helper_links && (
				<HelpModule content={homePage.helper_links} type="home" />
			)}
		</div>
	);
};

export default Home;
