import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import HelpModule from '../container/partials/HelpModule';
import ServiceAgent from '../container/partials/ServiceAgent';
import CategoryHero from '../container/partials/CategoryHero';
import SeoComponent from '../component/SeoComponent';
import Loader from '../component/Loader';

const Service = () => {
	const { data, loading, error } = useQuery(SERVICEPAGE);
	console.log(data);
	if (loading) return <Loader />;
	if (error) return <p>error</p>;
	const { supportLandingPage, servicesPage } = data;
	console.log(supportLandingPage);
	const {
		warranty,
		general_support,
		contact,
		product_support,
	} = supportLandingPage;
	return (
		<div className="services-page">
			{servicesPage.social_sharing && <SeoComponent social={servicesPage.social_sharing} />}
			<CategoryHero content={servicesPage} />
			<section className="service-agents -remove-padding-top">
				<div className="center-container grid-parent">
					<div className="grid-child-8">
						<h2 className="article-hero-title small-heading">
							Repairs & Spare parts
						</h2>
						<h3 className="alt-section-heading">Find a service agent</h3>

						<ServiceAgent />
					</div>
				</div>
			</section>
			<HelpModule content={warranty} color="white" />
			<HelpModule content={product_support} color="grey" />
			<HelpModule content={general_support} color="white" />
			<HelpModule content={contact} color="grey" />
		</div>
	);
};

export default Service;

const SERVICEPAGE = gql`
	query ServicePageQuery {
		supportLandingPage {
			id
			title
			copy
			warranty {
				id
				heading
				links {
					id
					link_text
					link_slug
				}
			}

			services {
				id
				heading
				links {
					id
					link_text
					link_slug
				}
			}

			contact {
				id
				heading
				links {
					id
					link_text
					link_slug
				}
			}

			general_support {
				id
				heading
				links {
					id
					link_text
					link_slug
				}
			}

			product_support {
				id
				heading
				links {
					id
					link_text
					link_slug
				}
			}
		}
		servicesPage {
			id
			title
			copy
			social_sharing {
				id
				title
				description
				image {
					id
					url
					alternativeText
				}
			}
			hero_with_foreground {
				id
				foreground {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
				background {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
			}
		}
	}
`;
