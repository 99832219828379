import React from 'react';
import Swiper from 'react-id-swiper';
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash';

const swiperParams = {
	slidesPerView: 1,
	freeMode: false,
	loop: true,
	pagination: {
		el: '.lifestyle-slider-pagination',
		type: 'bullets',
		clickable: true,
	},
	navigation: {
		nextEl: '.lifestyle-slider-next',
		prevEl: '.lifestyle-slider-prev',
	},
};

const swiperParamsSingle = {
	slidesPerView: 1,
	freeMode: false
};


const LifestyleSlider = ({ lifestyleImages }) => {
	// console.log(lifestyleImages);
	if (!lifestyleImages || isEmpty(lifestyleImages)) return null;
	// const lifestyleImages = data.lifestyleImages;
	const LifestyleSlide = lifestyleImages.map((item, i) => {
		if (item.desktop) {
			return (
				<img
					key={i}
					className="lifestyle-slide-image"
					src={item.desktop.url}
					alt={item.desktop.alternativeText}
				/>
			);
		}
	});
	
	const LifestyleSlideMobile = lifestyleImages.map((item, i) => {
		if (item.mobile) {
			return (
				<img
					key={i}
					className="lifestyle-slide-image"
					src={item.mobile.url}
					alt={item.mobile.alternativeText}
				/>
			);
		} else if (item.desktop) {
			return (
				<img
					key={i}
					className="lifestyle-slide-image"
					src={item.desktop.url}
					alt={item.desktop.alternativeText}
				/>
			);
		}
	});

	const slidePrams = LifestyleSlide.length > 1 ? swiperParams : swiperParamsSingle;

	return (
		<section className="lifestyle-slider">
			{LifestyleSlide && (
				<div className="lifestyle-slider-container -desktop">
					<Swiper {...slidePrams}>{LifestyleSlide}</Swiper>
				</div>
			)}
			<div className="lifestyle-slider-container -mobile">
				{LifestyleSlideMobile && (<Swiper {...slidePrams}>{LifestyleSlideMobile}</Swiper>)}
			</div>
		</section>
	);
};

LifestyleSlider.propTypes = {
	lifestyleImages: PropTypes.array,
};


export default LifestyleSlider;
