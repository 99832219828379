import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router';
import gql from 'graphql-tag';
import CollectionContainer from '../container/CollectionContainer';
import SeoComponent from '../component/SeoComponent';
import Loader from '../component/Loader';

const Collection = () => {
	let { collectionslug } = useParams();
	// const [colour, setColour] = useState(null);
	const { loading, error, data } = useQuery(COLLECTION_QUERY, {
		variables: { collectionslug },
		fetchPolicy: 'no-cache'
	});

	if (loading) return <Loader />;
	if (error)  return  null;

	const { collections } = data;
	const collection = collections && collections[0];
	// const products = (collection && collection.products) || [];

	return (
		<div className="collection">
			{collection.social_sharing && <SeoComponent social={collection.social_sharing} />}
			<CollectionContainer collection={collection} />
		</div>
	);
};

export default Collection;

const COLLECTION_QUERY = gql`
	query Collection($collectionslug: String) {
		collections(where: { slug: $collectionslug }) {
			id
			title
			social_sharing {
				id
				title
				description
				image {
					alternativeText
					url
				}
			}
			awards_icon {
				id
				title
				link
				image {
					id
					url
					formats
				}
			}
			mini_images {
				id
				heading
				copy
				square_images {
					id
					url
					formats
				}
			}
			full_bleed_hero {
				desktop_image {
					url
					alternativeText
				}
				mobile_image {
					url
					alternativeText
				}
			}
			image_carousel {
				id
				desktop {
					id
					url
					alternativeText
				}
				mobile {
					id
					url
					alternativeText
				}
			}
			card_image {
				id
				name
				url
				alternativeText
				caption
				width
				height
				formats
				previewUrl
			}

			sticky_image {
				id
				image {
					id
					url
					alternativeText
				}
			}

			card_image_large {
				id
				name
				url
				alternativeText
				caption
				width
				height
				formats
				previewUrl
			}

			colours {
				id
				colour {
					id
					title
					param
					hex_primary
					hex_optional
				}
				key_image {
					id
					formats
					url
				}
			}

			accordion_with_title {
				id
				title
				show_images
				accordion_items {
					id
					image {
						id
						url
					}
					visible_text
					dropdown_text
				}
			}

			intro {
				id
				embed_link
				poster {
					id
					alternativeText
					url
				}
				heading
				copy
			}

			products (sort: "created_at:desc", where: { status: "published"}) {
				id
				title
				slug
				primary_product_code
				card_image {
					id
					alternativeText
					url
					formats
				}
				product_colour {
					id
					colour {
						id
						title
						param
						hex_primary
						hex_optional
					}
					key_image {
						id
						title
						file {
							id
							url
							formats
							alternativeText
						}
					}
					product_code
				}
				awards_and_icons {
					id
					featured_icon {
						id
						icon {
							id
							image {
								id
								url
								alternativeText
							}
						}
					}
				}
				price {
					id
					price_au
					discontinued_au
				}
				features {
					id
					show_images
					accordion_items {
						id
						image {
							id
							url
						}
						visible_text
						dropdown_text
					}
				}
				subcategories {
					id
					slug
					title
					category {
						id
						slug
						title
					}
				}
			}
		}
	}
`;
