import React, {Component} from 'react';
import ProductList from './ProductList';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router';
import gql from 'graphql-tag';
import Images from '../../image/Images';
import { Link } from 'react-router-dom';
import CardContainer from './CardContainer';
import {findIndex, isEmpty} from 'lodash';
import Checkbox from '../../component/Checkbox';
import DropDownMenu from '../../component/DropDownMenu';
import ProductBlock from '../../component/ProductBlock';

class SearchFilterContainer extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
	      categoryItems: [],
	    };
	}

	handleCheckbox = (e, tag) => {
		const item = e.target.name;
    	const isChecked = e.target.checked;
    	const {categoryItems} = this.state;
    	const index = categoryItems.findIndex((element) => element === item);
    	
    	let newArr;
    	if(isChecked) {
    		if(index === -1) {
    			newArr = [...categoryItems, item];
    		}
    	} else {
    		newArr = categoryItems.filter((it) => {
    			return it !== item;
    		});
    	}
    	// console.log(newArr);
    	this.setState(prevState => ({ categoryItems: newArr}));
	};

	handleDropdown = (e) => {
		const item = e.target.name;
		const value = e.target.value;
		// console.log(item);
		// console.log(value);
		this.setState({
	      [item]: value
	    });
	};

	filterCategory = (data, categories) => {
		if (isEmpty(categories)) {
			return data;
		} else {
			return data.filter((item, i) => {
				if(item.categories) {
					let hasCategory = false;
					item.categories.forEach((category) => {
						if (categories.includes(category.slug)){
							hasCategory = true;
						}
					});
					return hasCategory;
				}
			});
		}
	};

	filterMeal = (data, meal) => {
		if (isEmpty(meal)) {
			return data;
		} else {
			return data.filter((item, i) => {
				return item.meal && item.meal.meal_title === meal;
			});
		}
	};

	resetFilter = () => {
		this.setState({
		      categoryItems: [],
		    });
	}

	render() {
		const { data, categories, keyword, onLoadMore } = this.props;
		const {categoryItems} = this.state;
		// console.log(data);
		// console.log(categories);
		// console.log(categoryItems);
		let filteredData = this.filterCategory(data, categoryItems);
		// filteredData = this.filterMeal(filteredData, meal);
		return (
			<section className="card-container search-result-container">
				<div className="center-container">
					<div className='title-section'>
						<h1>Products</h1>
						<h3 className='header-title'>{data.length} results for keyword <span className='brand-colour'>{keyword}</span></h3>
					</div>
					<div className='inner-content'>
						{ false && <div className='filter-wrapper'>
							{
								// 	meals && <div className='filter-dropdown filter-wrapper-inner'>
								// 	<div className='filter-tag-header'>
								// 		<img
								// 			className=""
								// 			src={Images.filterMealtype}
								// 			alt={''}
								// 		/>
								// 		<h3>Meal Type</h3>
								// 	</div> 
																					
								// 	<DropDownMenu value={meal} name='meal' onChange={(e)=>this.handleDropdown(e)}>
								// 									 <option value="">All</option>
								// 		{
								// 			meals.map((m,i) => {
								// 				return <option value={m.meal_title} key={i}>{m.meal_title}</option>;
								// 			})
								// 		}
								// 	</ DropDownMenu>
								// </div>
							}
						
							{
								// <div className='filter-wrapper-inner'>
								// 	<div className='filter-tag-header'>
								// 		<img
								// 			className=""
								// 			src={Images.flag}
								// 			alt={''}
								// 		/>
								// 		<h3>CATEGORY</h3>
								// 	</div> 
								// 	<div className='tag-container'>
								// 		{
								// 			categories && categories.map((category,i) => {
								// 				return (
								// 					<label key={i} className="container">{category.title}
								// 						<Checkbox name={category.slug} checked={this.state.categoryItems.includes(category.slug)} onChange={(e) => this.handleCheckbox(e)} />
								// 						<span className="checkmark"></span>
								// 					</label>
								// 				);
								// 			})
								// 		}
								// 	</div>
								// </div>
							}
						</div>}

						<div className="product-container bd-grey" style={{flex: 2}}>
							<div className='center-container grid-parent'>
								<div id="service" className="service grid-child-10">
									{
										filteredData.map((product, i) => {
											return <ProductBlock product={product} key={i}/>;			
										})
									}
								</div>

								{
									//filteredData.length > 0 && <div className="grid-child-10 center-container"><button className="form-submit button -black" onClick={onLoadMore}>Load more</button></div>
								}
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default SearchFilterContainer;
