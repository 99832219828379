import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import Images from '../../image/Images';
import { Link } from 'react-router-dom';
import { imageHelper } from '../../utils/helper';

export default class ProductList extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { content } = this.props;

		let swiperParams;
		
		if (content.length <= 1) {
			swiperParams = {
				slidesPerView: 1,
				freeMode: false,
			};
		} else {
			swiperParams = {
				slidesPerView: 1,
				freeMode: false,
				pagination: {
					el: '.promotion-slider-pagination',
					type: 'bullets',
					clickable: true,
				},
			};
		}

		return (
			<section className="home-promotion">
				<div className="centred-flex">
					<div className="home-promo-left">
						<h4 className="home-promo-heading">Promotion</h4>
						<Link to="/promotions" className="home-promotions-link -show-desktop">View all promotions</Link>
					</div>
					<div className="home-promo-right">
						<Swiper {...swiperParams}>
							{content.map((item, i) => {
								return (
									<div key={i} className="home-promo-slide">
										<div className="home-promo-text">
											<h3 className="home-promo-title">{item.promotion.title}</h3>
											<p className="home-promo-copy">{item.promotion.tagline}</p>
											<Link className="home-promo-link button -orange" to={'/promotions/'+ item.promotion.slug}>Find out more</Link>
										</div>
										<Link to={'/promotions/'+ item.promotion.slug} className="home-promo-image-container">
											<img className="home-promo-image" src={imageHelper(item.promotion.card_image, 'medium') || Images.fallbackCardImage} alt={item.promotion.card_image.alternativeText ? item.promotion.card_image.alternativeText : item.promotion.title}/>
										</Link>										
									</div>
								);
							})}
						</Swiper>
					</div>
					<Link to="/promotions" className="home-promotions-link -show-mobile">View all promotions</Link>
				</div>
			</section>
		);
	}
}

ProductList.propTypes = {
	items: PropTypes.array,
};
