import React from 'react';
import TipIcon from '../../image/icons/tip.svg';
import PropTypes from 'prop-types';

const RecipeTip = ({ content }) => {
	// console.log(content);
	return (
		<div className="recipe-tip">
			<img className="tip-icon" src={TipIcon} alt="" />
			<div className="recipe-tip-inner">
				<h4 className="tip-heading">{content.title}</h4>
				<p className="tip-copy">{content.content}</p>
			</div>
		</div>
	);
};

RecipeTip.propTypes = {
	content: PropTypes.object,
};

export default RecipeTip;
