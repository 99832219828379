import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router';
import  { Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import ArticleHero from '../container/partials/ArticleHero';
import RecipeBrief from '../container/partials/RecipeBrief';
import RecipeTags from '../container/partials/RecipeTags';
import DynamicContentContainer from '../container/DynamicContentContainer';
import FeaturedProducts from '../container/partials/FeaturedProducts';
import {isEmpty} from 'lodash';
import RelatedArticles from '../container/partials/RelatedArticles';
import SeoComponent from '../component/SeoComponent';
import SocialSharingContainer from '../container/SocialSharingContainer';
import Loader from '../component/Loader';

const Recipe = () => {
	let { recipeslug } = useParams();
	const { loading, error, data } = useQuery(RECIPE_QUERY, {
		variables: { recipeslug },
	});
	// console.log(error);
	if (loading) return <Loader />;
	if (error) return <p>error</p>;
	const { recipes } = data;
	if (recipes && recipes[0]) {
		const recipe = recipes[0];
		const dynamicContent = recipe.dynamic_content;
		// console.log(dynamicContent);
		return (
			<div className="recipe-page">
				{recipe.social_sharing && <SeoComponent social={recipe.social_sharing} />}
				<ArticleHero data={recipe} type="RECIPE" />
				<SocialSharingContainer type="recipe" />
				<RecipeBrief recipe={recipe} />
				{recipe.tags && !isEmpty(recipe.tags) && <RecipeTags tags={recipe.tags} type='recipe' />}
				{dynamicContent && <DynamicContentContainer dynamicContent={dynamicContent} share />}
				{recipe.related_products && (
					<FeaturedProducts content={recipe.related_products} />
				)}
				{recipe.related_content && (
					<RelatedArticles
						relatedContent={recipe.related_content || []}
						relatedHeading={recipe.related_content_heading}
					/>
				)}
			</div>
		);
	}
	else {
		return <Redirect to='/recipes'  />;
	}
};

export default Recipe;

const RECIPE_QUERY = gql`
	query SingleRecipeQuery($recipeslug: String) {
		recipes(where: { status: "published", slug: $recipeslug }) {
			id
			title
			tagline
			slug
			status
			preparation_time
			cooking_time
			servings
			meal {
				id
				meal_title
			}
			hero {
				id
				desktop {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
				mobile {
					id
					name
					hash
					url
					alternativeText
					caption
					width
					height
					previewUrl
					formats
				}
			}
			dynamic_content {
				... on ComponentTemplatesContentBlock {
					id
					content
				}
				... on ComponentTemplatesCta {
					id
					cta_text
					cta_link
					open_new_browser
				}
				... on ComponentTemplatesImageSlider {
					id
					single_image {
						id
						desktop {
							id
							url
							alternativeText
							formats
						}
					}
				}
				... on ComponentTemplatesImageCopyBlock {
					reverse
					copy
					image_media {
						alternativeText
						url
						formats
					}
				}
				... on ComponentTemplatesLargeHeading {
					id
					heading
				}
				... on ComponentTemplatesHighlightBlock {
					id
					title
					content
				}
				... on ComponentRepeatedIntro {
					id
					video_poster {
						id
						alternativeText
						url
					}
					video_embed_url
					heading
					content
					button_url
					button_text
					button_new_window
				}
				... on ComponentRepeatedVideoCarousel {
					id
					video {
						id
						embed_link
						poster {
							id
							alternativeText
							url
						}
						title
					}
				}
				... on ComponentRepeatedSingleImage {
					id
					desktop {
						id
						url
						alternativeText
						formats
					}
					mobile {
						id
						url
						alternativeText
						formats
					}
				}
				... on ComponentTemplatesProductShowcase {
					products {
						id
						title
						slug
						primary_product_code
						subcategories {
							id
							slug
							title
							category {
								id
								slug
								title
							}
						}
						card_image {
							id
							alternativeText
							url
						}
						price {
							id
							price_au
							discontinued_au
						}
						awards_and_icons {
							id
							featured_icon {
								id
								icon {
									id
									image {
										id
										url
										alternativeText
									}
								}
							}
						}
					}
					section_heading
					heading
				}
				... on ComponentRepeatedAccordion {
					id
					show_images
					accordion_items {
						id
						visible_text
						image {
							id
							alternativeText
							url
						}
						dropdown_text
					}
				}
			}
			related_products {
				id
				heading
				subheading
				button_link
				button_text
				products (where: { status: "published"}) {
					id
					status
					title
					primary_product_code
					slug
					card_image {
						id
						url
					}
					awards_and_icons {
						id
						featured_icon {
							id
							icon {
								id
								image {
									id
									url
									alternativeText
								}
							}
						}
					}
					price {
						id
						price_au
					}
					subcategories {
						id
						slug
						title
						category {
							id
							slug
							title
						}
					}				
				}
			}
			related_content_heading
			related_content {
				... on ComponentRepeatedRelatedRecipe {
					recipe {
						id
						title
						status
						slug
						tagline
						card_image {
							id
							url
						}
					}
				}
				... on ComponentRepeatedRelatedHintsTip {
					hints_tip {
						id
						title
						status
						slug
						tagline
						card_image {
							id
							url
						}
					}
				}
			}
			products {
				id
				title
				slug
				card_image {
					id
					alternativeText
					url
				}
				price {
					id
					price_au
					discontinued_au
				}
				subcategories {
					id
					slug
					title
					category {
						id
						slug
						title
					}
				}
			}
			card_image {
				id
				name
				url
				alternativeText
				caption
				width
				height
				formats
				previewUrl
			}
			tags (sort: "tag:asc") {
				id
				tag
			}
			social_sharing {
				id
				title
				description
				image {
					id
					url
					alternativeText
				}
			}
		}
	}
`;
