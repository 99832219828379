import React from 'react';
import Images from '../image/Images';

const Loader = () => (
	<div className="loader-container">
		<img
			className="loader"
			src={Images.loader}
			alt={''}
		/>
	</div>
);

export default Loader;
