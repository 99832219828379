import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Playbutton from '../image/icons/video-play.svg';
import Images from '../image/Images';
import ReactMarkdown from 'react-markdown';
import Breaks from 'remark-breaks';

class VideoIntroModule extends Component {
	constructor(props) {
		super(props);
		this.state = {
			videoModal: false,
		};
	}

	toggleModal = (e) => {
		e.preventDefault();
		this.setState((prev) => ({
			videoModal: !prev.videoModal,
		}));
	};

	render() {
		const { content } = this.props;
		const { videoModal } = this.state;
		const embed_link = content.embed_link || content.video_embed_url;
		const poster = content.video_poster && content.video_poster.url || content.poster && content.poster.url || Images.fallbackPoster;
		const copy = content.copy || content.content;
		return (
			<section className="product-intro">
				<div className="center-container grid-parent">
					<div className="product-intro-header-container left-small">
						<h1 className="product-intro-header large-subheading">
							{content && content.heading}
						</h1>
					</div>
					<div className="product-intro-content-container right-medium">
						{embed_link && (
							<div className="product-intro-video-gallery">
								<div className="single-product-intro-video">
									{videoModal && (
										<div className="intro-video-wrapper">
											<iframe
												className="intro-video-element"
												src={embed_link + '?autoplay=1'}
												frameBorder="0"
												allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
												allowFullScreen
											></iframe>
										</div>
									)}
									{!videoModal && poster && (
										<div className="product-intro-video-poster-container">
											<img
												className="product-intro-video-poster"
												src={poster}
												alt={poster.alternativeText || 'Sunbeam Image'}
											/>
											<div className="video-poster-button-wrapper">
												<a
													href="#"
													onClick={(e) => this.toggleModal(e)}
													className="video-poster-button"
												>
													<img
														className="video-poster-button-icon"
														src={Playbutton}
														alt="Play video"
													/>
												</a>
											</div>
										</div>
									)}
								</div>
							</div>
						)}
						<div className="product-intro-text">
							{copy && 
							<div className="product-intro-copy markdown-block">
								<ReactMarkdown children={copy} plugins={[Breaks]} />
							</div>
							}
						</div>
						{content.button_text && (
							<a
								className="button -orange"
								href={`${content.button_url}`}
							>
								{content.button_text}
							</a>
						)}
					</div>
				</div>
			</section>
		);
	}
}

VideoIntroModule.propTypes = {
	content: PropTypes.object,
};

export default VideoIntroModule;
