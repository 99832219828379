import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import Breaks from 'remark-breaks';
import {isEmpty} from 'lodash';


const ImageCopyBlock = ({ content, col }) => {
	if (!content) return null;

	let imageUrl = content &&
		content.image_media && content.image_media.formats && 
		content.image_media.formats.medium &&content.image_media.formats.medium.url || content.image_media && content.image_media.url;

	return (
		<div className="image-copy-container">
			{content.reverse && (
				<div className="image-copy-wrapper">
					<div className="block copy text-block reverse">
						<ReactMarkdown children={content.copy} plugins={[Breaks]} />
					</div>

					<div className="block image">
						<img
							className="image-break-element -desktop"
							src={imageUrl}
							alt={content.image_media.alternativeText}
						/>
					</div>
				</div>
			)}
			{!content.reverse && (
				<div className="image-copy-wrapper">
					<div className="block image">
						<img
							className="image-break-element -desktop"
							src={imageUrl}
							alt={content.image_media.alternativeText}
						/>
					</div>

					<div className="block copy text-block">
						<ReactMarkdown children={content.copy} plugins={[Breaks]} />
					</div>
				</div>
			)}
		</div>
	);
};

ImageCopyBlock.propTypes = {
	content: PropTypes.object,
};

export default ImageCopyBlock;

